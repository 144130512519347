<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="sticky-top">
                <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                    Dosen
                </div>

                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->

                <div class="row me-0 py-2 bg-white">
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-3">
                        <input type="text" v-model="query" @change="index()" class="form-control"
                            placeholder="Cari berdasarkan nama, nip">
                    </div>
                    <div class="col-3 text-center">
                        <div class="d-flex justify-content-center  align-items-center">
                            <div>
                                <i class='bx bx-chevron-left fs-7' @click="index_before_page()"
                                    style="cursor: pointer;"></i>
                            </div>
                            <div class="fs-7">
                                {{ numberPage }}/{{ totalPages }}
                            </div>
                            <div>
                                <i class='bx bx-chevron-right fs-7' @click="index_next_page(previousLastId, NextLastId)"
                                    style="cursor: pointer;"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-end ">
                        <button v-if="create_akses == true"
                            class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 me-2"
                            style="border-radius: 5px;" data-bs-toggle="modal" data-bs-target="#modal_tambah"
                            @click="gelarDosenRecord = []; currentGelarDosenRecord = {}; showModal('modal_tambah');">
                            <img src="@/assets/icon/user_plus.png" alt="" width="15">
                            Tambah Dosen
                        </button>
                        <!-- <button v-if="create_akses == true" class="btn bg-green-custom fs-8 py-1 text-light font-custom-4"
                            data-bs-toggle="modal" data-bs-target="#modal_generate_krs"
                            @click="showModal('modal_generate_krs');">
                            <img src="@/assets/icon/semester.png" alt="" width="15">
                            Generate SKS
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column gap-2 mt-2">

                <div class="border mt-1 rounded-3 me-2 font-custom-1" style="overflow-x:auto; z-index: 0;">
                    <table class="table mb-0 bg-transparent table-borderless " style="">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-transparent fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">NIP</th>
                                <th scope="col" class="font-custom-1 fs-7">NIDN/NITK</th>
                                <th scope="col" class="font-custom-1 fs-7">Nama</th>
                                <th scope="col" class="font-custom-1 fs-7">Jabatan Struktural</th>
                                <th scope="col" class="font-custom-1 fs-7">Jabatan Fungsional</th>
                                <th scope="col" class="font-custom-1 fs-7">Golongan</th>
                                <th scope="col" class="font-custom-1 fs-7">Bidang</th>
                                <th scope="col" class="font-custom-1 fs-7">Prodi </th>
                                <th scope="col" class="font-custom-1 fs-7">Status</th>
                                <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody class="fs-7">
                            <tr v-for="(record, index) in filteredRecords" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <!-- <td>{{ record.username }}</td> -->
                                <td>{{ record.nip }}</td>
                                <td>{{ record.nidn }}</td>
                                <td>{{ record.nama }}</td>
                                <td>{{ record.jabatan_struktural }}</td>
                                <td>{{ record.jabatan_fungsional }}</td>
                                <td>{{ record.golongan }}</td>
                                <td>{{ record.nama_bidang }}</td>
                                <td>{{ record.prodi }}</td>
                                <td>{{ record.status_dosen }}</td>
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button class="btn btn-primary btn-sm fs-8" data-bs-toggle="modal"
                                            data-bs-target="#modal_penelitian_dosen"
                                            v-on:click="get_penelitian_dosen(record.id)">
                                            <i class='bx bxs-file-find fs-8'></i>
                                        </button>
                                        <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            v-on:click="editRecord(record);" data-bs-toggle="modal"
                                            data-bs-target="#modal_edit"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            data-bs-toggle="modal" data-bs-target="#modal_delete"
                                            v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>
    <form @submit.prevent="insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_tambah">
            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Data dosen</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_tambah', 'close_modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">

                        <div class="mb-3">
                            <label for="nip" class="form-label"> NIP <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="newRecord.nip" maxlength="16"
                                @keypress="NumberOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="nidn" class="form-label"> NIDN/NITK</label>
                            <input type="text" class="form-control" v-model="newRecord.nidn" maxlength="10"
                                @keypress="NumberOnly" />
                        </div>

                        <div class="mb-3">
                            <label for="kode" class="form-label"> Nama Lengkap<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="newRecord.nama" @keypress="CharOnly"
                                required />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_lahir" class="form-label">Tanggal Lahir<span
                                    class="text-danger">*</span></label>
                            <input type="date" class="form-control" v-model="newRecord.tanggal_lahir" required />
                        </div>

                        <div class="mb-3">
                            <label for="prodi" class="form-label"> Prodi<span class="text-danger">*</span></label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="newRecord.prodi" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="prodi" class="form-label">Sebagai Dekan</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                    type="checkbox" role="switch" id="status_jadwalkan" v-model="newRecord.status_dekan"
                                    :checked="newRecord.status_dekan == 1" @click="cekDekanRektor('insert');">
                                <!-- <label class="form-check-label" for="flexSwitchCheckDefault">Dekan</label> -->
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="status_rektor" class="form-label">Sebagai Rektor</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                    type="checkbox" role="switch" id="status_rektor" v-model="newRecord.status_rektor"
                                    :checked="newRecord.status_rektor == 1" @click="cekDekanRektor('insert');">
                                <!-- <label class="form-check-label" for="flexSwitchCheckDefault">Dekan</label> -->
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="status_rektor" class="form-label">Sebagai Kaprodi</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                    type="checkbox" role="switch" id="status_rektor"
                                    v-model="currentRecord.status_kaprodi" :checked="newRecord.status_kaprodi == 1"
                                    @click="cekDekanRektor('update');">
                                <!-- <label class="form-check-label" for="flexSwitchCheckDefault">Dekan</label> -->
                            </div>
                        </div>

                        <div class="mb-3 ">
                            <label for="jabatan" class="form-label"> Jabatan Struktural</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="newRecord.jabatan_struktural">
                                <option value="*" selected disabled>- Pilih Jabatan Struktural</option>
                                <option value="">-</option>
                                <option v-for="list in jabatanStrukturalList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>



                        <div class="mb-3">
                            <label for="jabatan" class="form-label"> Jabatan Fungsional</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="newRecord.jabatan_fungsional">
                                <option value="*" selected disabled>- Pilih Jabatan Fungsional</option>
                                <option value="">-</option>
                                <option v-for="list in jabatanFungsionalList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="golongan" class="form-label"> Golongan</label>
                            <select class="form-select" aria-label="Default select example" name="golongan"
                                id="golongan" v-model="newRecord.golongan">
                                <option value="*" selected disabled>- Pilih Golongan</option>
                                <option value="">-</option>
                                <option v-for="list in golonganList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="bidang" class="form-label"> Bidang</label>
                            <!-- <select class="form-select" aria-label="Default select example" name="bidang" id="bidang"
                                v-model="newRecord.bidang" required>
                                <option value="" selected disabled>- Pilih Bidang</option>
                                <option v-for="list in bidangList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select> -->
                            <vue-select v-model="newRecord.bidang" :options="bidangList" label="nama" :multiple="true"
                                :reduce="tag => tag.id" :searchable="true" :close-on-select="false"
                                :limit="10"></vue-select>
                        </div>

                        <div class="mb-3">
                            <label for="status_dosen" class="form-label"> Status Dosen<span
                                    class="text-danger">*</span></label>
                            <select class="form-select" aria-label="Default select example" name="status_dosen"
                                id="status_dosen" v-model="newRecord.status_dosen" required>
                                <option value="" selected disabled>- Pilih Status Dosen</option>
                                <option v-for="list in statusDoseList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="email" class="form-label"> Email<span class="text-danger">*</span></label>
                            <input type="email" class="form-control" v-model="newRecord.email" required />
                        </div>
                        <div class="mb-3">
                            <label for="whatsapp" class="form-label"> Whatsapp<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="newRecord.whatsapp" @keypress="NumberOnly"
                                required />
                        </div>

                        <div class="mb-3">
                            <label for="jenis_kelamin" class="form-label"> Jenis Kelamin<span
                                    class="text-danger">*</span></label>
                            <select class="form-select" aria-label="Default select example" name="jenis_kelamin"
                                id="jenis_kelamin" v-model="newRecord.jenis_kelamin" required>
                                <option value="" selected disabled>- Pilih Jenis Kelamin</option>
                                <option v-for="list in jenisKelaminList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label"> Agama<span class="text-danger">*</span></label>
                            <select class="form-select" aria-label="Default select example" name="agama" id="agama"
                                v-model="newRecord.agama" required>
                                <option value="" selected disabled>- Pilih Agama</option>
                                <option v-for="list in agamaList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Alamat<span class="text-danger">*</span></label>
                            <textarea class="form-control" v-model="newRecord.alamat" required />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">No Hp Darurat</label>
                            <input type="text" class=" form-control" v-model="newRecord.no_darurat"
                                @keypress="NumberOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Hubungan dengan No darurat</label>
                            <input type="text" class=" form-control" v-model="newRecord.pemilik_no_darurat"
                                @keypress="CharOnly" />
                        </div>

                        <div class="mb-3">
                            <label for="alamat" class="form-label">No Hp Darurat 2</label>
                            <input type="text" class=" form-control" v-model="newRecord.no_darurat_2"
                                @keypress="NumberOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Hubungan dengan No darurat 2</label>
                            <input type="text" class=" form-control" v-model="newRecord.pemilik_no_darurat_2"
                                @keypress="CharOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">No Hp Darurat 3</label>
                            <input type="text" class=" form-control" v-model="newRecord.no_darurat_3"
                                @keypress="NumberOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Hubungan dengan No darurat 3</label>
                            <input type="text" class=" form-control" v-model="newRecord.pemilik_no_darurat_3"
                                @keypress="CharOnly" />
                        </div>

                        <div class="mb-3 border rounded border-1 px-2 py-2">
                            <label for="status_gelar" class="form-label">Input Gelar</label>
                            <select class="form-select" aria-label="Default select example" name="status_gelar"
                                id="status_gelar" v-model="statusGelar">
                                <option value="" selected disabled>- Pilih Gelar</option>
                                <option v-for="list in statusGelarList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                            <div class="d-flex justify-content-between align-items-center mt-3">
                                <div class="mb-3">
                                    <label for="gelar" class="form-label">Bidang/Program Studi</label>
                                    <input type="text" class="form-control" v-model="currentGelarDosenRecord.bidang" />
                                </div>
                                <!-- <div class="mb-3" v-if="statusGelar == 'S1'">
                                    <label for="gelar" class="form-label">Bidang Gelar S1</label>
                                    <select class="form-select" aria-label="Default select example" name="bidang"
                                        id="bidang" v-model="currentGelarDosenRecord.bidang"
                                        @change="updateGelar('gelar_1', 'bidang')">
                                        <option value="" selected disabled>- Pilih Bidang S1</option>
                                        <option v-for="list in sarjanaList" :key="list.nama" :value="list.nama">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3" v-if="statusGelar == 'S2'">
                                    <label for="gelar" class="form-label">Bidang Gelar S2</label>
                                    <select class="form-select" aria-label="Default select example" name="bidang"
                                        id="bidang" v-model="currentGelarDosenRecord.bidang"
                                        @change="updateGelar('gelar_2', 'bidang')">
                                        <option value="" selected disabled>- Pilih Bidang S1</option>
                                        <option v-for="list in magisterList" :key="list.nama" :value="list.nama">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3" v-if="statusGelar == 'S3'">
                                    <label for="gelar" class="form-label">Bidang Gelar S3</label>
                                    <select class="form-select" aria-label="Default select example" name="bidang"
                                        id="bidang" v-model="currentGelarDosenRecord.bidang"
                                        @change="currentGelarDosenRecord.gelar = 'Dr'">
                                        <option value="" selected disabled>- Pilih Bidang S1</option>
                                        <option v-for="list in sarjanaList" :key="list.nama" :value="list.nama">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div> -->

                                <div class="mb-3">
                                    <label for=" gelar" class="form-label">Gelar </label>
                                    <input type="text" class="form-control" v-model="currentGelarDosenRecord.gelar" />
                                </div>
                                <!-- <div class="mb-3" v-if="statusGelar == 'S1'">
                                    <label for="gelar" class="form-label">Gelar </label>
                                    <select class="form-select" aria-label="Default select example" name="gelar" id="gelar"
                                        v-model="currentGelarDosenRecord.gelar" disabled>
                                        <option value="" selected disabled>- Pilih Gelar</option>
                                        <option v-for="list in sarjanaList" :key="list.kode" :value="list.kode">
                                            {{ list.kode }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3" v-if="statusGelar == 'S2'">
                                    <label for="gelar" class="form-label">Gelar </label>
                                    <select class="form-select" aria-label="Default select example" name="gelar" id="gelar"
                                        v-model="currentGelarDosenRecord.gelar" disabled>
                                        <option value="" selected disabled>- Pilih Gelar</option>
                                        <option v-for="list in magisterList" :key="list.kode" :value="list.kode">
                                            {{ list.kode }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3" v-if="statusGelar == 'S3'">
                                    <label for="gelar" class="form-label">Gelar </label>
                                    <select class="form-select" aria-label="Default select example" name="gelar" id="gelar"
                                        v-model="currentGelarDosenRecord.gelar" disabled>
                                        <option value="Dr" selected disabled>Dr.</option>

                                    </select>
                                </div> -->
                                <div class="mb-3">
                                    <label for="nama_univ_gelar_1" class="form-label">Nama Universitas Gelar </label>
                                    <input type="text" class="form-control" v-model="currentGelarDosenRecord.nama_pt"
                                        @keypress="CharOnly" />
                                </div>
                                <div class="mb-3">
                                    <label for="tahun_perolehan_1" class="form-label"> Tahun Gelar </label>
                                    <input type="number" class="form-control" v-model="currentGelarDosenRecord.tahun" />
                                </div>
                                <div class="mb-3 text-end">
                                    <button class="btn bg-green-custom text-light" type="button"
                                        @click="insert_gelar_dosen()">Tambah Gelar</button>
                                </div>
                            </div>
                        </div>


                        <!-- //insert gelar dosen -->


                        <div class="border mt-1 rounded-3 me-2 font-custom-1">
                            <table class="table mb-0 bg-transparent table-borderless " style="">
                                <thead>
                                    <tr>
                                        <th scope="col" class="bg-transparent fs-7">No</th>
                                        <!-- <th scope="col" class="font-custom-1 fs-7">Dosen</th> -->
                                        <th scope="col" class="font-custom-1 fs-7">Jenjang Pendidikan</th>
                                        <th scope="col" class="font-custom-1 fs-7">Nama</th>
                                        <th scope="col" class="font-custom-1 fs-7">Bidang</th>
                                        <th scope="col" class="font-custom-1 fs-7">Tahun</th>
                                        <th scope="col" class="font-custom-1 fs-7">Nama Universitas</th>
                                        <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                                        <!-- <th scope="col" class="font-custom-1 fs-7">Username</th> -->

                                        <!-- <th scope="col" class="font-custom-1 fs-7">KTP</th> -->
                                        <!-- <th scope="col" class="font-custom-1 fs-7">Email</th>
                                <th scope="col" class="font-custom-1 fs-7">whatsapp</th> -->
                                    </tr>
                                </thead>
                                <tbody class="fs-7">
                                    <tr v-for="(record, index) in gelarDosenRecord" :key="record.id" :ref="record.id"
                                        class="border-bottom ">
                                        <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                        <!-- <td>{{ record.username }}</td> -->
                                        <!-- <td>{{ record.dosen }}</td> -->
                                        <td>{{ record.jenjang_pendidikan }}</td>
                                        <td>{{ record.gelar }}</td>
                                        <td>{{ record.bidang }}</td>
                                        <td>{{ record.tahun }}</td>
                                        <td>{{ record.nama_pt }}</td>
                                        <!-- <td>{{ record.email }}</td>
                                <td>{{ record.whatsapp }}</td> -->
                                        <td class="bg-transparent">
                                            <div class="d-flex gap-2">
                                                <!-- <button class="btn btn-primary btn-sm fs-8" data-bs-toggle="modal"
                                                    data-bs-target="#modal_penelitian_dosen"
                                                    v-on:click="get_penelitian_dosen(record.id)">
                                                    <i class='bx bxs-file-find fs-8'></i>
                                                </button>
                                                <button class="btn btn-warning btn-sm fs-8" v-on:click="editRecord(record)"
                                                    data-bs-toggle="modal" data-bs-target="#modal_edit"><i
                                                        class='bx bxs-message-square-edit fs-8'></i></button> -->
                                                <button class="btn btn-danger btn-sm fs-8" type="button"
                                                    v-on:click="delete_gelar_dosen(record.id, true)"><i
                                                        class='bx bxs-trash-alt fs-8'></i></button>

                                            </div>

                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_tambah', 'close_modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit">

            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data dosen</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_edit', 'close_modal_edit')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nip" class="form-label"> NIP<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="currentRecord.nip" maxlength="16"
                                @keypress="NumberOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="nidn" class="form-label"> NIDN</label>
                            <input type="text" class="form-control" v-model="currentRecord.nidn" maxlength="10"
                                @keypress="NumberOnly" />
                        </div>

                        <div class="mb-3">
                            <label for="kode" class="form-label"> Nama Lengkap<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="currentRecord.nama" @keypress="CharOnly"
                                required />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_lahir" class="form-label">Tanggal Lahir<span
                                    class="text-danger">*</span></label>
                            <input type="date" class="form-control" v-model="currentRecord.tanggal_lahir" required />
                        </div>

                        <div class="mb-3">
                            <label for="prodi" class="form-label"> Prodi<span class="text-danger">*</span></label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="currentRecord.prodi" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                            <!-- <vue-select v-model="currentRecord.mengajar_prodi" :options="prodiList" label="nama"
                                :multiple="true" :reduce="tag => tag.id" :searchable="true" :close-on-select="false"
                                :limit="10"></vue-select> -->
                        </div>
                        <div class="mb-3">
                            <label for="prodi" class="form-label">Sebagai Dekan</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                    type="checkbox" role="switch" id="status_jadwalkan"
                                    v-model="currentRecord.status_dekan" :checked="currentRecord.status_dekan == 1"
                                    @click="cekDekanRektor('update');">
                                <!-- <label class="form-check-label" for="flexSwitchCheckDefault">Dekan</label> -->
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="status_rektor" class="form-label">Sebagai Rektor</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                    type="checkbox" role="switch" id="status_rektor"
                                    v-model="currentRecord.status_rektor" :checked="currentRecord.status_rektor == 1"
                                    @click="cekDekanRektor('update');">
                                <!-- <label class="form-check-label" for="flexSwitchCheckDefault">Dekan</label> -->
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="status_rektor" class="form-label">Sebagai Kaprodi</label>
                            <div class="form-check form-switch">
                                <input class="form-check-input bg-green-custom-checked bg-border-green-custom"
                                    type="checkbox" role="switch" id="status_rektor"
                                    v-model="currentRecord.status_kaprodi" :checked="currentRecord.status_kaprodi == 1"
                                    @click="cekDekanRektor('update');">
                                <!-- <label class="form-check-label" for="flexSwitchCheckDefault">Dekan</label> -->
                            </div>
                        </div>


                        <div class="mb-3">
                            <label for="jabatan" class="form-label"> Jabatan Struktural</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="currentRecord.jabatan_struktural">
                                <option value="*" selected disabled>- Pilih Jabatan Struktural</option>
                                <option value="">-</option>
                                <option v-for="list in jabatanStrukturalList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="jabatan" class="form-label"> Jabatan Fungsional</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="currentRecord.jabatan_fungsional">

                                <option value="*" selected disabled>- Pilih Jabatan Fungsional</option>
                                <option value="">-</option>
                                <option v-for="list in jabatanFungsionalList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="golongan" class="form-label"> Golongan</label>
                            <select class="form-select" aria-label="Default select example" name="golongan"
                                id="golongan" v-model="currentRecord.golongan">
                                <option value="*" selected disabled>- Pilih Golongan</option>
                                <option value="">-</option>
                                <option v-for="list in golonganList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="bidang" class="form-label"> Bidang<span class="text-danger">*</span></label>
                            <vue-select v-model="currentRecord.bidang" :options="bidangList" label="nama"
                                :multiple="true" :reduce="tag => tag.id" :searchable="true" :close-on-select="false"
                                :limit="10"></vue-select>
                        </div>

                        <div class="mb-3">
                            <label for="status_dosen" class="form-label"> Status Dosen<span
                                    class="text-danger">*</span></label>
                            <select class="form-select" aria-label="Default select example" name="status_dosen"
                                id="status_dosen" v-model="currentRecord.status_dosen" required>
                                <option value="" selected disabled>- Pilih Status Dosen</option>
                                <option v-for="list in statusDoseList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <!-- <div class="mb-3">
                            <label for="kode" class="form-label"> Username</label>
                            <input type="text" class="form-control" v-model="currentRecord.username" required />
                        </div> -->
                        <!-- <div class="mb-3">
                            <label for="kode" class="form-label"> Password</label>
                            <input type="text" class="form-control" v-model="currentRecord.password" />
                        </div> -->

                        <div class="mb-3">
                            <label for="email" class="form-label"> Email<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="currentRecord.email" required />
                        </div>
                        <div class="mb-3">
                            <label for="whatsapp" class="form-label"> Whatsapp<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="currentRecord.whatsapp"
                                @keypress="NumberOnly" required />
                        </div>

                        <div class="mb-3">
                            <label for="jenis_kelamin" class="form-label"> Jenis Kelamin<span
                                    class="text-danger">*</span></label>
                            <select class="form-select" aria-label="Default select example" name="jenis_kelamin"
                                id="jenis_kelamin" v-model="currentRecord.jenis_kelamin" required>
                                <option value="" selected disabled>- Pilih Jenis Kelamin</option>
                                <option v-for="list in jenisKelaminList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label"> Agama<span class="text-danger">*</span></label>
                            <select class="form-select" aria-label="Default select example" name="agama" id="agama"
                                v-model="currentRecord.agama" required>
                                <option value="" selected disabled>- Pilih Agama</option>
                                <option v-for="list in agamaList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Alamat<span class="text-danger">*</span></label>
                            <textarea class="form-control" v-model="currentRecord.alamat" required />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">No Hp Darurat</label>
                            <input type="text" class=" form-control" v-model="currentRecord.no_darurat"
                                @keypress="NumberOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Pemilik no darurat</label>
                            <input type="text" class=" form-control" v-model="currentRecord.pemilik_no_darurat"
                                @keypress="CharOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">No Hp Darurat 2</label>
                            <input type="text" class=" form-control" v-model="currentRecord.no_darurat_2"
                                @keypress="NumberOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Pemilik no darurat 2</label>
                            <input type="text" class=" form-control" v-model="currentRecord.pemilik_no_darurat_2"
                                @keypress="CharOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">No Hp Darurat 3</label>
                            <input type="text" class=" form-control" v-model="currentRecord.no_darurat_3"
                                @keypress="NumberOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Pemilik no darurat 3</label>
                            <input type="text" class=" form-control" v-model="currentRecord.pemilik_no_darurat_3"
                                @keypress="CharOnly" />
                        </div>

                        <div class="mb-3 border rounded border-1 px-2 py-2">
                            <select class="form-select" aria-label="Default select example" name="status_gelar"
                                id="status_gelar" v-model="statusGelar">
                                <option value="" selected disabled>- Pilih Gelar</option>
                                <option v-for="list in statusGelarList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="mb-3">
                                    <label for="gelar" class="form-label">Bidang Gelar</label>
                                    <input type="text" class="form-control" v-model="currentGelarDosenRecord.bidang" />
                                </div>
                                <!-- <div class="mb-3" v-if="statusGelar == 'S1'">
                                    <label for="gelar" class="form-label">Bidang Gelar S3</label>
                                    <select class="form-select" aria-label="Default select example" name="bidang"
                                        id="bidang" v-model="currentGelarDosenRecord.bidang"
                                        @change="updateGelar('gelar_1', 'bidang')">
                                        <option value="" selected disabled>- Pilih Bidang S1</option>
                                        <option v-for="list in sarjanaList" :key="list.nama" :value="list.nama">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3" v-if="statusGelar == 'S2'">
                                    <label for="gelar" class="form-label">Bidang Gelar S3</label>
                                    <select class="form-select" aria-label="Default select example" name="bidang"
                                        id="bidang" v-model="currentGelarDosenRecord.bidang"
                                        @change="updateGelar('gelar_2', 'bidang')">
                                        <option value="" selected disabled>- Pilih Bidang S1</option>
                                        <option v-for="list in magisterList" :key="list.nama" :value="list.nama">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3" v-if="statusGelar == 'S3'">
                                    <label for="gelar" class="form-label">Bidang Gelar S3</label>
                                    <select class="form-select" aria-label="Default select example" name="bidang"
                                        id="bidang" v-model="currentGelarDosenRecord.bidang"
                                        @change="currentGelarDosenRecord.gelar = 'Dr'">
                                        <option value="" selected disabled>- Pilih Bidang S1</option>
                                        <option v-for="list in sarjanaList" :key="list.nama" :value="list.nama">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div> -->
                                <div class="mb-3">
                                    <label for="gelar" class="form-label">Gelar </label>
                                    <input type="text" class="form-control" v-model="currentGelarDosenRecord.gelar" />
                                </div>
                                <!-- <div class="mb-3" v-if="statusGelar == 'S1'">
                                    <label for="gelar" class="form-label">Gelar </label>
                                    <select class="form-select" aria-label="Default select example" name="gelar" id="gelar"
                                        v-model="currentGelarDosenRecord.gelar" disabled>
                                        <option value="" selected disabled>- Pilih Gelar</option>
                                        <option v-for="list in sarjanaList" :key="list.kode" :value="list.kode">
                                            {{ list.kode }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3" v-if="statusGelar == 'S2'">
                                    <label for="gelar" class="form-label">Gelar </label>
                                    <select class="form-select" aria-label="Default select example" name="gelar" id="gelar"
                                        v-model="currentGelarDosenRecord.gelar" disabled>
                                        <option value="" selected disabled>- Pilih Gelar</option>
                                        <option v-for="list in magisterList" :key="list.kode" :value="list.kode">
                                            {{ list.kode }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3" v-if="statusGelar == 'S3'">
                                    <label for="gelar" class="form-label">Gelar </label>
                                    <select class="form-select" aria-label="Default select example" name="gelar" id="gelar"
                                        v-model="currentGelarDosenRecord.gelar" disabled>
                                        <option value="Dr" selected disabled>Dr.</option>
                                    </select>
                                </div> -->
                                <div class="mb-3">
                                    <label for="nama_univ_gelar_1" class="form-label">Nama Universitas Gelar </label>
                                    <input type="text" class="form-control" v-model="currentGelarDosenRecord.nama_pt"
                                        @keypress="CharOnly" />
                                </div>
                                <div class="mb-3">
                                    <label for="tahun_perolehan_1" class="form-label"> Tahun Gelar </label>
                                    <input type="number" class="form-control" v-model="currentGelarDosenRecord.tahun" />
                                </div>

                            </div>
                        </div>


                        <!-- //insert gelar dosen -->
                        <div class="mb-3 text-end">
                            <button class="btn bg-green-custom text-light" type="button"
                                @click="insert_gelar_dosen(currentRecord.id)">Tambah Gelar</button>
                        </div>



                        <div class="border mt-1 rounded-3 me-2 font-custom-1">
                            <table class="table mb-0 bg-transparent table-borderless " style="">
                                <thead>
                                    <tr>
                                        <th scope="col" class="bg-transparent fs-7">No</th>
                                        <!-- <th scope="col" class="font-custom-1 fs-7">Dosen</th> -->
                                        <th scope="col" class="font-custom-1 fs-7">Jenjang pendidikan</th>
                                        <th scope="col" class="font-custom-1 fs-7">Nama</th>
                                        <th scope="col" class="font-custom-1 fs-7">Bidang</th>
                                        <th scope="col" class="font-custom-1 fs-7">Tahun</th>
                                        <th scope="col" class="font-custom-1 fs-7">Nama Universitas</th>
                                        <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                                        <!-- <th scope="col" class="font-custom-1 fs-7">Username</th> -->
                                        <!-- <th scope="col" class="font-custom-1 fs-7">KTP</th> -->
                                        <!-- <th scope="col" class="font-custom-1 fs-7">Email</th>
                                <th scope="col" class="font-custom-1 fs-7">whatsapp</th> -->
                                    </tr>
                                </thead>
                                <tbody class="fs-7">
                                    <tr v-for="(record, index) in gelarDosenRecord" :key="record.id" :ref="record.id"
                                        class="border-bottom ">
                                        <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                        <!-- <td>{{ record.username }}</td> -->
                                        <!-- <td>{{ record.dosen }}</td> -->
                                        <td>{{ record.jenjang_pendidikan }}</td>
                                        <td>{{ record.nama }}</td>
                                        <td>{{ record.bidang }}</td>
                                        <td>{{ record.tahun }}</td>
                                        <td>{{ record.nama_pt }}</td>
                                        <!-- <td>{{ record.email }}</td>
                                <td>{{ record.whatsapp }}</td> -->
                                        <td class="bg-transparent">
                                            <div class="d-flex gap-2">
                                                <!-- <button class="btn btn-primary btn-sm fs-8" data-bs-toggle="modal"
                                                    data-bs-target="#modal_penelitian_dosen"
                                                    v-on:click="get_penelitian_dosen(record.id)">
                                                    <i class='bx bxs-file-find fs-8'></i>
                                                </button>
                                                <button class="btn btn-warning btn-sm fs-8" v-on:click="editRecord(record)"
                                                    data-bs-toggle="modal" data-bs-target="#modal_edit"><i
                                                        class='bx bxs-message-square-edit fs-8'></i></button> -->
                                                <button class="btn btn-danger btn-sm fs-8" type="button"
                                                    v-on:click="delete_gelar_dosen(record.id)"><i
                                                        class='bx bxs-trash-alt fs-8'></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <!-- <div class="mb-3">
                            <label for="gelar" class="form-label">Bidang Gelar S2</label>
                            <select class="form-select" aria-label="Default select example" name="bidang" id="bidang"
                                v-model="currentRecord.bidang_2" @change="updateGelar('gelar_2', 'bidang_2', 'update')">
                                <option value="" selected disabled>- Pilih Bidang S2</option>
                                <option v-for="list in magisterList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="gelar" class="form-label">Gelar S2</label>
                            <select class="form-select" aria-label="Default select example" name="gelar" id="gelar"
                                v-model="currentRecord.gelar_2">
                                <option value="" selected disabled>- Pilih Gelar S2</option>
                                <option v-for="list in magisterList" :key="list.kode" :value="list.kode">
                                    {{ list.kode }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="nama_univ_gelar_2" class="form-label">Nama Universitas Gelar S2</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_univ_gelar_2"
                                @keypress="CharOnly" />
                        </div>
                        <div class="mb-3">
                            <label for="tahun_perolehan_2" class="form-label"> Tahun Gelar S2 </label>
                            <input type="number" class="form-control" v-model="currentRecord.tahun_perolehan_2" required />
                        </div> -->








                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>

                    </div>


                </div>

            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>



    <div class="modal fade" id="modal_penelitian_dosen" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_penelitian_dosen">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Penelitian Dosen</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_penelitian_dosen"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_penelitian_dosen', 'close_modal_penelitian_dosen')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-4">
                            <div class="d-flex align-items-center gap-5">
                                <div class="">
                                    <select class="form-select fs-8 font-custom-2 py-1 fw-bold"
                                        aria-label="Default select example" v-model="tipe"
                                        @change="get_penelitian_dosen()">
                                        <option value="" selected disabled>Pilih Tipe:</option>
                                        <option value="PENELITIAN">PENELITIAN</option>
                                        <option value="PENGABDIAN">PENGABDIAN</option>
                                        <option value="PUBLIKASI">PUBLIKASI</option>
                                        <option value="HKI">HKI</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <table class="table  " style="">

                        <thead>
                            <tr>
                                <th scope="col" class="fs-7">No</th>
                                <th scope="col" class="fs-7">Dosen</th>
                                <th scope="col" class="fs-7">Judul</th>
                                <th scope="col" class="fs-7">Semester</th>
                                <th scope="col" class="fs-7">Tahun Ajaran</th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Penulisan</th>
                                <th scope="col" class="font-custom-1 fs-7">Jenis Penelitian</th>
                                <th scope="col" class="font-custom-1 fs-7">Jumlah Dana </th>
                                <th scope="col" class="font-custom-1 fs-7">Sumber Dana </th>
                                <th scope="col" class="font-custom-1 fs-7" v-if="tipe == 'PUBLIKASI'">Link Publikasi
                                </th>
                                <th scope="col" class="font-custom-1 fs-7" v-if="tipe == 'HKI'">Sertifikat
                                </th>
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipe == 'PENELITIAN' || tipe == 'PENGABDIAN'">Masa pengerjaan </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in penelitianDosenList" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.dosen }}</td>
                                <td>{{ record.judul }}</td>
                                <td>{{ record.semester }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.tahun_penulisan }}</td>
                                <td>{{ record.jenis_penelitian }}</td>
                                <td>{{ rupiah(record.jumlah_dana) }}</td>
                                <td>{{ record.sumber_dana }}</td>
                                <td v-if="tipe == 'PUBLIKASI'">{{ record.link_publikasi }}</td>
                                <td v-if="tipe == 'HKI'"><img :src="record.sertifikat" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        @click="openInNewTab(record.sertifikat)">
                                </td>
                                <td v-if="tipe == 'PENELITIAN' || tipe == 'PENGABDIAN'">{{ record.masa_pengerjaan }}
                                </td>


                            </tr>
                        </tbody>
                    </table>

                </div>
                <!-- <div class="modal-footer"> -->
                <!-- <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->

                <!-- </div> -->


            </div>

        </div>
    </div>


    <div class="modal fade" id="modal_generate_krs" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_generate_krs">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate SKS dosen</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_generate_krs"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_generate_krs', 'close_modal_generate_krs')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tanggal_mulai" class="form-label">Tanggal Mulai</label>
                        <input type="date" class="form-control" v-model="tanggal_mulai" required />

                    </div>
                    <div class="mb-3">
                        <label for="tanggal_selesai" class="form-label">Tanggal Selesai</label>
                        <input type="date" class="form-control" v-model="tanggal_selesai" required />
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_generate_krs', 'close_modal_generate_krs')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcel(tanggal_mulai, tanggal_selesai, 'generate_krs_dosen', 'SKS Dosen', config)">
                        Generate </button>

                </div>


            </div>

        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, menu_akses_cek, DateNow, BooleanToNumber, generateExcel, CloseLoading, ShowLoading, ErrorConnectionTimeOut, AlertBottom, NumberOnly, CharOnly, sarjanaList, magisterList, agamaList, jenisKelaminList, rupiah } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";
import vueSelect from 'vue-select';

export default {
    data() {
        return {
            //data
            url: 'dosen',
            url_get_penelitian: 'get_penelitian_dosen',
            url_get_gelar_dosen: 'gelar_dosen',
            api: "",

            bidangList: "",
            jabatanStrukturalList: "",
            jabatanFungsionalList: "",
            prodiList: [],
            isLoad: 0,

            statusDoseList: [
                {
                    nama: "Dosen tetap menetap",
                },
                {
                    nama: "Dosen tetap tidak menetap",
                },
                {
                    nama: "Dosen luar biasa",
                },
                {
                    nama: "Dosen khusus",
                },
            ],

            //list
            sarjanaList: [],
            magisterList: [],
            agamaList: [],
            jenisKelaminList: [],
            golonganList: [],
            penelitianDosenList: [],
            // selected tags
            selectedTagsProdi: [],
            gelarDosenList: [],
            statusGelarList: [{ nama: "D3" }, { nama: "D4" }, { nama: "S1" }, { nama: "S2" }, { nama: "S3" }],
            statusGelar: "",

            //pages
            limit: "5",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,

            //filter p.enelitian dosen 
            tipe: "PENELITIAN",
            prodiFilter: "",
            query: "",


            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: {
                nama: "", jenis_kelamin: "", agama: "", alamat: "", username: "", password: "", bidang: "", jabatan_struktural: "*", jabatan_fungsional: "*", nidn: "", nip: "", email: "", whatsapp: "", status_dosen: "", tipe: "",
                prodi: "", golongan: "*", nama_bidang: "", status_dekan: false, status_rektor: false, status_kaprodi: false,
            },
            currentRecord: {},

            gelarDosenRecord: [],
            currentGelarDosenRecord: {},

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            config: "",
            tanggal_mulai: "",
            tanggal_selesai: "",

        }
    },
    components: {
        SideNavbar, vueSelect
    },

    methods: {
        NumberOnly,
        CharOnly,
        rupiah,
        generateExcel,
        get_penelitian_dosen(id_dosen) {
            this.penelitianDosenList = [];



            ShowLoading();
            let formData = new FormData();

            if (id_dosen == "" || id_dosen == undefined) {
                formData.append("id_dosen", localStorage.getItem('dosen'));
            } else {
                localStorage.setItem('dosen', id_dosen);
                formData.append("id_dosen", id_dosen);
            }

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);

            formData.append("tipe", this.tipe);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'get_penelitian_dosen';
            } else {
                this.api = base_url + 'get_penelitian_dosen';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.penelitianDosenList = response.data.data[1];

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },
        index_gelar_dosen(id_dosen) {
            ShowLoading();
            let formData = new FormData();
            formData.append("dosen", id_dosen);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url_get_gelar_dosen + '_index';
            } else {
                this.api = base_url + this.url_get_gelar_dosen + '_index';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.gelarDosenRecord = response.data.data[0];

                        if (response.data.data != undefined) {
                            // this.arrayPreviousIdOld.push(response.data.data[1][0].id);
                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },
        insert_gelar_dosen(id_dosen) {
            if (id_dosen != undefined) {

                ShowLoading();
                const formData = new FormData();
                formData.append('dosen', id_dosen);
                formData.append('jenjang_pendidikan', this.statusGelar);
                Object.entries(this.currentGelarDosenRecord).forEach(([key, value]) => {
                    formData.append(key, value);
                });

                // for (let [key, value] of formData.entries()) {
                //     console.log(`${key}: ${value}`);
                // }
                if (process.env.NODE_ENV === "development") {
                    this.api = base_url + this.url_get_gelar_dosen + "_insert";
                } else {
                    this.api = base_url + this.url_get_gelar_dosen + "_insert";
                }

                // Add a new record to the API
                axios
                    .post(this.api, formData, this.config)
                    .then((response) => {
                        if (response.data.status == true) {
                            this.gelarDosenRecord.unshift(response.data.data);

                            CloseLoading();
                            AlertBottom(response.data.message);
                            // this.hideModal('modal_tambah', 'close_modal_tambah')
                        }
                        if (response.data.status == false) {
                            AlertPopup("error", "", response.data.message, 1500, false);
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            } else {
                this.currentGelarDosenRecord.id = this.gelarDosenRecord.length;
                this.currentGelarDosenRecord.jenjang_pendidikan = this.statusGelar;
                this.gelarDosenRecord.unshift({ ...this.currentGelarDosenRecord });
                // this.gelarDosenRecord.unshift(a);
            }
        },
        insert_gelar_dosen_multiple(id_dosen, jenjang_pendidikan, gelar, bidang, tahun, nama_pt) {
            if (id_dosen != undefined) {
                const formData = new FormData();
                formData.append('dosen', id_dosen);
                formData.append('jenjang_pendidikan', jenjang_pendidikan);
                formData.append('gelar', gelar);
                formData.append('bidang', bidang);
                formData.append('tahun', tahun);
                formData.append('nama_pt', nama_pt);
                // Object.entries(this.currentGelarDosenRecord).forEach(([key, value]) => {
                //     formData.append(key, value);
                // });

                // for (let [key, value] of formData.entries()) {
                //     console.log(`${key}: ${value}`);
                // }
                if (process.env.NODE_ENV === "development") {
                    this.api = base_url + this.url_get_gelar_dosen + "_insert";
                } else {
                    this.api = base_url + this.url_get_gelar_dosen + "_insert";
                }

                // Add a new record to the API
                axios
                    .post(this.api, formData, this.config)
                    .then((response) => {
                        if (response.data.status == true) {
                            this.gelarDosenRecord.unshift(response.data.data);

                            // AlertBottom(response.data.message);
                            // this.hideModal('modal_tambah', 'close_modal_tambah')
                        }
                        if (response.data.status == false) {
                            AlertPopup("error", "", response.data.message, 1500, false);
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            }
        },


        delete_gelar_dosen(id, no_id) {
            if (no_id == undefined) {
                ShowLoading();
                const formData = new FormData();
                formData.append("id", id);

                if (process.env.NODE_ENV === "development") {
                    this.api = base_url + this.url_get_gelar_dosen + "_delete";
                } else {
                    this.api = base_url + this.url_get_gelar_dosen + "_delete";
                }
                // Delete a record from the API
                axios
                    .post(this.api, formData, this.config)
                    .then((response) => {
                        if (response.data.status == true) {
                            // Remove the record from the records list
                            const index = this.gelarDosenRecord.findIndex((record) => record.id === id);
                            this.gelarDosenRecord.splice(index, 1);
                            CloseLoading();
                            AlertBottom(response.data.message);
                            // this.hideModal('modal_delete', 'close_modal_delete');
                        } else {
                            AlertPopup("error", "", response.data.message, 1500, false);
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            } else {
                const index = this.gelarDosenRecord.findIndex((record) => record.id === id);
                this.gelarDosenRecord.splice(index, 1);
            }

        },

        index() {
            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("prodi", this.prodiFilter);
            formData.append("query", this.query);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.bidangList = response.data.bidang;
                        // console.log(response.data.bidang);
                        this.jabatanStrukturalList = response.data.jabatan_struktural;
                        this.jabatanFungsionalList = response.data.jabatan_fungsional;
                        this.prodiList = response.data.prodi;
                        this.golonganList = response.data.golongan;

                        if (response.data.data != undefined) {
                            if (this.isLoad != 0) {
                                this.records = response.data.data[1];
                            } else {
                                this.isLoad = 1;
                            }

                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1][0].id;

                            let last_index = Object.keys(response.data.data[1]).length - 1;
                            this.NextLastId = response.data.data[1][last_index].id;
                            // this.arrayPreviousIdOld.push(response.data.data[1][0].id);



                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {

            if (this.totalPages <= this.numberPage) {
                return true
            }
            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);
            formData.append("prodi", this.prodiFilter);
            formData.append("query", this.query);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records = response.data.data[1];
                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage + 1;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {

            if (this.numberPage == 1) {
                return true
            }


            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.previousLastId);
            formData.append("last_id", "");
            formData.append("limit", this.limit);
            formData.append("prodi", this.prodiFilter);
            formData.append("query", this.query);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records = response.data.data[1];
                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage - 1;


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert() {
            ShowLoading();
            const formData = new FormData();

            this.newRecord.status_dekan = BooleanToNumber(this.newRecord.status_dekan);
            this.newRecord.status_rektor = BooleanToNumber(this.newRecord.status_rektor);

            if (this.newRecord.bidang != undefined && this.newRecord.bidang != '') {
                const array = this.newRecord.bidang;
                this.newRecord.bidang = array.join(','); // Join elements with a comma and space as a separator
            }

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }



            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {


                        this.records.unshift(response.data.data);

                        if (response.data.data.bidang != '') {
                            const a = response.data.data.bidang.split(',');
                            let b = []
                            for (let [key, value] of a.entries()) {
                                const index = this.bidangList.findIndex((record) => record.id == value);
                                console.log(key);
                                b.push(this.bidangList[index].nama);
                            }
                            this.records[0].nama_bidang = b.join(',');
                        }




                        // Clear the form inputs
                        this.newRecord = {
                            nama: "", jenis_kelamin: "", agama: "", alamat: "", username: "", password: "", bidang: "", jabatan_struktural: "*", jabatan_fungsional: "*", nidn: "", nip: "", email: "", whatsapp: "", status_dosen: "", tipe: "",
                            prodi: "", golongan: "*", nama_bidang: "", status_dekan: false, status_rektor: false, status_kaprodi: false,
                        }

                        //console.log(true);
                        if (this.gelarDosenRecord.length > 0) {
                            this.gelarDosenRecord.forEach((record, index) => {
                                this.insert_gelar_dosen_multiple(response.data.data.id, record.jenjang_pendidikan, record.gelar, record.bidang, record.tahun, record.nama_pt);
                                console.log(index);
                            });

                        }
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_tambah', 'close_modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        if (this.newRecord.bidang != undefined && this.newRecord.bidang.length > 0 && this.newRecord.bidang != '') {
                            const a = this.newRecord.bidang.split(',');

                            let b = [];

                            for (let [key, value] of a.entries()) {
                                const index = this.bidangList.findIndex((record) => record.id == value);
                                console.log(key);
                                b.push(this.bidangList[index].id);
                            }
                            this.newRecord.bidang = "";
                            this.newRecord.bidang = b;
                        }


                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        editRecord(record) {
            this.currentGelarDosenRecord = [];
            this.index_gelar_dosen(record.id);
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);

            // this.setRecordwithId('bidang', this.bidangList, 'nama', 'id');
            this.setRecordwithId('jabatan_struktural', this.jabatanStrukturalList, 'nama', 'id');
            this.setRecordwithId('jabatan_fungsional', this.jabatanFungsionalList, 'nama', 'id');
            this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            this.setRecordwithId('golongan', this.golonganList, 'nama', 'id');

            this.showModal('modal_edit');


            if (this.currentRecord.bidang != "") {
                const a = this.currentRecord.bidang.split(',');
                let b = [];

                for (let [key, value] of a.entries()) {
                    const index = this.bidangList.findIndex((record) => record.id == value);
                    key
                    b.push(this.bidangList[index].id);
                }
                this.currentRecord.bidang = b;
            }

        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },


        update() {
            ShowLoading();
            const formData = new FormData();
            this.currentRecord.status_dekan = BooleanToNumber(this.currentRecord.status_dekan);
            this.currentRecord.status_rektor = BooleanToNumber(this.currentRecord.status_rektor);

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}

                        this.records.splice(index, 1, response.data.data);
                        if (response.data.data.bidang != '') {
                            const a = response.data.data.bidang.split(',');

                            let b = []
                            for (let [key, value] of a.entries()) {
                                const index = this.bidangList.findIndex((record) => record.id == value);
                                console.log(key);
                                console.log(index);
                                console.log(this.bidangList[index]);
                                b.push(this.bidangList[index].nama);
                            }
                            this.records[index].nama_bidang = b.join(',');
                        }


                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        const index = this.records.findIndex(
                            (record) => record.id == this.currentRecord.id
                        );
                        let a = "";
                        if (this.currentRecord.bidang.length > 1) {
                            a = this.currentRecord.bidang.split(',');

                            let b = []
                            for (let [key, value] of a.entries()) {
                                const index = this.bidangList.findIndex((record) => record.id == value);
                                console.log(key);
                                b.push(this.bidangList[index].id);
                            }
                            this.records[index].bidang = b.join(',');
                        }

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            formData.append("url", "ra_rekomendasi_delete");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },


        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 200)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            console.log(this.temporaryId)
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        },

        updateGelar(pilihan, bidang) {

            if (pilihan.slice(-1) == 1) {
                // if (tipe == 'insert') {
                //     const index = this.sarjanaList.findIndex((record) => record.nama == this.newRecord[bidang]);
                //     this.newRecord[pilihan] = sarjanaList[index].kode;
                // } else {
                //     const index = this.sarjanaList.findIndex((record) => record.nama == this.currentRecord[bidang]);
                //     this.currentRecord[pilihan] = sarjanaList[index].kode;
                // }
                const index = this.sarjanaList.findIndex((record) => record.nama == this.currentGelarDosenRecord[bidang]);

                this.currentGelarDosenRecord.gelar = sarjanaList[index].kode;
            } else {
                // if (tipe == 'insert') {
                //     const index = this.magisterList.findIndex((record) => record.nama == this.newRecord[bidang]);
                //     this.newRecord[pilihan] = magisterList[index].kode;
                // } else {
                //     const index = this.magisterList.findIndex((record) => record.nama == this.currentRecord[bidang]);
                //     this.currentRecord[pilihan] = magisterList[index].kode;
                // }
                const index = this.magisterList.findIndex((record) => record.nama == this.currentGelarDosenRecord[bidang]);
                this.currentGelarDosenRecord.gelar = magisterList[index].kode;
            }


        },
        onTagProdiAdded(slug) {
            const index = this.selectedTags.findIndex((record) => record.id === slug.id);
            if (index == -1) {
                this.selectedTags.push(slug);
            }

        },
        onTagProdiRemoved(slug) {
            const index = this.selectedTags.findIndex((record) => record.id === slug.id);
            this.selectedTags.splice(index, 1);
        },

        cekDekanRektor(status) {

            if (status == 'insert') {
                if (this.newRecord.status_dekan == false || this.newRecord.status_dekan == '0') {
                    this.newRecord.status_rektor = false;
                } else if (this.newRecord.status_rektor == false || this.newRecord.status_rektor == '0') {
                    this.newRecord.status_dekan = false;
                } else if (this.newRecord.status_kaprodi == false || this.newRecord.status_kaprodi == '0') {
                    this.newRecord.status_kaprodi = false;
                }
            } else {
                // console.log(this.currentRecord.status_rektor == false);
                // console.log('value');
                // console.log(this.currentRecord.status_rektor)
                if (this.currentRecord.status_dekan == false || this.currentRecord.status_dekan == '0') {
                    this.currentRecord.status_rektor = false;
                } else if (this.currentRecord.status_rektor == 'false' || this.currentRecord.status_rektor == '0') {
                    this.currentRecord.status_dekan = false;
                } else if (this.currentRecord.status_kaprodi == 'false' || this.currentRecord.status_kaprodi == '0') {
                    this.currentRecord.status_kaprodi = false;
                }

            }
        }


    },
    computed: {
        // Convert selectedTags array to a computed property
        // This ensures the selected tags are always up to date
        // with the component's data.
        filteredRecords() {
            return this.records.filter(record => {
                return record;
            }).map(record => {
                // Create a new object with all the existing attributes and add the new attribute 'profile'
                const a = record.bidang.split(',');
                let b = [];
                for (let [key, value] of a.entries()) {
                    const index = this.bidangList.findIndex((record) => record.id == value);
                    key;
                    if (index > -1) {
                        b.push(this.bidangList[index].nama);
                    }

                }
                b = b.join(',');


                return {
                    ...record,
                    nama_bidang: b
                };
            });
        }
    },

    mounted() {
        this.sarjanaList = sarjanaList;
        this.magisterList = magisterList;
        this.jenisKelaminList = jenisKelaminList;
        this.agamaList = agamaList;


    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();
        this.tanggal_mulai = DateNow();
        this.tanggal_selesai = DateNow();

    }



}
</script>