<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="sticky-top bg-white">
                <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                    Tagihan
                </div>
                <div class="mt-2 mb-2 me-0  bg-white">
                    <div class="d-flex justify-content-between align-items-center me-2">
                        <div class="d-flex justify-content-start align-items-center gap-1">
                            <div class="" style="width: 15%!important;">
                                <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                    id="tahuna_ajaran" v-model="tahunAjaranFilter"
                                    @change="pageActive = 1; offset = 0; index();">
                                    <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                    <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                        {{ list.nama }}
                                    </option>
                                </select>
                            </div>
                            <!-- <div class="">
                            <select class="form-select" aria-label="Default select example" name="nama_item" id="nama_item"
                                v-model="namaItemFilter" @change="pageActive = 1; offset = 0; index(); query = ''" required>
                                <option value="" selected disabled>- Pilih Nama Item </option>
                                <option v-for=" list  in  namaItemList " :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->

                            <div class="">
                                <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                    v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                                    <option value="" selected :disabled="tahunAjaranFilter == ''">- Pilih Prodi</option>
                                    <option v-for=" list in prodiList " :key="list.nama" :value="list.id">
                                        {{ list.nama }}
                                    </option>
                                </select>
                            </div>
                            <div class="">
                                <select class="form-select" aria-label="Default select example" name="semester"
                                    id="semester" v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()"
                                    required :disabled="prodiFilter == '' || tahunAjaranFilter == ''">
                                    <option value="" selected>- Pilih Semester</option>
                                    <option v-for=" list in semesterList " :key="list.nama" :value="list.id">
                                        {{ list.nama }}
                                    </option>
                                </select>
                            </div>
                            <div class="">
                                <select class="form-select" aria-label="Default select example" name="stambuk"
                                    id="stambuk" v-model="stambukFilter" @change="pageActive = 1; offset = 0; index()"
                                    required :disabled="prodiFilter == '' || tahunAjaranFilter == ''">
                                    <option value="" selected>- Pilih Stambuk</option>
                                    <option v-for=" list in stambukList " :key="list.nama" :value="list.id">
                                        {{ list.nama }}
                                    </option>
                                </select>
                            </div>
                            <div class="">
                                <select class="form-select" aria-label="Default select example" name="jalur" id="jalur"
                                    v-model="jalurFilter" @change="pageActive = 1; offset = 0; index(); query = ''"
                                    required :disabled="prodiFilter == '' || tahunAjaranFilter == ''">
                                    <option value="" selected>- Pilih Jalur</option>
                                    <option v-for=" list in jalurList " :key="list.nama" :value="list.value">
                                        {{ list.nama }}
                                    </option>
                                    <option value="SEMUA">
                                        Semua
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class=" d-flex align-items-center justify-content-end gap-2">
                            <button v-if="update_akses == true"
                                class="btn bg-border-green-custom fs-8 py-1 font-green-custom fw-bold font-custom-4 "
                                style="border-radius: 5px;" @click="showModal2('modal_finalisasi'); prodi = ''">
                                Finalisasi
                            </button>
                            <button v-if="create_akses == true"
                                class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;" @click="showModal2('modal_tambah'); newRecord = []; newRecord.tipe = '';
                                newRecord.tanggal = DateNow(); newRecord.tanggal_akhir = DateNow(); prodi = ''">
                                <img src="@/assets/icon/data_plus.png" alt="" width="15">
                                Generate
                            </button>

                        </div>

                    </div>

                    <div class="d-flex align-items-start justify-content-between mt-2  me-2">
                        <div class="d-flex align-items-start justify-content-start gap-2">


                            <div>
                                <select class="form-select" aria-label="Default select example" name="semester"
                                    id="semester" v-model="limit" @change="pageActive = 1; offset = 0; index()"
                                    required>
                                    <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                        @change=index()>
                                        {{ list.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex justify-content-center  align-items-center">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination" ref="pagi">
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActive != 1) { pageActive--; offset = offset - limit; index('previous') };"
                                                aria-label="Previous" style="cursor:pointer;">
                                                <span aria-hidden="true">&laquo;</span>
                                            </span>
                                        </li>
                                        <li v-for=" list in paginglist " :key="list.nama" :value="list.value"
                                            class="page-item"
                                            :class="{ 'active': list.nama == pageActive, 'd-none': list.show == false }">
                                            <span class="page-link text-black "
                                                @click="if (list.disabled != true) pageActive = list.nama; offset = list.value; index()"
                                                style="cursor: pointer;">{{
                                                    list.nama }}</span>
                                        </li>
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActive != totalPages) { pageActive++; offset = offset + limit; index('next') };"
                                                aria-label="Next" style="cursor:pointer;">
                                                <span aria-hidden="true">&raquo;</span>
                                            </span>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="">
                                <select class="form-select" aria-label="Default select example" name="status_invoice"
                                    id="status_invoice" v-model="statusInvoiceFilter"
                                    @change="pageActive = 1; offset = 0; index(); query = ''" required>
                                    <option value="" selected disabled>- Pilih Status </option>
                                    <option value="PENDING" selected>PENDING</option>
                                    <option value="SUCCESS" selected>SUCCESS</option>
                                </select>
                            </div>
                            <div class="">
                                <input type="text" v-model="query" @change="pageActive = 1; offset = 0; index()"
                                    class="form-control" placeholder="Cari ">
                            </div>

                        </div>
                        <div class="d-flex align-items-end">
                            <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;" @click="showModal2('modal_export')">
                                <i class='bx bx-export fs-8 me-2'></i>
                                Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column gap-2 mb-5">
                <div class="border mt-1 rounded-3 me-2 font-custom-1"
                    style="overflow-y:scroll; max-height: 63vh!important;">
                    <table class="table mb-0 bg-transparent table-borderless ">
                        <thead class="sticky-top " style="top: 0px;">
                            <tr>
                                <th scope="col" class="fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">NIM </th>
                                <th scope="col" class="font-custom-1 fs-7">Nama lengkap </th>
                                <th scope="col" class="font-custom-1 fs-7">Tagihan </th>
                                <th scope="col" class="font-custom-1 fs-7">Nomor Invoice </th>
                                <th scope="col" class="font-custom-1 fs-7">Tanggal </th>
                                <th scope="col" class="font-custom-1 fs-7">Tanggal Akhir</th>
                                <th scope="col" class="font-custom-1 fs-7">Jumlah</th>
                                <th scope="col" class="font-custom-1 fs-7">Status Pembayaran</th>
                                <th scope="col" class="font-custom-1 fs-7">Status </th>
                                <th scope="col" class="fs-7" style="width:5%!important">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.nim_mahasiswa }}</td>
                                <td>{{ capitalizeWords(record.nama_lengkap) }}</td>
                                <td>{{ record.item }}</td>
                                <td>{{ record.nomor_invoice }}</td>
                                <td>{{ record.tanggal }}</td>
                                <td>{{ record.tanggal_akhir }}</td>
                                <td>{{ rupiah(record.jumlah) }}</td>
                                <td>{{ record.status_text }}</td>
                                <td>{{ record.status_invoice }}</td>
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button class="btn btn-secondary btn-sm fs-8"
                                            v-on:click="viewInvoice(record.nomor_invoice)"><i
                                                class='bx bxs-show fs-8'></i></button>
                                        <button class="btn btn-primary btn-sm fs-8" v-on:click="editRecordUjian(record)"
                                            v-if="record.status_invoice == 'FINAL' && record.status_text == 'PENDING'"><i
                                                class='bx bxs-pencil fs-8'></i></button>
                                        <button class="btn btn-warning btn-sm fs-8" v-on:click="editRecord(record)"
                                            v-if="record.status_invoice == 'PENDING'"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <button class="btn btn-danger btn-sm fs-8" data-bs-toggle="modal"
                                            data-bs-target="#modal_delete" v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>
    <form @submit.prevent="cekGenerate()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_tambah">
            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Tagihan</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3 ">
                            <label for="jabatan" class="form-label">Pilih Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" v-model="tahunAjaranFilter"
                                @change="pageActive = 1; offset = 0; index();" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 ">
                            <label for="jabatan" class="form-label">Pilih Prodi</label>
                            <!-- <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for=" list  in  prodiList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select> -->
                            <vue-select v-model="prodi" :options="prodiStambukList" label="nama" :multiple="true"
                                :reduce="tag => tag.id" :searchable="true" :close-on-select="false"
                                :limit="10"></vue-select>
                        </div>

                        <div class="mb-3 ">
                            <label for="jabatan" class="form-label">Pilih Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()"
                                required :disabled="prodi == '' || tahunAjaranFilter == ''">
                                <option value="" selected>- Pilih Semester</option>
                                <option v-for=" list in semesterList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <!-- <div class="mb-3 ">
                            <label for="stambuk" class="form-label">Pilih Stambuk</label>
                            <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                                v-model="stambukFilter" @change="pageActive = 1; offset = 0; index()" required
                                :disabled="prodi == '' || tahunAjaranFilter == ''">
                                <option value="" selected>- Pilih Stambuk</option>
                                <option v-for=" list in stambukList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                        <div class="mb-3 ">
                            <label for="stambuk" class="form-label">Pilih Jalur</label>
                            <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                                v-model="jalurFilter" @change="pageActive = 1; offset = 0; index()" required
                                :disabled="prodi == '' || tahunAjaranFilter == ''">
                                <option value="" selected>- Pilih Jalur</option>
                                <option v-for=" list in jalurList " :key="list.nama" :value="list.value">
                                    {{ list.nama }}
                                </option>
                                <option value="SEMUA">
                                    Semua
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 ">
                            <label for="tipe_generate" class="form-label">Pilih Tipe Generate</label>
                            <select class="form-select" aria-label="Default select example" name="tipe_generate"
                                id="tipe_generate" v-model="newRecord.tipe"
                                @change="pageActive = 1; offset = 0; index()" required>
                                <option value="" selected disabled>- Pilih Tipe</option>
                                <option value="baru">
                                    Generate Invoice baru
                                </option>
                                <option value="belum_bayar">
                                    Generate Invoice baru untuk yang belum bayar sampe waktu yang ditentukan
                                </option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="newRecord.tipe == 'belum_bayar'">
                            <label for="nama_item" class="form-label">Nama Item</label>
                            <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                                v-model="newRecord.nama_item" @change="pageActive = 1; offset = 0; index()" required
                                :disabled="semesterFilter == ''">
                                <option value="" selected>- Pilih Nama Tagihan</option>
                                <option v-for=" list in namaItemList " :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3 " v-if="newRecord.tipe == 'belum_bayar'">
                            <label for="tanggal" class="form-label">Tanggal tagihan</label>
                            <select class="form-select" aria-label="Default select example" name="tanggal" id="tanggal"
                                v-model="newRecord.tanggal_item" @change="pageActive = 1; offset = 0; index()" required
                                :disabled="semesterFilter == ''">
                                <option value="" selected>- Pilih Tanggal</option>
                                <option v-for=" list in tanggalList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 " v-if="newRecord.tipe == 'baru'">
                            <label for="nama_item" class="form-label">Nama Item</label>
                            <input type="text" class="form-control" @input="CharAndNumberOnly"
                                v-model="newRecord.nama_item" />
                        </div>
                        <div class="mb-3 " v-if="newRecord.tipe == 'belum_bayar' && newRecord.nama_item != ''">
                            <label for="nama_item_baru" class="form-label">Nama Item Baru</label>
                            <input type="text" class="form-control" @input="CharAndNumberOnly"
                                v-model="newRecord.nama_item_baru" />
                        </div>
                        <div class="mb-3 ">
                            <label for="nama_item" class="form-label">Jumlah</label>
                            <input type="text" class="form-control"
                                @input="changerupiahInputInsert($event.target.value, 'jumlah', 'insert')"
                                v-model="newRecord.jumlah" />
                        </div>
                        <div class="mb-3 ">
                            <label for="nama_item" class="form-label">Tanggal Mulai tagihan</label>
                            <input type="date" class="form-control" v-model="newRecord.tanggal" />
                        </div>
                        <div class="mb-3 ">
                            <label for="tanggal_akhir_item" class="form-label">Tanggal Akhir</label>
                            <input type="date" class="form-control" v-model="newRecord.tanggal_akhir" />
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>

    <form @submit.prevent="finalisasi()">
        <div class="modal fade" id="modal_finalisasi" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_finalisasi">
            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Finalisasi</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_finalisasi"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_finalisasi')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="jabatan" class="form-label">Pilih Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" v-model="tahunAjaranFilter"
                                @change="pageActive = 1; offset = 0; index();" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="jabatan" class="form-label">Pilih Prodi</label>
                            <!-- <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for=" list  in  prodiList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select> -->
                            <vue-select v-model="prodi" :options="prodiStambukList" label="nama" :multiple="true"
                                :reduce="tag => tag.id" :searchable="true" :close-on-select="false"
                                :limit="10"></vue-select>
                        </div>
                        <div class="mb-3">
                            <label for="jabatan" class="form-label">Pilih Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()"
                                required :disabled="prodi == ''">
                                <option value="" selected>- Pilih Semester</option>
                                <option v-for=" list in semesterList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <!-- <div class="mb-3 ">
                            <label for="stambuk" class="form-label">Pilih Stambuk</label>
                            <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                                v-model="stambukFilter" @change="pageActive = 1; offset = 0; index()" required
                                :disabled="prodi == ''">
                                <option value="" selected>- Pilih Stambuk</option>
                                <option v-for=" list  in  stambukList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                        <div class="mb-3 ">
                            <label for="nama_item" class="form-label">Nama Item</label>
                            <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                                v-model="newRecord.nama_item" @change="pageActive = 1; offset = 0; index()" required
                                :disabled="semesterFilter == ''">
                                <option value="" selected>- Pilih Nama Tagihan</option>
                                <option v-for=" list in namaItemList " :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3 ">
                            <label for="tanggal" class="form-label">Tanggal tagihan</label>
                            <select class="form-select" aria-label="Default select example" name="tanggal" id="tanggal"
                                v-model="newRecord.tanggal" @change="pageActive = 1; offset = 0; index()" required
                                :disabled="semesterFilter == ''">
                                <option value="" selected>- Pilih Tanggal</option>
                                <option v-for=" list in tanggalList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_finalisasi')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_edit')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nim" class="form-label"> NIM </label>
                            <input type="text" class="form-control" disabled v-model="currentRecord.nim_mahasiswa" />
                        </div>
                        <div class="mb-3">
                            <label for="nama_lengkap" class="form-label"> Nama Lengkap</label>
                            <input type="text" class="form-control" disabled v-model="currentRecord.nama_lengkap" />
                        </div>
                        <div class="mb-3">
                            <label for="jumlah" class="form-label"> Jumlah Cicilan</label>
                            <input type="text" class="form-control"
                                @input="changerupiahInputInsert($event.target.value, 'jumlah', 'update')"
                                v-model="currentRecord.jumlah" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Keterangan <span class="text-danger">*</span></label>
                            <textarea class="form-control" v-model="currentRecord.keterangan" required />
                        </div>
                        <div class="mb-3">
                            <label for="jumlah" class="form-label"> Status pembayaran</label>
                            <select class="form-select" aria-label="Default select example" name="status_pembayaran"
                                id="status_pembayaran" v-model="currentRecord.status_text" required>
                                <option value="" selected disabled>- Pilih Status Pembayaran</option>
                                <option value="PENDING">PENDING</option>
                                <option value="SUCCESS" selected>SUCCESS</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="hideModal2('modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit_ujian" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit_ujian">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_edit_ujian')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nim" class="form-label"> NIM </label>
                            <input type="text" class="form-control" disabled v-model="currentRecord.nim_mahasiswa" />
                        </div>
                        <div class="mb-3">
                            <label for="nama_lengkap" class="form-label"> Nama Lengkap</label>
                            <input type="text" class="form-control" disabled v-model="currentRecord.nama_lengkap" />
                        </div>
                        <div class="mb-3">
                            <label for="jumlah" class="form-label"> Status Ujian</label>
                            <select class="form-select" aria-label="Default select example" name="status_ujian"
                                id="status_ujian" v-model="currentRecord.status_ujian" required>
                                <option value="" selected disabled>- Pilih Status Ujian</option>
                                <option value="YA">Diperbolehkan Ujian</option>
                                <option value="TIDAK" selected>Tidak Diperbolehkan Ujian</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="jumlah" class="form-label"> Jumlah Cicilan</label>
                            <input type="text" class="form-control"
                                @input="changerupiahInputInsert($event.target.value, 'jumlah', 'update')"
                                v-model="currentRecord.jumlah" />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_akhir" class="form-label"> Tanggal terakhir pembayaran</label>
                            <input type="date" class="form-control" v-model="currentRecord.tanggal_akhir" />
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Keterangan <span class="text-danger">*</span></label>
                            <textarea class="form-control" v-model="currentRecord.keterangan" required />
                        </div>
                        <div class="mb-3">
                            <label for="jumlah" class="form-label"> Status pembayaran</label>
                            <select class="form-select" aria-label="Default select example" name="status_pembayaran"
                                id="status_pembayaran" v-model="currentRecord.status_text" required>
                                <option value="" selected disabled>- Pilih Status Pembayaran</option>
                                <option value="PENDING">PENDING</option>
                                <option value="SUCCESS" selected>SUCCESS</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_edit_ujian')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>

                    </div>
                </div>
            </div>
        </div>
    </form>


    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan
                        disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

    <form @submit.prevent="exportInvoice">
        <div class="modal fade" id="modal_export" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_export">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Ekspor Data</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_export')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nim" class="form-label"> Tahun Ajaran </label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" v-model="tahunAjaranFilter"
                                @change="pageActive = 1; offset = 0; index();">
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="nim" class="form-label"> Semester </label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()"
                                required :disabled="tahunAjaranFilter == ''">
                                <option value="" selected>- Pilih Semester</option>
                                <option v-for=" list in semesterList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_export')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>

                    </div>
                </div>
            </div>
        </div>
    </form>



</template>

<style scoped>
.pagination {
    --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
    background-color: #0D9044 !important;
    border-color: #0D9044 !important;
    color: white !important;
}


.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, AlertBottom, base_url, menu_akses_cek, CloseLoading, ShowLoading, ErrorConnectionTimeOut, CharAndNumberOnly, NumberOnly, rupiah, rupiahInput, rupiahoutput, capitalizeWords, DateNow, addNumberToObjects } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";
import XLSX from "xlsx/dist/xlsx.full.min.js";

import vueSelect from 'vue-select';


export default {
    data() {
        return {
            //data
            url: 'invoice_cicilan_mandiri',
            menu: 'tagihan',
            api: "",

            //pages
            limit: 10,
            offset: 0,
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            currentPageActive: 1,
            pageActive: 1,


            //list
            prodiList: [],
            tahunAjaranList: [],
            semesterList: [],
            stambukList: [],
            paginglist: [],
            namaItemList: [],
            tanggalList: [],
            jalurList: [],
            limitList: [{
                value: 10
            }, {
                value: 20
            }, {
                value: 50
            }
            ],
            batas_page_awal: 0,
            batas_page_akhir: 5,
            prodiStambukList: [],


            //krs
            progresKrs: 0,
            isLoading: false,
            idKrsLast: 0,
            interval: null,
            updateGenerateKrsId: 0,

            //filter
            prodiFilter: "",
            tahunAjaranFilter: "",
            stambukFilter: "",
            semesterFilter: "",
            jalurFilter: "",
            namaItemFilter: "",
            statusInvoiceFilter: "",
            query: "",
            isLoad: 0,

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: { prodi: "", tahun_ajaran: "", semester: "" },
            currentRecord: {},

            //generate
            prodi: "",


        }
    },
    components: {
        SideNavbar, vueSelect
    },

    methods: {
        DateNow,
        CharAndNumberOnly,
        capitalizeWords,
        rupiah,
        rupiahInput,
        rupiahoutput,
        NumberOnly,
        addNumberToObjects,

        changerupiahInputInsert(value, pilihan, status) {
            if (status == 'insert') {
                if (value.length == 5 && value.slice(-1) == ",") {
                    value = "Rp 0,-";
                    this.newRecord[pilihan] = rupiahInput(value);
                }
                this.newRecord[pilihan] = rupiahInput(value);

            } else {
                this.currentRecord[pilihan] = rupiahInput(value);

            }
        },
        viewInvoice(nomor_invoice) {
            window.open('view_invoice?nomor_invoice=' + nomor_invoice, '_blank');
        },

        index(status) {

            this.paginglist = [];
            ShowLoading();
            this.records = [];


            let formData = new FormData();

            formData.append("limit", this.limit);
            formData.append("offset", this.offset);
            formData.append("stambuk", this.stambukFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("jalur", this.jalurFilter);
            // formData.append("nama_item", this.namaItemFilter);
            formData.append("status_invoice", this.statusInvoiceFilter);
            formData.append("query", this.query);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.prodiList = response.data.prodi;
                        this.semesterList = response.data.semester;
                        this.tahunAjaranList = response.data.tahun_ajaran;
                        this.stambukList = response.data.stambuk;
                        this.namaItemList = response.data.nama_item;
                        this.tanggalList = response.data.tanggal;
                        this.jalurList = response.data.jalur;
                        this.prodiStambukList = response.data.prodi_stambuk;
                        console.log(response.data.prodi_stambuk);

                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];
                            this.totalPages = response.data.data[0][0].number_page;
                            let limit = this.limit;

                            this.pageActive;
                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limit;
                                }

                                if (i <= this.batas_page_akhir && i > this.batas_page_awal) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }


                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhir] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) {

                                    page_list_array[this.batas_page_awal].show = false;
                                    page_list_array[this.batas_page_akhir].show = true;

                                    this.batas_page_awal++;
                                    this.batas_page_akhir++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActive - 1].key) {
                                        this.batas_page_awal--;
                                        this.batas_page_akhir--;

                                        page_list_array[this.batas_page_awal].show = true;
                                        page_list_array[this.batas_page_akhir].show = false;

                                    }


                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) && page_list_array[this.batas_page_akhir] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActive != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglist.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPages,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }
                                // console.log(page_list_array[index_last_true]);
                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglist.push(pemisahPages);
                                    this.paginglist.push(totalPages);
                                }

                            }

                            // this.previousLastId = response.data.data[1][0].id;
                            // let last_index = Object.keys(response.data.data[1]).length - 1;
                            // this.NextLastId = response.data.data[1][last_index].id;
                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },



        stopInterval() {
            clearInterval(this.interval); // Clear the interval
            this.interval = null; // Reset the interval variable
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal2('modal_edit');

            this.currentRecord.jumlah = this.rupiahInput(this.currentRecord.jumlah);
        },
        editRecordUjian(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal2('modal_edit_ujian');

            this.currentRecord.jumlah = this.rupiahInput(this.currentRecord.jumlah);
        },
        cekGenerate() {

            ShowLoading();
            const formData = new FormData();

            let stambuk = [];
            let prodi_array = [];
            if (this.prodi != "") {
                const array = this.prodi;
                this.prodi = array.join(',');

                // stambuk
                const arrayProdi = this.prodi.split(',').map(Number);
                arrayProdi.forEach(value => {
                    const index = this.prodiStambukList.findIndex((record) => record.id === value);
                    stambuk.push(this.prodiStambukList[index].id);
                    prodi_array.push(this.prodiStambukList[index].id_prodi);
                });
                stambuk = stambuk.join(',');
                prodi_array = prodi_array.join(',');

            } else {
                AlertPopup("error", "", "Prodi belum dipilih, silahkan pilih Prodi", 2000, false);
                return true;
            }


            formData.append('tahun_ajaran', this.tahunAjaranFilter);
            formData.append('prodi', prodi_array);
            formData.append('semester', this.semesterFilter);
            formData.append('stambuk', stambuk);
            formData.append('jalur', this.jalurFilter);
            formData.append('jumlah', rupiahoutput(this.newRecord.jumlah))
            formData.append('tanggal', this.newRecord.tanggal)
            formData.append('tanggal_akhir', this.newRecord.tanggal_akhir)
            formData.append('nama_item', this.newRecord.nama_item)
            formData.append('nama_item_baru', this.newRecord.nama_item_baru)
            formData.append('tipe', this.newRecord.tipe)

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_generate";
            } else {
                this.api = base_url + this.url + "_generate";
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        CloseLoading();
                        // this.index();
                        AlertPopup("success", "", response.data.message, 0, true);
                        this.hideModal2('modal_tambah')
                        // setTimeout(() => {
                        //     this.index();
                        // }, 1500);

                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                        if (this.prodi.length > 0 && this.prodi != '') {
                            const a = this.prodi.split(',');

                            let b = [];

                            for (let [key, value] of a.entries()) {
                                key;
                                const index = this.prodiList.findIndex((record) => record.id == value);
                                b.push(this.prodiList[index].id);
                            }

                            this.prodi = "";
                            this.prodi = b;
                        }

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        finalisasi() {
            ShowLoading();
            const formData = new FormData();

            let stambuk = [];
            // if (this.prodi != "") {
            //     const array = this.prodi;
            //     this.prodi = array.join(',');
            // }

            let prodi_array = [];
            // stambuk
            const arrayProdi = this.prodi;
            arrayProdi.forEach(value => {
                const index = this.prodiStambukList.findIndex((record) => record.id === value);
                stambuk.push(this.prodiStambukList[index].id);
                prodi_array.push(this.prodiStambukList[index].id_prodi);
            });
            stambuk = stambuk.join(',');
            prodi_array = prodi_array.join(',');


            formData.append('tahun_ajaran', this.tahunAjaranFilter);
            formData.append('prodi', prodi_array);
            formData.append('semester', this.semesterFilter);
            formData.append('stambuk', stambuk);

            formData.append('nama_item', this.newRecord.nama_item)
            formData.append('tanggal', this.newRecord.tanggal)

            // Object.entries(this.newRecord).forEach(([key, value]) => {
            //     formData.append(key, value);
            // });

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_finalisasi";
            } else {
                this.api = base_url + this.url + "_finalisasi";
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        CloseLoading();
                        AlertPopup("success", "", response.data.message, 1500, false);
                        this.hideModal2('modal_finalisasi')
                        setTimeout(() => {
                            this.index();
                        }, 1500);
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                        if (this.prodi.length > 0 && this.prodi != '') {

                            const a = this.prodi.split(',');

                            let b = [];

                            for (let [key, value] of a.entries()) {
                                const index = this.prodiList.findIndex((record) => record.id == value);
                                key;
                                b.push(this.prodiList[index].id);
                            }

                            this.prodi = "";
                            this.prodi = b;
                        }
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        update() {
            ShowLoading();
            const formData = new FormData();
            this.currentRecord.jumlah = rupiahoutput(this.currentRecord.jumlah);

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_update';
            } else {
                this.api = base_url + this.url + '_update';
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Clear the form inputs
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.records.splice(index, 1, response.data.data);
                        this.currentRecord = {};
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_edit');
                        this.hideModal2('modal_edit_ujian');

                        this.interval = setInterval(() => {
                            this.getProgresKrs(response.data.last_id)
                        }, 1000);


                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.currentRecord.jumlah = this.rupiahInput(this.currentRecord.jumlah);

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            console.log(this.temporaryId)
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        getProgresKrs(last_id) {
            console.log('testing_cek_progress');
            this.idKrsLast = 0;
            const formData = new FormData();
            formData.append('krs', last_id);
            if (process.env.NODE_ENV === "development") {
                // this.api = base_url + this.url + "_insert";
                this.api = base_url + "get_krs_progres";
            } else {
                this.api = base_url + "get_krs_progres";
            }
            console.log(this.api);
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log('hitung');
                    console.log(response);
                    if (response.data.status == true) {
                        this.progresKrs = response.data.progres;
                        this.isLoading = true;
                        console.log(response.data.progres)

                    } else {
                        if (response.data.data != undefined) {
                            const index = this.records.findIndex((record) => record.id === response.data.data[0].id);
                            if (index > -1) {
                                this.records.splice(index, 1, response.data.data[0]);
                            } else {
                                this.records.unshift(response.data.data[0]);
                            }

                            this.stopInterval();
                            localStorage.removeItem('id_progress_krs');
                            this.isLoading = false;
                            this.idKrsLast = 0;
                        } else {
                            this.stopInterval();
                        }

                    }
                })
                .catch((error) => {
                    this.stopInterval();
                    // ErrorConnectionTimeOut(error);
                    console.log(error);

                });
        },
        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)
        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        deleteRecord(id) {
            this.temporaryId = id;
            this.showModal2('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        },
        exportInvoice() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);

            api = base_url + 'invoice_cicilan_mandiri_export';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Invoice');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Invoice' + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.menu, 'create');
        // console.log(menu_akses_cek(this.menu, 'create'));
        this.update_akses = menu_akses_cek(this.menu, 'update');
        this.delete_akses = menu_akses_cek(this.menu, 'delete');
        // this.newRecord.tanggal = DateNow();
        // this.newRecord.tanggal_akhir = DateNow();

        this.index();
        if (localStorage.getItem('id_progress_krs') != null) {
            this.interval = setInterval(() => {
                this.getProgresKrs(localStorage.getItem('id_progress_krs'));
            }, 1000);
        }


    }



}
</script>