<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar "
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="sticky-top bg-white">
                <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                    Jadwal Ujian
                </div>
                <div class="row mt-2 mb-2 me-0  bg-white">
                    <div class="col-2 text-end ">
                        <button v-if="create_akses == true"
                            class="btn bg-border-green-custom  fs-8 py-1 font-green-custom font-custom-4 d-flex justify-content-center align-items-center w-100 position-relative"
                            style="border-radius: 5px;" @click="showModal2('modal_pengajuan'); pengajuan_index()">
                            <i class='bx bxs-file  font-green-custom me-2'></i>
                            Pengajuan Susulan

                        </button>
                    </div>
                    <div class="col-2 text-end ">
                        <button v-if="create_akses == true"
                            class="btn bg-blue-custom fs-8 py-1 text-light font-custom-4 d-flex justify-content-center align-items-center w-100"
                            style="border-radius: 5px;" @click="showModal2('modal_pengaturan'); pengaturan_index();">
                            <i class='bx bxs-cog me-2'></i>
                            Ujian Susulan
                        </button>
                    </div>
                    <div class="col-8 text-end ">
                        <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 me-1"
                            style="border-radius: 5px;" data-bs-toggle="modal"
                            data-bs-target="#modal_finalisasi_jadwal_ujian"
                            @click="showModal('modal_finalisasi_jadwal_ujian'); newRecord.jam_mulai = HourNow(); newRecord.jam_selesai = HourNow()">
                            <i class='bx bx-list-check'></i>
                            Finalisasi Jadwal Ujian
                        </button>
                        <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 me-1"
                            style="border-radius: 5px;" data-bs-toggle="modal"
                            data-bs-target="#modal_export_jadwal_ujian"
                            @click="showModal('modal_export_jadwal_ujian'); newRecord.jam_mulai = HourNow(); newRecord.jam_selesai = HourNow()">
                            <i class='bx bx-export'></i>
                            Eksport Jadwal Ujian
                        </button>
                        <button v-if="create_akses == true"
                            class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            data-bs-toggle="modal" data-bs-target="#modal_tambah"
                            @click="showModal('modal_tambah'); newRecord.jam_mulai = HourNow(); newRecord.jam_selesai = HourNow()">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah Jadwal Ujian
                        </button>
                    </div>
                </div>

                <div class="row mt-2 mb-2 me-0  bg-white">
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                            id="tahuna_ajaran" v-model="tahunAjaranFilter"
                            @change="pageActive = 1; offset = 0; index();">
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for=" list in prodiList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                            v-model="kurikulumFilter" @change="pageActive = 1; offset = 0; index();" required
                            :disabled="prodiFilter == ''">
                            <option value="" selected disabled>- Pilih Kurikulum
                            </option>
                            <option v-for="list in kurikulumList" :key="list.id" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="kurikulumFilter == ''">
                            <option value="" selected>- Pilih Semester</option>
                            <option v-for=" list in semesterList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="hariFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="semesterFilter == ''">
                            <option value="" selected>- Pilih Hari</option>
                            <option v-for=" list  in  hariJadwalList " :key="list.nama" :value="list.nama">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div> -->
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="tipeFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="semesterFilter == ''">
                            <option value="" selected disabled>- Pilih Tipe</option>
                            <!-- <option value="semua">Semua</option> -->
                            <option value="uts">UTS</option>
                            <option value="uas">UAS</option>
                            <option value="susulan_uts">Susulan (UTS)</option>
                            <option value="susulan_uas">Susulan (UAS)</option>
                        </select>
                    </div>
                    <div class="d-flex align-items-start justify-content-start mt-2 gap-2">
                        <div>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="limit" @change="pageActive = 1; offset = 0; index()" required>
                                <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                    @change=index()>
                                    {{ list.value }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center  align-items-center">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination" ref="pagi">
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != 1) { pageActive--; offset = offset - limit; index('previous') };"
                                            aria-label="Previous" style="cursor:pointer;">
                                            <span aria-hidden="true">&laquo;</span>
                                        </span>
                                    </li>
                                    <li v-for=" list in paginglist " :key="list.nama" :value="list.value"
                                        class="page-item"
                                        :class="{ 'active': list.nama == pageActive, 'd-none': list.show == false }">
                                        <span class="page-link text-black "
                                            @click="if (list.disabled != true) pageActive = list.nama; offset = list.value; index()"
                                            style="cursor: pointer;">{{
                                                list.nama }}</span>
                                    </li>
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != totalPages) { pageActive++; offset = offset + limit; index('next') };"
                                            aria-label="Next" style="cursor:pointer;">
                                            <span aria-hidden="true">&raquo;</span>
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="flex-fill">
                            <input type="text" v-model="query" @change="pageActive = 1; offset = 0; index()"
                                class="form-control"
                                placeholder="Cari dosen pengajar, mata kuliah, kelas, status input ">
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column gap-2  ">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <div class="border  rounded-3 me-2 font-custom-1 mb-5 "
                    style="overflow-x:scroll; z-index: 0;  overflow-y: scroll; max-height: 59vh!important; ">
                    <table class="table mb-0 bg-transparent table-borderless" style="">
                        <thead class="sticky-top " style="top: 0px;">
                            <tr>
                                <th scope="col" class="fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipeFilter != 'uts' && tipeFilter != 'uas'">Tanggal</th>
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipeFilter != 'susulan_uts' && tipeFilter != 'susulan_uas'">Kelas</th>
                                <th scope="col" class="font-custom-1 fs-7">Kurikulum</th>
                                <th scope="col" class="font-custom-1 fs-7">Mata kuliah</th>
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipeFilter != 'uts' && tipeFilter != 'uas'">Jam</th>
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipeFilter != 'uts' && tipeFilter != 'uas'">Ruangan</th>
                                <th scope="col" class="font-custom-1 fs-7">Tipe </th>
                                <!-- <th scope="col" class="font-custom-1 fs-7">Model Ujian </th> -->
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipeFilter == 'uts' || tipeFilter == 'uas'">Jenis Ujian</th>
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipeFilter != 'uts' && tipeFilter != 'uas'">Dosen Pengawas </th>
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipeFilter != 'uts' && tipeFilter != 'uas'">Dosen Pengawas 2</th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran</th>
                                <th scope="col" class="font-custom-1 fs-7">Status</th>
                                <th scope="col" class="font-custom-1 fs-7">Status Input </th>
                                <th scope="col" class=" fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="( record, index ) in records " :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <!-- <td>{{ record.hari }}</td> -->
                                <td v-if="tipeFilter != 'uts' && tipeFilter != 'uas'">{{ DateFormat(record.tanggal) }}
                                </td>
                                <td v-if="tipeFilter != 'susulan_uts' && tipeFilter != 'susulan_uas'">{{ record.kelas }}
                                </td>
                                <td>{{ record.kurikulum }}</td>
                                <td>{{ record.mata_kuliah }}</td>
                                <td v-if="tipeFilter != 'uts' && tipeFilter != 'uas'">{{ record.jam_mulai }} - <br>{{
                                    record.jam_selesai }}</td>
                                <td v-if="tipeFilter != 'uts' && tipeFilter != 'uas'">{{ record.ruangan }}</td>
                                <td>
                                    <span v-if="record.tipe == 'uts'">
                                        UTS
                                    </span>
                                    <span v-if="record.tipe == 'uas'">
                                        UAS
                                    </span>

                                    <span v-if="record.tipe == 'susulan_uts'">
                                        Susulan (UTS)
                                    </span>
                                    <span v-if="record.tipe == 'susulan_uas'">
                                        Susulan (UAS)
                                    </span>
                                </td>
                                <!-- <td v-if="tipeFilter == 'uts' || tipeFilter == 'uas'">{{ record.jenis_ujian }}</td> -->
                                <td v-if="tipeFilter == 'uts' || tipeFilter == 'uas'">{{ record.tipe_ujian }}</td>
                                <td v-if="tipeFilter != 'uts' && tipeFilter != 'uas'">{{ record.dosen_pengawas }}</td>
                                <td v-if="tipeFilter != 'uts' && tipeFilter != 'uas'">{{ record.dosen_pengawas_2 }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.status_finalisasi }}</td>
                                <td>{{ record.status_penginputan }}</td>

                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button
                                            v-if="update_akses == true && (record.tipe == 'uts' || record.tipe == 'uas')"
                                            class="btn btn-primary btn-sm fs-8"
                                            @click=" editRecordPesertaUjian(record); peserta_ujian_index(); showModal2('modal_list_peserta_ujian');"><i
                                                class='bx bxs-user-rectangle fs-8'></i></button>
                                        <button
                                            v-if="update_akses == true && (record.tipe == 'susulan_uts' || record.tipe == 'susulan_uas')"
                                            class="btn btn-primary btn-sm fs-8"
                                            @click=" editRecordPeserta(record); peserta_index(); showModal2('modal_list_peserta');"><i
                                                class='bx bxs-user-rectangle fs-8'></i></button>
                                        <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            @click="editRecord(record);" data-bs-toggle="modal"
                                            data-bs-target="#modal_edit"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            data-bs-toggle="modal" data-bs-target="#modal_delete"
                                            v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <form @submit.prevent="export_jadwal_ujian()">
        <div class="modal fade" id="modal_export_jadwal_ujian" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_export_jadwal_ujian">
            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Export data Jadwal Ujian</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_export_jadwal_ujian"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_export_jadwal_ujian', 'close_modal_export_jadwal_ujian')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Tipe </label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="newRecord.tipe" required>
                                <option value="" selected disabled>- Pilih Tipe Ujian</option>
                                <option value="uts">UTS</option>
                                <option value="uas">UAS</option>
                                <option value="susulan_uts">Susulan (UTS)</option>
                                <option value="susulan_uas">Susulan (UAS)</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="tahunAjaranFilter"
                                @change="pageActive = 1; offset = 0; index();">
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required
                                :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih Prodi
                                </option>
                                <option v-for="list in prodiList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="kurikulum" class="form-label">Kurikulum</label>
                            <select class="form-select" aria-label="Default select example" name="kurikulum"
                                id="kurikulum" v-model="kurikulumFilter" @change="pageActive = 1; offset = 0; index();"
                                required :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih Kurikulum
                                </option>
                                <option v-for="list in kurikulumList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 ">
                            <label for="semester" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()"
                                required :disabled="kurikulumFilter == ''">
                                <option value="" selected>- Pilih Semester</option>
                                <option v-for=" list in semesterList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_export_jadwal_ujian', 'close_modal_export_jadwal_ujian')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Eksport</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form @submit.prevent="insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_tambah">
            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Data Jadwal Ujian</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_tambah', 'close_modal_tambah')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Tipe </label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="newRecord.tipe" @change="tipeFilter = newRecord.tipe; index();" required>
                                <option value="" selected disabled>- Pilih Tipe Ujian</option>
                                <option value="uts">UTS</option>
                                <option value="uas">UAS</option>
                                <option value="susulan_uts">Susulan (UTS)</option>
                                <option value="susulan_uas">Susulan (UAS)</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="tahunAjaranFilter"
                                @change="pageActive = 1; offset = 0; index();">
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required
                                :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih Prodi
                                </option>
                                <option v-for="list in prodiList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="kurikulum" class="form-label">Kurikulum</label>
                            <select class="form-select" aria-label="Default select example" name="kurikulum"
                                id="kurikulum" v-model="kurikulumFilter" @change="pageActive = 1; offset = 0; index();"
                                required :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih Kurikulum
                                </option>
                                <option v-for="list in kurikulumList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="newRecord.tipe == 'uts' || newRecord.tipe == 'uas'">
                            <label for="dosen" class="form-label">Kelas</label>
                            <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                                v-model="newRecord.kelas" :disabled="prodiFilter == ''" required>
                                <option value="" selected disabled>- Pilih Kelas</option>
                                <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                    {{ list.nama }} ({{ list.waktu_kuliah }})
                                </option>
                            </select>
                        </div>
                        <!-- <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" @change="index(newRecord.prodi, newRecord.tahun_ajaran)"
                                v-model="newRecord.tahun_ajaran" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.id" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div> -->

                        <!-- <div class="mb-3">
                            <label for="dosen" class="form-label">Pilih Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                                v-model="newRecord.semester"
                                @change="index(newRecord.prodi, newRecord.tahun_ajaran, newRecord.kurikulum, newRecord.semester)"
                                :disabled="newRecord.kurikulum == ''" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for="list in semesterList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="semesterFilter" @change="pageActive = 1; offset = 0; index();"
                                :disabled="prodiFilter == ''" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>


                        <div class="mb-3">
                            <label for="dosen" class="form-label">mata Kuliah </label>
                            <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="newRecord.mata_kuliah" required>
                                <option value="" selected disabled>- Pilih Mata Kuliah</option>
                                <option v-for="list in mataKuliahList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3" v-if="newRecord.tipe == 'uts' || newRecord.tipe == 'uas'">
                            <label for="dosen" class="form-label">Jenis </label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="newRecord.tipe_ujian" required :disabled="newRecord.tipe == ''">
                                <option value="" selected disabled>- Pilih Jenis Ujian</option>
                                <option value="teori">Teori</option>
                                <option value="praktek">Praktek</option>
                            </select>
                        </div>
                        <!-- <div class="mb-3" v-if="newRecord.tipe == 'uts' || newRecord.tipe == 'uas'">
                            <label for="dosen" class="form-label">Model Ujian</label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="newRecord.jenis_ujian" required :disabled="newRecord.tipe == ''">
                                <option value="" selected disabled>- Pilih Model Ujian</option>
                                <option value="PROJECT BASED">PROJECT BASED</option>
                                <option value="ACADEMIC BASED">ACADEMIC BASED</option>
                            </select>
                        </div> -->
                        <!-- <div class="mb-3">
                            <label for="hari" class="form-label">Hari</label>
                            <select class="form-select" aria-label="Default select example" name="hari" id="hari"
                                v-model="newRecord.hari" required>
                                <option value="" selected disabled>- Pilih hari</option>
                                <option v-for="list in hariList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                        <div class="mb-3" v-if="newRecord.tipe == 'susulan_uts' || newRecord.tipe == 'susulan_uas'">
                            <label for="tanggal" class="form-label">Tanggal </label>
                            <input type="date" class="form-control" v-model="newRecord.tanggal" required />
                        </div>
                        <div class="mb-3" v-if="newRecord.tipe == 'susulan_uts' || newRecord.tipe == 'susulan_uas'">
                            <label for="dosen" class="form-label">Jam Mulai</label>
                            <input type="time" class="form-control" v-model="newRecord.jam_mulai" placeholder="09:00"
                                required />
                        </div>
                        <div class="mb-3" v-if="newRecord.tipe == 'susulan_uts' || newRecord.tipe == 'susulan_uas'">
                            <label for="dosen" class="form-label">Jam Selesai</label>
                            <input type="time" class="form-control" v-model="newRecord.jam_selesai" placeholder="09:00"
                                required />
                        </div>
                        <div class="mb-3" v-if="newRecord.tipe == 'susulan_uts' || newRecord.tipe == 'susulan_uas'">
                            <label for="dosen" class="form-label">Ruangan</label>
                            <select class="form-select" aria-label="Default select example" name="ruangan" id="ruangan"
                                v-model="newRecord.ruangan" required>
                                <option value="" selected disabled>- Pilih Ruangan</option>
                                <option v-for="list in ruanganList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="newRecord.tipe == 'susulan_uts' || newRecord.tipe == 'susulan_uas'">
                            <label for="dosen" class="form-label">Dosen Pengawas</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="newRecord.dosen_pengawas" required>
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="newRecord.tipe == 'susulan_uts' || newRecord.tipe == 'susulan_uas'">
                            <label for="dosen" class="form-label">Dosen Pengawas 2</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="newRecord.dosen_pengawas_2">
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>


                        <div class="mb-3" v-if="newRecord.tipe == 'uts' || newRecord.tipe == 'uas'">
                            <label for="dosen" class="form-label">Berapa Sesi</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="newRecord.jumlah_sesi" required @change="push_list_jadwal('insert')">
                                <option value="" selected disabled>- Pilih Jumlah sesi</option>
                                <option value="1" selected>1</option>
                                <option value="2" selected>2</option>
                                <option value="3" selected>3</option>
                            </select>
                        </div>
                        <div v-if="newRecord.jumlah_sesi != '' && (newRecord.tipe == 'uts' || newRecord.tipe == 'uas')">
                            <div class="border py-2 px-2 rounded " v-for="( record, index ) in list_jadwal"
                                :key="record.id" :ref="record.id">
                                <p class="fs-6 ">Sesi {{ index + 1 }}</p>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Tanggal</label>
                                    <input type="date" class="form-control" v-model="record.tanggal" required />
                                </div>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Jam Mulai</label>
                                    <input type="time" class="form-control" v-model="record.jam_mulai"
                                        placeholder="09:00" required />
                                </div>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Jam Selesai</label>
                                    <input type="time" class="form-control" v-model="record.jam_selesai"
                                        placeholder="09:00" required />
                                </div>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Ruangan</label>
                                    <select class="form-select" aria-label="Default select example" name="ruangan"
                                        id="ruangan" v-model="record.ruangan" required>
                                        <option value="" selected disabled>- Pilih Ruangan</option>
                                        <option v-for="list in ruanganList" :key="list.nama" :value="list.id">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Dosen Pengawas</label>
                                    <select class="form-select" aria-label="Default select example" name="dosen"
                                        id="dosen" v-model="record.dosen_pengawas" required>
                                        <option value="" selected disabled>- Pilih Dosen</option>
                                        <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Dosen Pengawas 2</label>
                                    <select class="form-select" aria-label="Default select example" name="dosen"
                                        id="dosen" v-model="record.dosen_pengawas_2">
                                        <option value="" selected disabled>- Pilih Dosen</option>
                                        <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_tambah', 'close_modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data Jadwal Ujian</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_edit', 'close_modal_edit')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Tipe </label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="currentRecord.tipe" @change="tipeFilter = currentRecord.tipe; index();"
                                required>
                                <option value="" selected disabled>- Pilih Tipe Ujian</option>
                                <option value="uts">UTS</option>
                                <option value="uas">UAS</option>
                                <option value="susulan_uts">Susulan (UTS)</option>
                                <option value="susulan_uas">Susulan (UAS)</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="currentRecord.id_tahun_ajaran"
                                @change="pageActive = 1; offset = 0; index();">
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="currentRecord.id_prodi" @change="pageActive = 1; offset = 0; index();" required
                                :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih Prodi
                                </option>
                                <option v-for="list in prodiList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="kurikulum" class="form-label">Kurikulum</label>
                            <select class="form-select" aria-label="Default select example" name="kurikulum"
                                id="kurikulum" v-model="currentRecord.id_kurikulum"
                                @change="pageActive = 1; offset = 0; index();" required
                                :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih Kurikulum
                                </option>
                                <option v-for="list in kurikulumList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="currentRecord.tipe == 'uts' || currentRecord.tipe == 'uas'">
                            <label for="dosen" class="form-label">Kelas</label>
                            <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                                v-model="currentRecord.id_kelas" :disabled="prodiFilter == ''" required>
                                <option value="" selected disabled>- Pilih kelas</option>
                                <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                    {{ list.nama }} ({{ list.waktu_kuliah }})
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="dosen" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.id_semester" @change="pageActive = 1; offset = 0; index();"
                                :disabled="prodiFilter == ''" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="dosen" class="form-label">Mata Kuliah </label>
                            <select class="form-select" aria-label="Default select example" name="mata_kuliah"
                                id="mata_kuliah" v-model="currentRecord.id_mata_kuliah" required>
                                <option value="" selected disabled>- Pilih Mata Kuliah</option>
                                <option v-for="list in mataKuliahList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="currentRecord.tipe == 'uts' || currentRecord.tipe == 'uas'">
                            <label for="dosen" class="form-label">Jenis </label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="currentRecord.tipe_ujian" required>
                                <option value="" selected disabled>- Pilih Jenis Ujian</option>
                                <option value="teori">Teori</option>
                                <option value="praktek">Praktek</option>
                            </select>
                        </div>
                        <!-- <div class="mb-3" v-if="currentRecord.tipe == 'uts' || currentRecord.tipe == 'uas'">
                            <label for="dosen" class="form-label">Model Ujian</label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="currentRecord.jenis_ujian" required :disabled="currentRecord.tipe == ''">
                                <option value="" selected disabled>- Pilih Model Ujian</option>
                                <option value="PROJECT BASED">PROJECT BASED</option>
                                <option value="ACADEMIC BASED">ACADEMIC BASED</option>
                            </select>
                        </div> -->
                        <div class="mb-3"
                            v-if="currentRecord.tipe == 'susulan_uts' || currentRecord.tipe == 'susulan_uas'">
                            <label for="tanggal" class="form-label">Tanggal </label>
                            <input type="date" class="form-control" v-model="currentRecord.tanggal" required />
                        </div>
                        <div class="mb-3"
                            v-if="currentRecord.tipe == 'susulan_uts' || currentRecord.tipe == 'susulan_uas'">
                            <label for="dosen" class="form-label">Jam Mulai</label>
                            <input type="time" class="form-control" v-model="currentRecord.jam_mulai"
                                placeholder="09:00" required />
                        </div>
                        <div class="mb-3"
                            v-if="currentRecord.tipe == 'susulan_uts' || currentRecord.tipe == 'susulan_uas'">
                            <label for="dosen" class="form-label">Jam Selesai</label>
                            <input type="time" class="form-control" v-model="currentRecord.jam_selesai"
                                placeholder="09:00" required />
                        </div>
                        <div class="mb-3"
                            v-if="currentRecord.tipe == 'susulan_uts' || currentRecord.tipe == 'susulan_uas'">
                            <label for="dosen" class="form-label">Ruangan</label>
                            <select class="form-select" aria-label="Default select example" name="ruangan" id="ruangan"
                                v-model="currentRecord.id_ruangan" required>
                                <option value="" selected disabled>- Pilih Ruangan</option>
                                <option v-for="list in ruanganList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3"
                            v-if="currentRecord.tipe == 'susulan_uts' || currentRecord.tipe == 'susulan_uas'">
                            <label for="dosen" class="form-label">Dosen Pengawas</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.id_dosen_pengawas" required>
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3"
                            v-if="currentRecord.tipe == 'susulan_uts' || currentRecord.tipe == 'susulan_uas'">
                            <label for="dosen" class="form-label">Dosen Pengawas 2</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.id_dosen_pengawas_2">
                                <option value="" selected disabled>- Pilih Dosen</option>
                                <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3" v-if="currentRecord.tipe == 'uts' || currentRecord.tipe == 'uas'">
                            <label for="dosen" class="form-label">Berapa Sesi</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="currentRecord.jumlah_sesi" @change="push_list_jadwal('update')">
                                <option value="" selected disabled>- Pilih Jumlah sesi</option>
                                <option value="1" selected>1</option>
                                <option value="2" selected>2</option>
                                <option value="3" selected>3</option>
                            </select>
                        </div>
                        <div
                            v-if="currentRecord.jumlah_sesi != '' && (currentRecord.tipe == 'uts' || currentRecord.tipe == 'uas')">
                            <div class="border py-2 px-2 rounded mb-2" v-for="( record, index ) in list_jadwal_update"
                                :key="record.id" :ref="record.id">
                                <p class="fs-6 ">Sesi {{ index + 1 }}</p>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Tanggal</label>
                                    <input type="date" class="form-control" v-model="record.tanggal" required />
                                </div>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Jam Mulai</label>
                                    <input type="time" class="form-control" v-model="record.jam_mulai"
                                        placeholder="09:00" required />
                                </div>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Jam Selesai</label>
                                    <input type="time" class="form-control" v-model="record.jam_selesai"
                                        placeholder="09:00" required />
                                </div>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Ruangan</label>
                                    <select class="form-select" aria-label="Default select example" name="ruangan"
                                        id="ruangan" v-model="record.ruangan" required>
                                        <option value="" selected disabled>- Pilih Ruangan</option>
                                        <option v-for="list in ruanganList" :key="list.nama" :value="list.id">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Dosen Pengawas</label>
                                    <select class="form-select" aria-label="Default select example" name="dosen"
                                        id="dosen" v-model="record.dosen_pengawas" required>
                                        <option value="" selected disabled>- Pilih Dosen</option>
                                        <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="dosen" class="form-label">Dosen Pengawas 2</label>
                                    <select class="form-select" aria-label="Default select example" name="dosen"
                                        id="dosen" v-model="record.dosen_pengawas_2">
                                        <option value="" selected disabled>- Pilih Dosen</option>
                                        <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form @submit.prevent="finalisasiJadwalUjian">
        <div class="modal fade" id="modal_finalisasi_jadwal_ujian" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_finalisasi_jadwal_ujian">
            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Finalisasi Jadwal Ujian</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_finalisasi_jadwal_ujian"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_finalisasi_jadwal_ujian', 'close_modal_finalisasi_jadwal_ujian')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="dosen" class="form-label">Tipe </label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="newRecord.tipe" required>
                                <option value="" selected disabled>- Pilih Tipe Ujian</option>
                                <option value="uts">UTS</option>
                                <option value="uas">UAS</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="tahunAjaranFilter"
                                @change="pageActive = 1; offset = 0; index();">
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <!-- <div class="mb-3">
                            <label for="kurikulum" class="form-label">Kurikulum</label>
                            <select class="form-select" aria-label="Default select example" name="kurikulum" id="kurikulum"
                                v-model="kurikulumFilter" @change="pageActive = 1; offset = 0; index();" required
                                :disabled="tahunAjaranFilter == ''">
                                <option value="" selected disabled>- Pilih Kurikulum
                                </option>
                                <option v-for="list in kurikulumList" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->

                        <div class="mb-3">
                            <label for="dosen" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                                v-model="semesterFilter" @change="pageActive = 1; offset = 0; index();"
                                :disabled="tahunAjaranFilter == ''" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_finalisasi_jadwal_ujian', 'close_modal_finalisasi_jadwal_ujian')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>
                </div>
            </div>
        </div>
    </form>



    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_pengajuan" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_pengajuan">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Daftar mahasiswa pengajuan ujian susulan
                        <span v-if="total_sudah_bayar_belum_dapat_jadwal_ujian_susulan > 0">({{
                            total_sudah_bayar_belum_dapat_jadwal_ujian_susulan }}
                            Mahasiswa sudah bayar namun belum dapat jadwal)</span>
                    </h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_list_berita_acara"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_pengajuan')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="tahunAjaranFilterPengajuan"
                                @change="pageActive = 1; offset = 0; pengajuan_index();">
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranListPengajuan " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="prodiFilterPengajuan" @change="pageActive = 1; offset = 0; pengajuan_index()"
                                required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for=" list in prodiListPengajuan " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilterPengajuan"
                                @change="pageActive = 1; offset = 0; pengajuan_index()" required
                                :disabled="prodiFilterPengajuan == ''">
                                <option value="" selected>- Pilih Semester</option>
                                <option v-for=" list in semesterListPengajuan " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="kurikulum"
                                id="kurikulum" v-model="kurikulumFilterPengajuan"
                                @change="pageActive = 1; offset = 0; pengajuan_index()" required
                                :disabled="prodiFilterPengajuan == ''">
                                <option value="" selected disabled>- Pilih Kurikulum
                                </option>
                                <option v-for="list in kurikulumListPengajuan" :key="list.id" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="MataKuliahFilterPengajuan"
                                @change="pageActive = 1; offset = 0; pengajuan_index()" required
                                :disabled="semesterFilterPengajuan == ''">
                                <option value="" selected>- Pilih Mata Kuliah</option>
                                <option v-for=" list in mataKuliahListPengajuan " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="tipeFilterPengajuan"
                                @change="pageActive = 1; offset = 0; pengajuan_index()" required
                                :disabled="semesterFilterPengajuan == ''">
                                <option value="" selected disabled>- Pilih Tipe</option>
                                <option value="semua">Semua</option>
                                <!-- <option value="uts">UTS</option>
                                <option value="uas">UAS</option> -->
                                <option value="susulan_uts">Susulan (UTS)</option>
                                <option value="susulan_uas">Susulan (UAS)</option>
                            </select>
                        </div>
                    </div>
                    <div class="">
                        <div class="d-flex align-items-start justify-content-start mt-2 gap-2">
                            <div>
                                <select class="form-select" aria-label="Default select example" name="semester"
                                    id="semester" v-model="limitPengajuan"
                                    @change="pageActivePengajuan = 1; offsetPengajuan = 0; index()" required>
                                    <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                        @change=index()>
                                        {{ list.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex justify-content-center  align-items-center">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination" ref="pagi">
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActivePengajuan != 1) { pageActivePengajuan--; offsetPengajuan = offsetPengajuan - limitPengajuan; pengajuan_index('previous') };"
                                                aria-label="Previous" style="cursor:pointer;">
                                                <span aria-hidden="true">&laquo;</span>
                                            </span>
                                        </li>
                                        <li v-for=" list in paginglistPengajuan " :key="list.nama" :value="list.value"
                                            class="page-item"
                                            :class="{ 'active': list.nama == pageActivePengajuan, 'd-none': list.show == false }">
                                            <span class="page-link text-black "
                                                @click="if (list.disabled != true) pageActivePengajuan = list.nama; offsetPengajuan = list.value; pengajuan_index()"
                                                style="cursor: pointer;">{{
                                                    list.nama }}</span>
                                        </li>
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActivePengajuan != totalPagesPengajuan) { pageActivePengajuan++; offsetPengajuan = offsetPengajuan + limitPengajuan; pengajuan_index('next') };"
                                                aria-label="Next" style="cursor:pointer;">
                                                <span aria-hidden="true">&raquo;</span>
                                            </span>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="w-25">
                                <input type="text" v-model="queryPengajuan"
                                    @change="pageActivePengajuan = 1; offsetPengajuan = 0; pengajuan_index()"
                                    class="form-control" placeholder="Cari nim, nama_lengkap, nama_kuliah">
                            </div>
                        </div>
                    </div>

                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Tanggal</th>
                                    <th>NIM </th>
                                    <th>Waktu Kuliah</th>
                                    <th>Nama lengkap</th>
                                    <th>Mata kuliah</th>
                                    <th>Dosen Pengajar</th>
                                    <th>Kelas</th>
                                    <!-- <th>Persetujuan</th> -->
                                    <th>Tipe</th>
                                    <!-- <th>Jenis Ujian</th> -->
                                    <th>Status</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in recordsPengajuan " :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ DateFormat(record.tanggal) }}</td>
                                    <td>{{ record.nim_mahasiswa }}</td>
                                    <td>{{ record.waktu_kuliah }}</td>
                                    <td>{{ record.nama_lengkap }}</td>
                                    <td>{{ record.mata_kuliah }}</td>
                                    <td>{{ record.dosen_pengajar }}</td>
                                    <td>{{ record.nama_kelas }}</td>
                                    <!-- <td>{{ record.status_terima }}</td> -->
                                    <td>
                                        <span v-if="record.tipe == 'susulan_uts'">
                                            Susulan (UTS)
                                        </span>
                                        <span v-if="record.tipe == 'susulan_uas'">
                                            Susulan (UAS)
                                        </span>
                                    </td>
                                    <!-- <td>{{ record.tipe_ujian }}</td> -->
                                    <td>{{ record.status_pengajuan }}</td>
                                    <td class="bg-transparent">
                                        <div class="d-flex gap-2">
                                            <button v-if="(update_akses == true) || tipe == 'admin'"
                                                class="btn btn-primary btn-sm fs-8"
                                                @click="editRecordPengajuan(record); showModal2('modal_terima');"><i
                                                    class='bx bxs-show fs-8'></i></button>
                                            <button v-if="delete_akses == true || tipe == 'admin'"
                                                class="btn btn-danger btn-sm fs-8"
                                                v-on:click="deleteRecord(record.id); showModal2('modal_delete_pengajuan')"><i
                                                    class='bx bxs-trash-alt fs-8'></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_pengajuan')">Tutup</button>
                </div>
            </div>

        </div>
    </div>

    <form @submit.prevent="updatePengajuan">
        <div class="modal fade" id="modal_terima" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_terima">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Lihat Pengajuan</h5>

                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_terima')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                            <table class="table mb-0 bg-transparent table-borderless" style="">
                                <!-- <thead>
                                    <tr>
                                        <th>Nama</th>
                                    </tr>
                                </thead> -->
                                <tbody>
                                    <tr class="border-bottom ">
                                        <td class="fw-bold">NIM</td>
                                        <td>{{ currentRecordPengajuan.nim_mahasiswa }}</td>
                                    </tr>
                                    <tr class="border-bottom ">
                                        <td class="fw-bold">Nama</td>
                                        <td>{{ currentRecordPengajuan.nama_lengkap }}</td>
                                    </tr>
                                    <tr class="border-bottom ">
                                        <td class="fw-bold">Mata Kuliah</td>
                                        <td>{{ currentRecordPengajuan.mata_kuliah }}</td>
                                    </tr>
                                    <tr class="border-bottom ">
                                        <td class="fw-bold">Jenis Ujian</td>
                                        <td>{{ currentRecordPengajuan.tipe_ujian }}</td>
                                    </tr>
                                    <tr class="border-bottom ">
                                        <td class="fw-bold">Dosen Pengajar</td>
                                        <td>{{ currentRecordPengajuan.dosen_pengajar }}</td>
                                    </tr>
                                    <tr class="border-bottom ">
                                        <td class="fw-bold">Nomor Invoice</td>
                                        <td>{{ currentRecordPengajuan.nomor_invoice }}</td>
                                    </tr>
                                    <tr class="border-bottom ">
                                        <td class="fw-bold">Status Dibayar</td>
                                        <td>{{ currentRecordPengajuan.status_pengajuan }}</td>
                                    </tr>
                                    <tr class="border-bottom ">
                                        <td class="fw-bold">Tanggal</td>
                                        <td>{{ DateFormat(currentRecordPengajuan.tanggal) }}</td>
                                    </tr>
                                    <tr class="border-bottom ">
                                        <td class="fw-bold">Alasan</td>
                                        <td>{{ currentRecordPengajuan.alasan }}</td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_terima')">Tutup</button>
                        <button type="submit" class="btn btn-danger"
                            @click="currentRecordPengajuan.status_terima = 'DITOLAK'">
                            Tolak </button>
                        <button type="submit" @click="currentRecordPengajuan.status_terima = 'DISETUJUI'"
                            class="btn bg-green-custom text-light">
                            Terima </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_delete_pengajuan" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_delete_pengajuan">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_delete_pengajuan')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_delete_pengajuan')">Tidak,
                        tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteDataPengajuan">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_pengaturan" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_pengaturan">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Tanggal pembukaan pembayaran ujian susulan
                    </h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_list_berita_acara"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_pengaturan')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="tahunAjaranFilterPengaturan"
                                @change="pageActive = 1; offset = 0; pengaturan_index();">
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranListPengaturan " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="col-2 text-center">
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilterPengaturan"
                                @change="pageActive = 1; offset = 0; pengaturan_index()" required
                                :disabled="tahunAjaranFilterPengaturan == ''">
                                <option value="" selected>- Pilih Semester</option>
                                <option v-for=" list in semesterListPengaturan " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="col-8 text-end ">
                            <button v-if="create_akses == true"
                                class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;"
                                @click="showModal2('modal_pengaturan_tambah'); newRecordPengaturan.tanggal_mulai = DateNow(); newRecordPengaturan.tanggal_selesai = DateNow()">
                                <img src="@/assets/icon/data_plus.png" alt="" width="15">
                                Tambah Tanggal pengajuan ujian susulan
                            </button>
                        </div>
                    </div>

                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Tahun Ajaran</th>
                                    <th>Semester</th>
                                    <th>Tanggal Mulai</th>
                                    <th>Tanggal Selesai</th>
                                    <th>Biaya</th>
                                    <th>Tipe</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in recordsPengaturan " :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>

                                    <td>{{ record.tahun_ajaran }}</td>
                                    <td>{{ record.semester }}</td>
                                    <td>{{ DateFormat(record.tanggal_mulai) }}</td>
                                    <td>{{ DateFormat(record.tanggal_selesai) }}</td>
                                    <td>{{ rupiah(record.harga) }}</td>
                                    <td>
                                        <span v-if="record.tipe == 'susulan_uts'">
                                            Susulan (UTS)
                                        </span>
                                        <span v-if="record.tipe == 'susulan_uas'">
                                            Susulan (UAS)
                                        </span>
                                    </td>

                                    <td class="bg-transparent">
                                        <div class="d-flex gap-2">
                                            <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                                @click="editRecordPengaturan(record); showModal2('modal_pengaturan_update'); pengaturan_index()"><i
                                                    class='bx bxs-message-square-edit fs-8'></i></button>
                                            <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                                v-on:click="deleteRecord(record.id); showModal2('modal_delete_pengaturan')"><i
                                                    class='bx bxs-trash-alt fs-8'></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_pengaturan')">Tutup</button>
                </div>
            </div>

        </div>
    </div>

    <form @submit.prevent="insertPengaturan">
        <div class="modal fade" id="modal_pengaturan_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_pengaturan_tambah">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Tanggal Pengajuan Ujian Susulan
                        </h5>

                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_pengaturan_tambah')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="tahunAjaranFilterPengaturan"
                                @change="pageActive = 1; offset = 0; pengaturan_index();">
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranListPengaturan " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilterPengaturan"
                                @change="pageActive = 1; offset = 0; pengaturan_index();">
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for=" list in semesterListPengaturan " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tanggal" class="form-label">Tanggal Mulai</label>
                            <input type="date" class="form-control" v-model="newRecordPengaturan.tanggal_mulai"
                                required />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal" class="form-label">Tanggal Selesai</label>
                            <input type="date" class="form-control" v-model="newRecordPengaturan.tanggal_selesai"
                                required />
                        </div>
                        <div class="mb-3">
                            <label for="biaya_kuliah" class="form-label"> Biaya</label>
                            <input type="text" class="form-control"
                                @input="changerupiahInput($event.target.value, 'harga', 'insert')"
                                v-model="newRecordPengaturan.harga" />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal" class="form-label">Tipe</label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="newRecordPengaturan.tipe" required :disabled="semesterFilterPengaturan == ''">
                                <option value="" selected disabled>- Pilih Tipe</option>
                                <!-- <option value="uts">UTS</option>
                                <option value="uas">UAS</option> -->
                                <option value="susulan_uts">Susulan (UTS)</option>
                                <option value="susulan_uas">Susulan (UAS)</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_pengaturan_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form @submit.prevent="updatePengaturan">
        <div class="modal fade" id="modal_pengaturan_update" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_pengaturan_update">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Perbaharui tanggal pengajuan ujian
                            susulan
                        </h5>

                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_pengaturan_update')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="tahunAjaranFilterPengaturan"
                                @change="pageActive = 1; offset = 0; pengaturan_index();">
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranListPengaturan " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tahun_ajaran" class="form-label">Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilterPengaturan"
                                @change="pageActive = 1; offset = 0; pengaturan_index();">
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for=" list in semesterListPengaturan " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tanggal" class="form-label">Tanggal Mulai</label>
                            <input type="date" class="form-control" v-model="currentRecordPengaturan.tanggal_mulai"
                                required />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal" class="form-label">Tanggal Selesai</label>
                            <input type="date" class="form-control" v-model="currentRecordPengaturan.tanggal_selesai"
                                required />
                        </div>
                        <div class="mb-3">
                            <label for="biaya_kuliah" class="form-label"> Biaya</label>
                            <input type="text" class="form-control"
                                @input="changerupiahInput($event.target.value, 'harga', 'update')"
                                v-model="currentRecordPengaturan.harga" />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal" class="form-label">Tipe</label>
                            <select class="form-select" aria-label="Default select example" name="tipe" id="tipe"
                                v-model="currentRecordPengaturan.tipe" required
                                :disabled="semesterFilterPengaturan == ''">
                                <option value="" selected disabled>- Pilih Tipe</option>
                                <!-- <option value="uts">UTS</option>
                                <option value="uas">UAS</option> -->
                                <option value="susulan_uts">Susulan (UTS)</option>
                                <option value="susulan_uas">Susulan (UAS)</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_pengaturan_update')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_delete_pengaturan" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_delete_pengaturan">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_delete_pengaturan')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_delete_pengaturan')">Tidak,
                        tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteDataPengaturan">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_list_peserta" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_list_peserta">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Daftar Mahasiswa pengajuan ujian susulan -
                        {{
                            currentRecordPeserta.mata_kuliah }} - {{
                            currentRecordPeserta.tahun_ajaran }} </h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_list_berita_acara"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_list_peserta')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-6 pe-1">
                            <div>* Daftar peserta yang belum mendapatkan jadwal ujian susulan</div>
                            <div class="border mt-1 rounded-3  font-custom-1 mb-5 "
                                style="overflow-x:auto; z-index: 0;">
                                <table class="table mb-0 bg-transparent table-borderless" style="">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <!-- <th>Tanggal</th> -->
                                            <th>NIM </th>
                                            <th>Nama lengkap</th>
                                            <th>Waktu kuliah</th>
                                            <th>Mata kuliah</th>
                                            <th>Tipe</th>
                                            <!-- <th>Jenis Ujian</th> -->
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="( record, index ) in recordsMahasiswa " :key="record.id"
                                            :ref="record.id" class="border-bottom ">
                                            <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                            <!-- <td>{{ DateFormat(record.tanggal) }}</td> -->
                                            <td>{{ record.nim_mahasiswa }}</td>
                                            <td>{{ record.nama_lengkap }}</td>
                                            <td>{{ record.waktu_kuliah }}</td>
                                            <td>{{ record.mata_kuliah }}</td>
                                            <td>
                                                <span v-if="record.tipe == 'uts'">
                                                    UTS
                                                </span>
                                                <span v-if="record.tipe == 'uas'">
                                                    UAS
                                                </span>
                                                <span v-if="record.tipe == 'susulan_uts'">
                                                    Susulan (UTS)
                                                </span>
                                                <span v-if="record.tipe == 'susulan_uas'">
                                                    Susulan (UAS)
                                                </span>
                                            </td>
                                            <!-- <td>{{ record.tipe_ujian }}</td> -->
                                            <td class="bg-transparent">
                                                <div class="d-flex gap-2">
                                                    <button v-if="update_akses == true"
                                                        class="btn btn-warning btn-sm fs-8"
                                                        @click="insertPeserta(record)"><i
                                                            class='bx bx-plus fs-8'></i></button>
                                                    <!-- <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"><i
                                                        class='bx bxs-trash-alt fs-8'></i></button> -->
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-6 ps-1">
                            <div>* Daftar peserta yang sudah mendapatkan jadwal ujian susulan</div>
                            <div class="border mt-1 rounded-3  font-custom-1 mb-5 "
                                style="overflow-x:auto; z-index: 0;">
                                <table class="table mb-0 bg-transparent table-borderless" style="">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <!-- <th>Tanggal</th> -->
                                            <th>NIM </th>
                                            <th>Nama lengkap</th>
                                            <th>Waktu kuliah</th>
                                            <th>Mata kuliah</th>
                                            <th>Tipe</th>
                                            <!-- <th>Jenis Ujian</th> -->
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="( record, index ) in recordsPeserta " :key="record.id"
                                            :ref="record.id" class="border-bottom ">
                                            <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                            <!-- <td>{{ DateFormat(record.tanggal) }}</td> -->
                                            <td>{{ record.nim_mahasiswa }}</td>
                                            <td>{{ record.nama_lengkap }}</td>
                                            <td>{{ record.waktu_kuliah }}</td>
                                            <td>{{ record.mata_kuliah }}</td>
                                            <td> <span v-if="record.tipe == 'uts'">
                                                    UTS
                                                </span>
                                                <span v-if="record.tipe == 'uas'">
                                                    UAS
                                                </span>
                                                <span v-if="record.tipe == 'susulan_uts'">
                                                    Susulan (UTS)
                                                </span>
                                                <span v-if="record.tipe == 'susulan_uas'">
                                                    Susulan (UAS)
                                                </span>
                                            </td>
                                            <!-- <td>{{ record.tipe_ujian }}</td> -->
                                            <td class="bg-transparent">
                                                <div class="d-flex gap-2">
                                                    <!-- <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"><i
                                                        class='bx bx-plus fs-8'></i></button> -->
                                                    <button v-if="delete_akses == true"
                                                        class="btn btn-danger btn-sm fs-8"
                                                        @click="deleteRecord(record.id); showModal2('modal_delete_peserta')"><i
                                                            class='bx bxs-trash-alt fs-8'></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_list_peserta')">Tutup</button>
                </div>
            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_delete_peserta" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_delete_peserta">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_delete_peserta')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_delete_peserta')">Tidak,
                        tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteDataPeserta">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_list_peserta_ujian" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_list_peserta_ujian">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Daftar Mahasiswa </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_list_peserta_ujian')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3 d-flex gap-2 align-items-center">

                        <div class="">
                            <input type="text" v-model="queryPesertaUjian"
                                @change="pageActivePesertaUjian = 1; offsetPesertaUjian = 0; peserta_ujian_index();"
                                class="form-control" placeholder="Cari NIM, nama_lengkap ">
                        </div>
                        <div>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahuna_ajaran" v-model="sesiFilter"
                                @change="pageActivePesertaUjian = 1; offsetPesertaUjian = 0; peserta_ujian_index();">
                                <option value="" selected disabled>- Pilih Sesi</option>
                                <option v-for=" list in sesi_ujian " :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <!-- <div v-if="sesiFilter != '0' && sesiFilter > 0 && jadwal_ujian != undefined"
                            class="d-flex align-items-center gap-2 ">
                            <div>
                                <span class="fw-bold">
                                    Tanggal :
                                </span>
                                <span>
                                    {{ DateFormat(jadwal_ujian.tanggal) }}
                                </span>
                            </div>
                            <div>
                                <span class="fw-bold">
                                    Jam :
                                </span>
                                <span>
                                    {{ jadwal_ujian.jam_mulai }} - {{ jadwal_ujian.jam_selesai }}
                                </span>
                            </div>
                            <div>
                                <span class="fw-bold">
                                    Ruangan :
                                </span>
                                <span>
                                    {{ jadwal_ujian.ruangan }}
                                </span>
                            </div>
                            <div>
                                <span class="fw-bold">
                                    Dosen Pengawas :
                                </span>
                                <span>
                                    {{ jadwal_ujian.dosen_pengawas }}
                                </span>
                                <span v-if="dosen_pengawas_2 != '' || dosen_pengawas_2 != null">
                                    dan {{ jadwal_ujian.dosen_pengawas_2 }}
                                </span>
                            </div>
                        </div> -->
                    </div>
                    <div class="">

                        <div>* Daftar peserta ujian</div>
                        <div class="border mt-1 rounded-3  font-custom-1 mb-5 " style="overflow-x:auto; z-index: 0;">
                            <table class="table mb-0 bg-transparent table-borderless" style="">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>NIM </th>
                                        <th>Nama lengkap</th>
                                        <th>Sesi</th>
                                        <th>Ruangan</th>
                                        <th>Tanggal</th>
                                        <th>Waktu</th>
                                        <th>Dosen Pengawas 1</th>
                                        <th>Dosen Pengawas 2</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="( record, index ) in recordsPesertaUjian " :key="record.id"
                                        :ref="record.id" class="border-bottom ">
                                        <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                        <!-- <td>{{ DateFormat(record.tanggal) }}</td> -->
                                        <td>{{ record.nim_mahasiswa }}</td>
                                        <td>{{ record.nama_lengkap }}</td>
                                        <td>{{ record.sesi }}</td>
                                        <td>{{ record.ruangan }}</td>
                                        <td>{{ DateFormat(record.tanggal) }}</td>
                                        <td>{{ record.jam_mulai }} - {{ record.jam_selesai }} WIB</td>
                                        <td>{{ record.dosen_pengawas }}</td>
                                        <td>{{ record.dosen_pengawas_2 }}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_list_peserta_ujian')">Tutup</button>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
.pagination {
    --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
    background-color: #0D9044 !important;
    border-color: #0D9044 !important;
    color: white !important;
}


.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}

::-webkit-scrollbar {
    width: 0.6rem;
    border-radius: 0.5rem;
    background-color: rgba(63, 63, 63, 0.656);
    height: 0.8rem;

}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.473);
    border-radius: 0.5rem;
    width: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
    width: 0.3rem;
    background-color: rgba(255, 255, 255, 0.797);
}

.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, hariList, base_url, DateFormat, CloseLoading, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut, AlertBottom, CharAndNumberOnly, DateNow, HourNow, rupiah, rupiahInput, rupiahoutput, addNumberToObjects } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";
import XLSX from "xlsx/dist/xlsx.full.min.js";

export default {
    data() {
        return {
            //data
            url: 'jadwal_ujian',
            url2: 'pengajuan_ujian_susulan',
            url3: 'pengaturan_ujian_susulan',
            api: "",

            //pages
            limit: 5,
            offset: 0,
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            currentPageActive: 1,
            pageActive: 1,

            //

            prodiList: [],
            waktuKuliahList: [],
            dosenWaliList: [],
            semesterList: [],
            stambukList: [],
            tahunAjaranList: [],
            hariJadwalList: [],
            mataKuliahList: [],
            kurikulumList: [],
            dosenList: [],
            ruanganList: [],

            limitList: [{
                value: 5
            }, {
                value: 10
            }, {
                value: 25
            }
            ],

            prodiFilter: "",
            semesterFilter: "",
            tahunAjaranFilter: "",
            hariFilter: "",
            kurikulumFilter: "",
            tipeFilter: "",
            query: "",


            PertemuanList: [
                {
                    nama: 1,
                },
                {
                    nama: 2,
                },
                {
                    nama: 3,
                },
                {
                    nama: 4,
                },
                {
                    nama: 5,
                },
                {
                    nama: 6,
                },
                {
                    nama: 7,
                },
                {
                    nama: 8,
                },
                {
                    nama: 9,
                },
                {
                    nama: 10,
                },
                {
                    nama: 11,
                },
                {
                    nama: 12,
                },
                {
                    nama: 13,
                },
                {
                    nama: 14,
                },
                {
                    nama: 15,
                },
                {
                    nama: 16,
                },
            ],
            JenisPertemuanList: [
                {
                    nama: 'luring',
                },
                {
                    nama: 'daring',
                },
            ],

            //data
            records: [],
            newRecord: { tipe: "", mata_kuliah: "", jenis: "", dosen_pengawas: "", dosen_pengawas_2: "", ruangan: "", tipe_ujian: "", jumlah_sesi: "", jenis_ujian: "", },
            currentRecordBeritaAcara: { pertemuan: "", jenis_pertemuan: "" },
            beritaAcaraRecords: [],
            currentRecord: [],
            mahasiswaRecords: [],
            attendanceRecords: [],
            penggantiList: [],
            paginglist: [],
            isLoad: 0,


            //pengajuan 
            numberPagePengajuan: 1,
            totalPagesPengajuan: 0,
            currentPageActivePengajuan: 1,
            pageActivePengajuan: 1,
            recordsPengajuan: [],
            currentRecordPengajuan: { nim_mahasiswa: "", nama_lengkap: "", mata_kuliah: "", tipe_ujian: "", dosen_pengajar: "", nomor_invoice: "", status_pengajuan: "", tanggal: "", alasan: "" },
            limitPengajuan: 5,
            offsetPengajuan: 0,
            batas_page_awalPengajuan: 0,
            batas_page_akhirPengajuan: 10,

            //
            prodiListPengajuan: [],
            waktuKuliahListPengajuan: [],
            dosenWaliListPengajuan: [],
            semesterListPengajuan: [],
            tahunAjaranListPengajuan: [],
            hariJadwalListPengajuan: [],
            mataKuliahListPengajuan: [],
            kurikulumListPengajuan: [],

            //
            prodiFilterPengajuan: "",
            semesterFilterPengajuan: "",
            tahunAjaranFilterPengajuan: "",
            kurikulumFilterPengajuan: "",
            tipeFilterPengajuan: "",
            MataKuliahFilterPengajuan: "",
            queryPengajuan: "",
            status_terima_pengajuan: "",

            paginglistPengajuan: [],
            isLoadPengajuan: 0,

            // pengaturan
            numberPagePengaturan: 1,
            totalPagesPengaturan: 0,
            currentPageActivePengaturan: 1,
            pageActivePengaturan: 1,
            recordsPengaturan: [],
            currentRecordPengaturan: [],
            newRecordPengaturan: {},
            limitPengaturan: 5,
            offsetPengaturan: 0,
            batas_page_awalPengaturan: 0,
            batas_page_akhirPengaturan: 10,

            tahunAjaranListPengaturan: [],
            semesterListPengaturan: [],

            semesterFilterPengaturan: "",
            tahunAjaranFilterPengaturan: "",
            prodiFilterPengaturan: "",

            paginglistPengaturan: [],
            isLoadPengaturan: 0,

            //peserta 
            recordsMahasiswa: [],

            numberPagePeserta: 1,
            totalPagesPeserta: 0,
            currentPageActivePeserta: 1,
            pageActivePeserta: 1,
            recordsPeserta: [],
            currentRecordPeserta: [],
            newRecordPeserta: {},
            limitPeserta: 5,
            offsetPeserta: 0,
            batas_page_awalPeserta: 0,
            batas_page_akhirPeserta: 10,

            tahunAjaranListPeserta: [],
            semesterListPeserta: [],
            jadwal_ujian: {},
            sesi_ujian: [],
            sesiFilter: "",

            semesterFilterPeserta: "",
            tahunAjaranFilterPeserta: "",
            queryPeserta: "",

            paginglistPeserta: [],
            isLoadPeserta: 0,

            //peserta 
            recordsMahasiswaUjian: [],

            numberPagePesertaUjian: 1,
            totalPagesPesertaUjian: 0,
            currentPageActivePesertaUjian: 1,
            pageActivePesertaUjian: 1,
            recordsPesertaUjian: [],
            currentRecordPesertaUjian: [],
            newRecordPesertaUjian: {},
            limitPesertaUjian: 100,
            offsetPesertaUjian: 0,
            batas_page_awalPesertaUjian: 0,
            batas_page_akhirPesertaUjian: 10,

            tahunAjaranListPesertaUjian: [],
            semesterListPesertaUjian: [],

            semesterFilterPesertaUjian: "",
            tahunAjaranFilterPesertaUjian: "",
            queryPesertaUjian: "",

            paginglistPesertaUjian: [],
            isLoadPesertaUjian: 0,


            //modal berita acara
            mata_kuliah_dipilih: "",
            id_jadwal_dipilih: "",
            id_dosen_dipilih: "",
            id_profiles_dipilih: "",
            batas_page_awal: 0,
            batas_page_akhir: 10,

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            temporaryStatusDosen: "",

            finalisasi: "",
            setujui: "",
            tipe: "",

            //
            kelasList: "",

            //pemetaan ujian
            record_list_jadwal: [],
            list_jadwal_update: [],
            list_jadwal: [],
            recordListJadwal: { id_ruangan: 0, tanggal: "", jam_mulai: "", jam_selesai: "", },

            total_sudah_bayar_belum_dapat_jadwal_ujian_susulan: 0,
        }
    },
    components: {
        SideNavbar,
    },
    computed: {
        returnValueStatusDosen() {
            return this.currentRecordBeritaAcara.status_dosen ? 'TIDAK' : 'HADIR';
        }
    },

    methods: {
        addNumberToObjects,
        rupiah,
        changerupiahInput(value, pilihan, status) {
            if (status == 'insert') {
                if (value.length == 5 && value.slice(-1) == ",") {
                    value = "Rp 0,-";
                    this.newRecordPengaturan[pilihan] = rupiahInput(value);
                }
                this.newRecordPengaturan[pilihan] = rupiahInput(value);

            } else {
                this.currentRecordPengaturan[pilihan] = rupiahInput(value);

            }

        },
        push_list_jadwal(status) {
            if (status == 'insert') {
                this.list_jadwal = [];
                for (let i = 0; i < this.newRecord.jumlah_sesi; i++) {
                    const record_jadwal = { id_ruangan: i, tanggal: DateNow(), jam_mulai: HourNow(), jam_selesai: HourNow(), ruangan: "", dosen_pengawas: "", dosen_pengawas_2: "" }
                    this.list_jadwal.push(record_jadwal);
                }
            } else {
                this.list_jadwal_update = [];
                for (let i = 0; i < this.currentRecord.jumlah_sesi; i++) {
                    let record_jadwal = "";
                    if (this.currentRecord.sesi_default == 0) {
                        record_jadwal = { id_ruangan: i, tanggal: this.currentRecord.tanggal, jam_mulai: this.currentRecord.jam_mulai, jam_selesai: this.currentRecord.jam_selesai, ruangan: this.currentRecord.id_ruangan, dosen_pengawas: this.currentRecord.id_dosen_pengawas, dosen_pengawas_2: this.currentRecord.id_dosen_pengawas_2 }
                        this.list_jadwal_update.push(record_jadwal);
                    } else {
                        record_jadwal = { id_ruangan: i, tanggal: DateNow(), jam_mulai: HourNow(), jam_selesai: HourNow(), ruangan: "", dosen_pengawas: "", dosen_pengawas_2: "" }
                        this.list_jadwal_update.push(record_jadwal);
                    }

                }
            }

        },
        capitalizeWords(str) {
            return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        },
        CharAndNumberOnly,
        DateFormat,
        DateNow,
        HourNow,
        setPertemuan(pertemuan) {
            if (pertemuan == 0) {
                return "";
            } else {
                return pertemuan;
            }
        },

        finalisasiJadwalUjian() {

            ShowLoading();
            const formData = new FormData();

            // Object.entries(this.newRecord).forEach(([key, value]) => {
            //     formData.append(key, value);
            // });

            formData.append('tahun_ajaran', this.tahunAjaranFilter);
            formData.append('semester', this.semesterFilter);
            formData.append('tipe', this.newRecord.tipe);

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_finalisasi";
            } else {
                this.api = base_url + this.url + "_finalisasi";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.records.unshift(response.data.data);

                        // Clear the form inputs
                        this.newRecord = { tipe: "", mata_kuliah: "", jenis: "", dosen_pengawas: "", dosen_pengawas_2: "", ruangan: "", tipe_ujian: "", jumlah_sesi: "" };
                        this.list_jadwal = [];
                        CloseLoading();

                        AlertBottom(response.data.message);
                        this.hideModal('modal_finalisasi_jadwal_ujian', 'close_modal_finalisasi_jadwal_ujian')
                        this.index();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        export_jadwal_ujian() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("limit", 1000);
            formData.append("offset", 0);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("hari", this.hariFilter);
            formData.append("query", this.query);
            formData.append("kurikulum", this.kurikulumFilter);
            formData.append("tipe", this.tipeFilter);

            if (process.env.NODE_ENV === "development") {
                api = base_url + this.url + '_index';
            } else {
                api = base_url + this.url + '_index';
            }

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {

                            response.data.data[1] = this.addNumberToObjects(response.data.data[1]);
                            for (var i = 0; i < response.data.data[1].length; i++) {
                                delete response.data.data[1][i]["id"];
                                delete response.data.data[1][i]["created_at"];
                                delete response.data.data[1][i]["updated_at"];
                            }

                            // Create a new workbook
                            const wb = XLSX.utils.book_new();
                            // Create a new worksheet
                            const ws = XLSX.utils.json_to_sheet(response.data.data[1]);
                            // Add the worksheet to the workbook
                            XLSX.utils.book_append_sheet(wb, ws, 'Jadwal Ujian');
                            // Convert the workbook to an XLSX file and download it
                            XLSX.writeFile(wb, 'Jadwal Ujian' + ".xlsx");
                            CloseLoading();
                        } else {
                            AlertPopup("error", "", response.data.message, 1500, false);
                        }


                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        async index(status) {

            this.paginglist = [];
            ShowLoading();
            this.records = [];

            let formData = new FormData();

            formData.append("limit", this.limit);
            formData.append("offset", this.offset);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("hari", this.hariFilter);
            formData.append("query", this.query);
            formData.append("kurikulum", this.kurikulumFilter);
            formData.append("tipe", this.tipeFilter);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.prodiList = response.data.prodi;
                        this.semesterList = response.data.semester;
                        this.tahunAjaranList = response.data.tahun_ajaran;
                        this.kelasList = response.data.kelas;
                        this.mataKuliahList = response.data.mata_kuliah;
                        this.kurikulumList = response.data.kurikulum;
                        this.dosenList = response.data.dosen;
                        this.ruanganList = response.data.ruangan;
                        this.record_list_jadwal = response.data.list_jadwal;

                        if (response.data.data != undefined && this.tipeFilter != "") {
                            this.records = response.data.data[1];
                            this.totalPages = response.data.data[0][0].number_page;
                            // let object_2 = [];
                            let limit = this.limit;

                            this.pageActive;
                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limit;
                                }

                                if (i <= this.batas_page_akhir && i > this.batas_page_awal) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }


                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhir] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) {

                                    page_list_array[this.batas_page_awal].show = false;
                                    page_list_array[this.batas_page_akhir].show = true;

                                    this.batas_page_awal++;
                                    this.batas_page_akhir++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActive - 1].key) {
                                        this.batas_page_awal--;
                                        this.batas_page_akhir--;

                                        page_list_array[this.batas_page_awal].show = true;
                                        page_list_array[this.batas_page_akhir].show = false;

                                    }


                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) && page_list_array[this.batas_page_akhir] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActive != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglist.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPages,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }
                                // console.log(page_list_array[index_last_true]);
                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglist.push(pemisahPages);
                                    this.paginglist.push(totalPages);
                                }

                            }


                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        async pengajuan_index(status) {

            this.paginglistPengajuan = [];
            ShowLoading();
            this.recordsPengajuan = [];


            let formData = new FormData();

            formData.append("limit", this.limitPengajuan);
            formData.append("offset", this.offsetPengajuan);
            formData.append("tahun_ajaran", this.tahunAjaranFilterPengajuan);
            formData.append("prodi", this.prodiFilterPengajuan);
            formData.append("semester", this.semesterFilterPengajuan);
            formData.append("hari", this.hariFilterPengajuan);
            formData.append("query", this.queryPengajuan);
            formData.append("kurikulum", this.kurikulumFilterPengajuan);
            formData.append("tipe", this.tipeFilterPengajuan);
            formData.append("mata_kuliah", this.MataKuliahFilterPengajuan);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + '_index';
            } else {
                this.api = base_url + this.url2 + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.prodiListPengajuan = response.data.prodi;
                        this.semesterListPengajuan = response.data.semester;
                        this.tahunAjaranListPengajuan = response.data.tahun_ajaran;
                        this.kelasListPengajuan = response.data.kelas;
                        this.mataKuliahListPengajuan = response.data.mata_kuliah;
                        this.kurikulumListPengajuan = response.data.kurikulum;
                        this.dosenListPengajuan = response.data.dosen;
                        this.ruanganListPengajuan = response.data.ruangan;
                        this.total_sudah_bayar_belum_dapat_jadwal_ujian_susulan = response.data.total_sudah_bayar_belum_dapat_jadwal_ujian_susulan;

                        if (response.data.data != undefined) {
                            this.recordsPengajuan = response.data.data[1];
                            this.totalPagesPengajuan = response.data.data[0][0].number_page;

                            let limit = this.limitPengajuan;
                            this.pageActivePengajuan;

                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limitPengajuan;
                                }

                                if (i <= this.batas_page_akhirPengajuan && i > this.batas_page_awalPengajuan) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }



                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhirPengajuan] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActivePengajuan - 1].key) {

                                    page_list_array[this.batas_page_awalPengajuan].show = false;
                                    page_list_array[this.batas_page_akhirPengajuan].show = true;

                                    this.batas_page_awalPengajuan++;
                                    this.batas_page_akhirPengajuan++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActivePengajuan - 1].key) {
                                        this.batas_page_awalPengajuan--;
                                        this.batas_page_akhirPengajuan--;

                                        page_list_array[this.batas_page_awalPengajuan].show = true;
                                        page_list_array[this.batas_page_akhirPengajuan].show = false;
                                    }
                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActivePengajuan - 1].key) && page_list_array[this.batas_page_akhirPengajuan] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActivePengajuan != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglistPengajuan.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPagesPengajuan,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }

                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglistPengajuan.push(pemisahPages);
                                    this.paginglistPengajuan.push(totalPages);
                                }

                            }


                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },


        async pengaturan_index(status) {

            this.paginglistPengaturan = [];
            ShowLoading();
            this.recordsPengaturan = [];


            let formData = new FormData();

            formData.append("limit", this.limitPengaturan);
            formData.append("offset", this.offsetPengaturan);
            formData.append("tahun_ajaran", this.tahunAjaranFilterPengaturan);
            formData.append("semester", this.semesterFilterPengaturan);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url3 + '_index';
            } else {
                this.api = base_url + this.url3 + '_index';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.semesterListPengaturan = response.data.semester;
                        this.tahunAjaranListPengaturan = response.data.tahun_ajaran;

                        if (response.data.data != undefined) {
                            this.recordsPengaturan = response.data.data[1];
                            this.totalPagesPengaturan = response.data.data[0][0].number_page;

                            let limit = this.limitPengaturan;
                            this.pageActivePengaturan;

                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limitPengaturan;
                                }

                                if (i <= this.batas_page_akhirPengaturan && i > this.batas_page_awalPengaturan) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }



                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhirPengaturan] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActivePengaturan - 1].key) {

                                    page_list_array[this.batas_page_awalPengaturan].show = false;
                                    page_list_array[this.batas_page_akhirPengaturan].show = true;

                                    this.batas_page_awalPengaturan++;
                                    this.batas_page_akhirPengaturan++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActivePengaturan - 1].key) {
                                        this.batas_page_awalPengaturan--;
                                        this.batas_page_akhirPengaturan--;

                                        page_list_array[this.batas_page_awalPengaturan].show = true;
                                        page_list_array[this.batas_page_akhirPengaturan].show = false;
                                    }
                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActivePengaturan - 1].key) && page_list_array[this.batas_page_akhirPengaturan] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActivePengaturan != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglistPengaturan.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPagesPengaturan,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }

                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglistPengaturan.push(pemisahPages);
                                    this.paginglistPengaturan.push(totalPages);
                                }

                            }


                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        async peserta_index(status) {

            this.paginglistPeserta = [];
            ShowLoading();
            this.recordsPeserta = [];

            let formData = new FormData();

            formData.append("limit", this.limitPeserta);
            formData.append("offset", this.offsetPeserta);
            formData.append("tahun_ajaran", this.currentRecordPeserta.id_tahun_ajaran);
            formData.append("prodi", this.currentRecordPeserta.id_prodi);
            formData.append("semester", this.currentRecordPeserta.id_semester);
            formData.append("kurikulum", this.currentRecordPeserta.id_kurikulum);
            formData.append("mata_kuliah", this.currentRecordPeserta.id_mata_kuliah);
            formData.append("id", this.currentRecordPeserta.id);
            formData.append("tipe", this.currentRecordPeserta.tipe);
            formData.append("tipe_ujian", this.currentRecordPeserta.tipe_ujian);
            formData.append("query", this.queryPeserta);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'peserta_ujian_susulan' + '_index';
            } else {
                this.api = base_url + 'peserta_ujian_susulan' + '_index';
            }

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.recordsMahasiswa = response.data.mahasiswa;

                        if (response.data.data != undefined) {

                            this.recordsPeserta = response.data.data[1];
                            this.totalPagesPeserta = response.data.data[0][0].number_page;

                            let limit = this.limitPeserta;
                            this.pageActivePeserta;

                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limitPeserta;
                                }

                                if (i <= this.batas_page_akhirPeserta && i > this.batas_page_awalPeserta) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }



                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhirPeserta] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActivePeserta - 1].key) {

                                    page_list_array[this.batas_page_awalPeserta].show = false;
                                    page_list_array[this.batas_page_akhirPeserta].show = true;

                                    this.batas_page_awalPeserta++;
                                    this.batas_page_akhirPeserta++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActivePeserta - 1].key) {
                                        this.batas_page_awalPeserta--;
                                        this.batas_page_akhirPeserta--;

                                        page_list_array[this.batas_page_awalPeserta].show = true;
                                        page_list_array[this.batas_page_akhirPeserta].show = false;
                                    }
                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActivePeserta - 1].key) && page_list_array[this.batas_page_akhirPeserta] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActivePeserta != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglistPeserta.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPagesPeserta,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }

                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglistPeserta.push(pemisahPages);
                                    this.paginglistPeserta.push(totalPages);
                                }

                            }


                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        async peserta_ujian_index(status) {

            this.paginglistPesertaUjian = [];
            ShowLoading();
            this.recordsPesertaUjian = [];

            let formData = new FormData();

            formData.append("limit", this.limitPesertaUjian);
            formData.append("offset", this.offsetPesertaUjian);
            formData.append("sesi", this.sesiFilter);
            formData.append("id_ujian", this.currentRecordPesertaUjian.id);
            formData.append("query", this.queryPesertaUjian);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'peserta_ujian' + '_get';
            } else {
                this.api = base_url + 'peserta_ujian' + '_get';
            }

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // this.jadwal_ujian = response.data.jadwal_ujian;
                        this.sesi_ujian = response.data.sesi;

                        if (response.data.data != undefined) {

                            this.recordsPesertaUjian = response.data.data[1];
                            this.totalPagesPesertaUjian = response.data.data[0][0].number_page;



                            let limit = this.limitPesertaUjian;
                            this.pageActivePesertaUjian;

                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limitPesertaUjian;
                                }

                                if (i <= this.batas_page_akhirPesertaUjian && i > this.batas_page_awalPesertaUjian) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }



                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhirPesertaUjian] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActivePesertaUjian - 1].key) {

                                    // page_list_array[this.batas_page_awalPesertaUjian].show = false;
                                    // page_list_array[this.batas_page_akhirPesertaUjian].show = true;

                                    this.batas_page_awalPesertaUjian++;
                                    this.batas_page_akhirPesertaUjian++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActivePesertaUjian - 1].key) {
                                        this.batas_page_awalPesertaUjian--;
                                        this.batas_page_akhirPesertaUjian--;

                                        // page_list_array[this.batas_page_awalPesertaUjian].show = true;
                                        // page_list_array[this.batas_page_akhirPesertaUjian].show = false;
                                    }
                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActivePesertaUjian - 1].key) && page_list_array[this.batas_page_akhirPesertaUjian] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActivePesertaUjian != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglistPesertaUjian.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPagesPesertaUjian,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }

                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglistPesertaUjian.push(pemisahPages);
                                    this.paginglistPesertaUjian.push(totalPages);
                                }
                            }
                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.list_jadwal_update = [];
            ShowLoading();
            for (let i = 0; i < this.record_list_jadwal.length; i++) {
                if (this.record_list_jadwal[i].id_ujian == this.currentRecord.id) {

                    this.list_jadwal_update.push(this.record_list_jadwal[i]);
                }
            }

            if (this.list_jadwal_update.length == 0) {
                this.push_list_jadwal('update');
            }

            CloseLoading();

            // this.setRecordwithId('tahun_ajaran', this.tahunAjaranList, 'nama', 'id');
            // this.setRecordwithId('dosen_pengawas', this.dosenList, 'nama', 'id');
            // this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            // this.setRecordwithId('kurikulum', this.kurikulumList, 'nama', 'id');
            // this.setRecordwithId('kelas', this.kelasList, 'nama', 'id');

            // this.setRecordwithId('mata_kuliah', this.mataKuliahList, 'nama', 'id');
            // this.setRecordwithId('ruangan', this.ruanganList, 'nama', 'id');





            // this.setRecordwithId('semester', this.semesterList, 'nama', 'id');
            // const index = this.semesterList.findIndex((record) => record.id == this.currentRecord.tipe_semester_id);

            // this.currentRecord['semester'] = this.semesterList[index].id;
            // this.selectedSemester = { id: this.semesterList[index].id, id_tahun_ajaran: this.semesterList[index].id_tahun_ajaran };


            // this.currentRecord.jumlah_dana = this.rupiahInput(this.currentRecord.jumlah_dana);
            this.showModal('modal_edit');

        },
        editRecordPengajuan(record) {
            // Set the current record to edit mode
            this.currentRecordPengajuan = Object.assign({}, record);



            // this.setRecordwithId('tahun_ajaran', this.tahunAjaranList, 'nama', 'id');
            // this.setRecordwithId('dosen_pengawas', this.dosenList, 'nama', 'id');
            // this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            // this.setRecordwithId('kurikulum', this.kurikulumList, 'nama', 'id');
            // this.setRecordwithId('kelas', this.kelasList, 'nama', 'id');

            // this.setRecordwithId('mata_kuliah', this.mataKuliahList, 'nama', 'id');
            // this.setRecordwithId('ruangan', this.ruanganList, 'nama', 'id');

            // const index = this.semesterList.findIndex((record) => record.id == this.currentRecord.tipe_semester_id);

            // this.currentRecord['semester'] = this.semesterList[index].id;
            // this.selectedSemester = { id: this.semesterList[index].id, id_tahun_ajaran: this.semesterList[index].id_tahun_ajaran };


            // this.currentRecord.jumlah_dana = this.rupiahInput(this.currentRecord.jumlah_dana);
            // this.showModal('modal_edit');

        },
        editRecordPengaturan(record) {
            // Set the current record to edit mode
            this.currentRecordPengaturan = Object.assign({}, record);
            this.semesterFilterPengaturan = record.id_semester;

            this.currentRecordPengaturan.harga = rupiahInput(this.currentRecordPengaturan.harga);

        },
        editRecordPeserta(record) {
            // Set the current record to edit mode
            this.currentRecordPeserta = Object.assign({}, record);

            // console.log(this.currentRecordPeserta);
            // this.semesterFilterPengaturan = record.id_semester;

        },
        editRecordPesertaUjian(record) {
            // Set the current record to edit mode
            this.currentRecordPesertaUjian = Object.assign({}, record);
            // console.log(this.currentRecordPeserta);
            // this.semesterFilterPengaturan = record.id_semester;

        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            // console.log(this.currentRecord[field]);
            // console.log(arraylist);
            // console.log(this.currentRecord[field] == arraylist[0])

            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },
        deleteData() {
            ShowLoading();
            const formData = new FormData();
            //get kehadiran siswa
            this.attendanceRecords = delete this.mahasiswaRecords.nama_lengkap;
            this.attendanceRecords = delete this.mahasiswaRecords.nim_mahasiswa;
            this.attendanceRecords = this.mahasiswaRecords.map(employee => {
                const { ...record } = employee;
                delete record.nama_lengkap;
                delete record.nim_mahasiswa;
                return record;
            });

            formData.append("id", this.temporaryId);
            formData.append("status_dosen", this.temporaryStatusDosen);
            formData.append("id_jadwal", this.id_jadwal_dipilih);
            formData.append('kehadiran_siswa', JSON.stringify(this.attendanceRecords));

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        insert() {

            ShowLoading();
            const formData = new FormData();


            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });


            formData.append('tahun_ajaran', this.tahunAjaranFilter);
            formData.append('semester', this.semesterFilter);
            formData.append('kurikulum', this.kurikulumFilter);
            formData.append('prodi', this.prodiFilter);

            formData.append('list_jadwal', JSON.stringify(this.list_jadwal));


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.records.unshift(response.data.data);

                        // Clear the form inputs
                        this.newRecord = { tipe: "", mata_kuliah: "", jenis: "", dosen_pengawas: "", dosen_pengawas_2: "", ruangan: "", tipe_ujian: "", jumlah_sesi: "" };
                        this.list_jadwal = [];
                        CloseLoading();
                        this.index();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_tambah', 'close_modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        update() {

            ShowLoading();
            const formData = new FormData();
            Object.entries(this.currentRecord).forEach(([key, value]) => {
                // const arrayCurrentRecord = ["tahun_ajaran", "dosen_pengawas", "mata_kuliah", "prodi", "kurikulum", "kelas", "ruangan", "semester"]
                // let check = false;
                // for (let element of arrayCurrentRecord) {
                //     if (key.indexOf(element) !== -1) {

                //         check = true;
                //         break;
                //     }
                // }
                // if (check == true) {
                //     formData.append(key.substring(3), value);
                // } else {
                // formData.append(key, value);
                // }
                formData.append(key, value);
            });

            formData.append('list_jadwal', JSON.stringify(this.list_jadwal_update));

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}
                        this.records.splice(index, 1, response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.index();
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        updatePengajuan() {
            ShowLoading();
            const formData = new FormData();
            Object.entries(this.currentRecordPengajuan).forEach(([key, value]) => {
                formData.append(key, value);
            });


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + "_update";
            } else {
                this.api = base_url + this.url2 + "_update";
            }


            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        const index = this.recordsPengajuan.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecordPengajuan = {}
                        // this.$set(this.records, index, response.data.data);
                        this.recordsPengajuan.splice(index, 1, response.data.data);



                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_terima');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        insertPengaturan() {
            ShowLoading();
            const formData = new FormData();

            formData.append('tahun_ajaran', this.tahunAjaranFilterPengaturan);
            formData.append('semester', this.semesterFilterPengaturan);

            this.newRecordPengaturan.harga = rupiahoutput(this.newRecordPengaturan.harga);

            Object.entries(this.newRecordPengaturan).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url3 + "_insert";
            } else {
                this.api = base_url + this.url3 + "_insert";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        // const index = this.recordsPengaturan.findIndex(
                        //     (record) => record.id == response.data.data.id
                        // );
                        this.currentRecordPengaturan = {}
                        // this.$set(this.records, index, response.data.data);
                        // this.recordsPengaturan.splice(index, 1, response.data.data);
                        this.recordsPengaturan.unshift(response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_pengaturan_tambah');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.newRecordPengaturan.harga = rupiahInput(this.newRecordPengaturan.harga);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        insertPeserta(record_2) {
            ShowLoading();
            const formData = new FormData();

            formData.append('mahasiswa', record_2.id_mahasiswa);
            formData.append('tahun_ajaran', record_2.id_tahun_ajaran);
            formData.append('semester', record_2.id_semester);
            formData.append('mata_kuliah', record_2.id_mata_kuliah);
            formData.append('kurikulum', record_2.id_kurikulum);
            formData.append('tipe', record_2.tipe);
            formData.append('id', this.currentRecordPeserta.id);
            Object.entries(this.newRecordPengaturan).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'peserta_ujian_susulan' + "_insert";
            } else {
                this.api = base_url + 'peserta_ujian_susulan' + "_insert";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.recordsPeserta.unshift(response.data.data);

                        const index = this.recordsMahasiswa.findIndex((record) => record.id === record_2.id);
                        this.recordsMahasiswa.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        updatePengaturan() {
            ShowLoading();
            const formData = new FormData();

            delete this.currentRecordPengaturan.tahun_ajaran;
            delete this.currentRecordPengaturan.semester;

            formData.append('tahun_ajaran', this.tahunAjaranFilterPengaturan);
            formData.append('semester', this.semesterFilterPengaturan);
            this.currentRecordPengaturan.harga = rupiahoutput(this.currentRecordPengaturan.harga);
            Object.entries(this.currentRecordPengaturan).forEach(([key, value]) => {
                formData.append(key, value);
            });



            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url3 + "_update";
            } else {
                this.api = base_url + this.url3 + "_update";
            }


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        const index = this.recordsPengaturan.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecordPengaturan = {}
                        // this.$set(this.records, index, response.data.data);
                        this.recordsPengaturan.splice(index, 1, response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_pengaturan_update');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                        this.currentRecordPengaturan.harga = rupiahInput(this.currentRecordPengaturan.harga);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteDataPengajuan() {
            ShowLoading();
            const formData = new FormData();

            formData.append("id", this.temporaryId);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + "_delete";
            } else {
                this.api = base_url + this.url2 + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.recordsPengajuan.findIndex((record) => record.id === this.temporaryId);
                        this.recordsPengajuan.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_delete_pengajuan');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        deleteDataPengaturan() {
            ShowLoading();
            const formData = new FormData();

            formData.append("id", this.temporaryId);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url3 + "_delete";
            } else {
                this.api = base_url + this.url3 + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.recordsPengaturan.findIndex((record) => record.id === this.temporaryId);
                        this.recordsPengaturan.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_delete_pengaturan');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        deleteDataPeserta() {
            ShowLoading();
            const formData = new FormData();

            formData.append("id", this.temporaryId);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'peserta_ujian_susulan' + "_delete";
            } else {
                this.api = base_url + 'peserta_ujian_susulan' + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.recordsPeserta.findIndex((record) => record.id === this.temporaryId);
                        this.recordsPeserta.splice(index, 1);

                        this.recordsMahasiswa.unshift(response.data.data[1][0]);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_delete_peserta');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },

        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';


            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')

            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        deleteRecord(id, status_dosen) {

            this.temporaryId = id;
            this.temporaryStatusDosen = status_dosen;
        },

        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();
        this.hariJadwalList = hariList;
        this.hariList = hariList;
        this.newRecord.tanggal = DateNow();
        this.tipe = localStorage.getItem('tipe_user');

    }



}
</script>