<template>
    <div class="poweredby">
        <img src="https://ptscbd.com/assets/images/logo/logo_scbd-black.png" width="200" style="margin-bottom:30px;">
        <p>
            Background of the work or services that SCBD has provided :
        </p>
        <p>
            ST Bhinneka faced several critical issues, including data migration, security, server deployment, and system backup, all of which needed to be resolved without causing any downtime. After thorough consultation, AWS services such as EC2, RDS, GuardDuty, S3, and CloudWatch were recommended to ensure smooth operation of their educational activities. The implementation of these services was completed successfully with collaboration between PT. SCBD and the Westcon team. The system has since been running seamlessly with no major issues.
        </p>
        
        <p>
            &copy; 2024 - PT.SCBD<br><a href="https://www.ptscbd.com">https://www.ptscbd.com</a>
        </p>
      <div class=""></div>
    </div>
  </template>
  
  <script>
//   import { base_url, AlertPopup, goPage } from "@/assets/javascript/function";
//   import axios from "axios";
//   import Cookies from "js-cookie";
  
  export default {
    // data() {
    //   return {
    //    
    //   };
    // },
    // methods: {
    // },
  };
  </script>

  <style>
    .poweredby {
        margin-top:100px;
        text-align: center;
        padding: 100px;
    }
</style>
  