<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="sticky-top bg-white">
                <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                    Score Mahasiswa
                </div>
                <div class="row mt-2 mb-2 me-0  bg-white">
                    <div class="col-12 d-flex justify-content-start align-items-center gap-1">
                        <div class="">
                            <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                                v-model="stambukFilter" @change="pageActive = 1; offset = 0; index()" required>
                                <option value="" selected>- Pilih Stambuk</option>
                                <option v-for=" list in stambukList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="">
                            <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                                v-model="tahunAjaranFilter" @change="pageActive = 1; offset = 0; index()" required>
                                <option value="" selected>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="">
                            <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                                <option value="" selected :disabled="stambukFilter == ''">- Pilih Prodi</option>
                                <option v-for=" list in prodiList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="">
                            <select class="form-select" aria-label="Default select example" name="jalur" id="jalur"
                                v-model="jalurFilter" @change="pageActive = 1; offset = 0; index(); query = ''" required
                                :disabled="prodiFilter == '' || stambukFilter == ''">
                                <option value="" selected>- Pilih Jalur</option>
                                <option v-for=" list in jalurList " :key="list.nama" :value="list.value">
                                    {{ list.nama }}
                                </option>
                                <option value="SEMUA">
                                    Semua
                                </option>
                            </select>
                        </div>

                        <div class="flex-fill d-flex  align-items-center justify-content-end gap-2 ">
                            <button v-if="update_akses == true"
                                class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;"
                                @click="showModal2('modal_pengaturan_score_mahasiswa'); indexPengaturanScoreMahasiswa()">
                                <i class='bx bxs-cog me-2'></i>
                                Pengaturan Score Mahasiswa
                            </button>
                            <button v-if="update_akses == true"
                                class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;"
                                @click="showModal2('modal_laporan_score_mahasiswa'); index()">
                                <i class='bx bxs-file me-2'></i>
                                Laporan
                            </button>
                            <button v-if="update_akses == true"
                                class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;"
                                @click="showModal2('modal_kelola_tipe_score'); scoreTipe_index()">
                                <img src="@/assets/icon/data_plus.png" alt="" width="15">
                                Score Mahasiswa
                            </button>

                        </div>

                    </div>
                    <div class="d-flex align-items-start justify-content-start mt-2 gap-2">

                        <div>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="limit" @change="pageActive = 1; offset = 0; index()" required>
                                <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                    @change=index()>
                                    {{ list.value }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center  align-items-center">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination" ref="pagi">
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != 1) { pageActive--; offset = offset - limit; index('previous') };"
                                            aria-label="Previous" style="cursor:pointer;">
                                            <span aria-hidden="true">&laquo;</span>
                                        </span>
                                    </li>
                                    <li v-for=" list in paginglist " :key="list.nama" :value="list.value"
                                        class="page-item"
                                        :class="{ 'active': list.nama == pageActive, 'd-none': list.show == false }">
                                        <span class="page-link text-black "
                                            @click="if (list.disabled != true) pageActive = list.nama; offset = list.value; index()"
                                            style="cursor: pointer;">{{ list.nama }}</span>
                                    </li>
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != totalPages) { pageActive++; offset = offset + limit; index('next') };"
                                            aria-label="Next" style="cursor:pointer;">
                                            <span aria-hidden="true">&raquo;</span>
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="w-25">
                            <input type="text" v-model="query" @change="pageActive = 1; offset = 0; index()"
                                class="form-control" placeholder="Cari nim/nama lengkap/tagihan">
                        </div>
                        <div class="">
                            <select class="form-select" aria-label="Default select example" name="status_invoice"
                                id="status_invoice" v-model="statusDiterimaFilter"
                                @change="pageActive = 1; offset = 0; index();" required>
                                <option value="" selected disabled>- Pilih Status </option>
                                <option value="PENDING">PENDING</option>
                                <option value="SETUJU">SETUJU</option>
                                <option value="TOLAK">TOLAK</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
            <div class="d-flex flex-column gap-2 mb-5">

                <div class="border mt-1 rounded-3 me-2 font-custom-1"
                    style="overflow-y:scroll; max-height: 63vh!important;">
                    <table class="table mb-0 bg-transparent table-borderless ">
                        <thead class="sticky-top " style="top: 0px;">
                            <tr>
                                <th scope="col" class="fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">NIM </th>
                                <th scope="col" class="font-custom-1 fs-7">Nama lengkap </th>
                                <th scope="col" class="font-custom-1 fs-7">Prodi </th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran </th>
                                <th scope="col" class="font-custom-1 fs-7">Tipe Kegiatan</th>
                                <th scope="col" class="font-custom-1 fs-7">jenis </th>
                                <th scope="col" class="font-custom-1 fs-7">Nama Kegiatan</th>
                                <th scope="col" class="font-custom-1 fs-7">Score</th>
                                <th scope="col" class="font-custom-1 fs-7">tanggal</th>
                                <!-- <th scope="col" class="font-custom-1 fs-7">gambar</th> -->
                                <th scope="col" class="font-custom-1 fs-7">status</th>
                                <th scope="col" class="fs-7" style="width:5%!important">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.nim_mahasiswa }}</td>
                                <td>{{ capitalizeWords(record.nama_lengkap) }}</td>
                                <td>{{ record.prodi }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.tipe_kegiatan }}</td>
                                <td>{{ record.nama_score }}</td>
                                <td>{{ record.nama }}</td>
                                <td>{{ record.jumlah_score }}</td>
                                <td>{{ record.tanggal }}</td>
                                <!-- <td>{{ record.gambar }}</td> -->
                                <td>{{ record.status_diterima }}</td>
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <!-- <button class="btn btn-primary btn-sm fs-8" v-on:click="editRecordUjian(record)"
                                            v-if="record.status_invoice == 'FINAL' && record.status_text == 'PENDING'"><i
                                                class='bx bxs-pencil fs-8'></i></button> -->
                                        <button class="btn btn-warning btn-sm fs-8" v-on:click="editRecord(record);"><i
                                                class='bx bxs-message-square-edit fs-8'
                                                v-if="update_akses == true"></i></button>
                                        <button class="btn btn-danger btn-sm fs-8" data-bs-toggle="modal"
                                            data-bs-target="#modal_delete" v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>

    <div class="modal fade" id="modal_kelola_tipe_score" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_kelola_tipe_score">
        <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Kelola Tipe Score Mahasiswa</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_kelola_tipe_score"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_kelola_tipe_score')">
                    </button>
                </div>

                <div class="modal-body">

                    <div class="d-flex align-items-start">
                        <div class="flex-fill d-flex align-items-start justify-content-start  gap-2">
                            <div>
                                <select class="form-select" aria-label="Default select example" name="semester"
                                    id="semester" v-model="tipeKegiatanFilterScoreTipe"
                                    @change="pageActiveScoreTipe = 1; offsetScoreTipe = 0; scoreTipe_index()" required>
                                    <option value="" selected>
                                        - Pilih Tipe Kegiatan
                                    </option>
                                    <option v-for=" list in tipeKegiatanListScoreTipe " :key="list.value"
                                        :value="list.value" @change=scoreTipe_index()>
                                        {{ list.value }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <select class="form-select" aria-label="Default select example" name="semester"
                                    id="semester" v-model="limitScoreTipe"
                                    @change="pageActiveScoreTipe = 1; offsetScoreTipe = 0; scoreTipe_index()" required>
                                    <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                        @change=scoreTipe_index()>
                                        {{ list.value }}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex justify-content-center  align-items-center">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination" ref="pagi">
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActiveScoreTipe != 1) { pageActiveScoreTipe--; offsetScoreTipe = offsetScoreTipe - limitScoreTipe; scoreTipe_index('previous') };"
                                                aria-label="Previous" style="cursor:pointer;">
                                                <span aria-hidden="true">&laquo;</span>
                                            </span>
                                        </li>
                                        <li v-for=" list in paginglistScoreTipe " :key="list.nama" :value="list.value"
                                            class="page-item"
                                            :class="{ 'active': list.nama == pageActiveScoreTipe, 'd-none': list.show == false }">
                                            <span class="page-link text-black "
                                                @click="if (list.disabled != true) pageActiveScoreTipe = list.nama; offsetScoreTipe = list.value; scoreTipe_index()"
                                                style="cursor: pointer;">{{
                                                    list.nama }}</span>
                                        </li>
                                        <li class="page-item">
                                            <span class="page-link"
                                                @click="if (pageActiveScoreTipe != totalPagesScoreTipe) { pageActiveScoreTipe++; offsetScoreTipe = offsetScoreTipe + limitScoreTipe; scoreTipe_index('next') };"
                                                aria-label="Next" style="cursor:pointer;">
                                                <span aria-hidden="true">&raquo;</span>
                                            </span>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="w-25">
                                <input type="text" v-model="queryScoreTipe"
                                    @change="pageActiveScoreTipe = 1; offsetScoreTipe = 0; scoreTipe_index()"
                                    class="form-control" placeholder="Cari nama">
                            </div>
                        </div>
                        <div class="flex-fill d-flex  align-items-center justify-content-end gap-2 mt-2">
                            <button v-if="update_akses == true"
                                class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 "
                                style="border-radius: 5px;" @click="showModal2('modal_insert_tipe_score');"
                                type="button">
                                <img src="@/assets/icon/data_plus.png" alt="" width="15">
                                Tambah Score
                            </button>

                        </div>
                    </div>

                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nama</th>
                                    <th>Score</th>
                                    <th>Tipe Kegiatan</th>
                                    <!-- <th>Jenis Ujian</th> -->
                                    <th>Status</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in recordsScoreTipe " :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.nama }}</td>
                                    <td>{{ record.score }}</td>
                                    <td>{{ record.tipe_kegiatan }}</td>
                                    <td>{{ record.status }}</td>
                                    <td class="bg-transparent">
                                        <div class="d-flex gap-2">
                                            <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                                @click="editRecordScoreTipe(record); showModal2('modal_update_tipe_score');"><i
                                                    class='bx bxs-message-square-edit fs-8'></i></button>
                                            <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                                v-on:click="deleteRecordTipeScore(record.id); showModal2('modal_delete_tipe_score')"><i
                                                    class='bx bxs-trash-alt fs-8'></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_kelola_tipe_score')">Tutup</button>
                </div>

            </div>

        </div>

    </div>


    <form @submit.prevent="finalisasi()">
        <div class="modal fade" id="modal_finalisasi" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_finalisasi">
            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Finalisasi</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_finalisasi"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_finalisasi')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="jabatan" class="form-label">Pilih Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                                id="tahun_ajaran" v-model="tahunAjaranFilter"
                                @change="pageActive = 1; offset = 0; index();" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="jabatan" class="form-label">Pilih Prodi</label>
                            <!-- <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                                v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for=" list  in  prodiList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select> -->
                            <vue-select v-model="prodi" :options="prodiList" label="nama" :multiple="true"
                                :reduce="tag => tag.id" :searchable="true" :close-on-select="false"
                                :limit="10"></vue-select>
                        </div>
                        <div class="mb-3">
                            <label for="jabatan" class="form-label">Pilih Semester</label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()"
                                required :disabled="prodi == ''">
                                <option value="" selected>- Pilih Semester</option>
                                <option v-for=" list in semesterList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 ">
                            <label for="stambuk" class="form-label">Pilih Stambuk</label>
                            <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                                v-model="stambukFilter" @change="pageActive = 1; offset = 0; index()" required
                                :disabled="prodi == ''">
                                <option value="" selected>- Pilih Stambuk</option>
                                <option v-for=" list in stambukList " :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 ">
                            <label for="nama_item" class="form-label">Nama Item</label>
                            <select class="form-select" aria-label="Default select example" name="stambuk" id="stambuk"
                                v-model="newRecord.nama_item" @change="pageActive = 1; offset = 0; index()" required
                                :disabled="semesterFilter == ''">
                                <option value="" selected>- Pilih Nama Tagihan</option>
                                <option v-for=" list in namaItemList " :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3 ">
                            <label for="tanggal" class="form-label">Tanggal tagihan</label>
                            <select class="form-select" aria-label="Default select example" name="tanggal" id="tanggal"
                                v-model="newRecord.tanggal" @change="pageActive = 1; offset = 0; index()" required
                                :disabled="semesterFilter == ''">
                                <option value="" selected>- Pilih Tanggal</option>
                                <option v-for=" list in tanggalList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_finalisasi')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_edit')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nim" class="form-label"> NIM </label>
                            <input type="text" class="form-control" v-model="currentRecord.nim_mahasiswa" disabled />
                        </div>
                        <div class="mb-3">
                            <label for="nama_lengkap" class="form-label"> Nama Lengkap</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_lengkap" disabled />
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label"> Nama </label>
                            <input type="text" class="form-control" v-model="currentRecord.nama" disabled />
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label">Tipe Kegiatan </label>
                            <input type="text" class="form-control" v-model="currentRecord.tipe_kegiatan" disabled />
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label"> Tipe </label>
                            <input type="text" class="form-control" v-model="currentRecord.nama_score" disabled />
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label"> Score </label>
                            <input type="text" class="form-control" v-model="currentRecord.jumlah_score" disabled />
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label"> Tanggal </label>
                            <input type="text" class="form-control" v-model="currentRecord.tanggal" disabled />
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label"> Status </label>
                            <input type="text" class="form-control" v-model="currentRecord.status_diterima" disabled />
                        </div>
                        <div>

                            <img :src="currentRecord.gambar" alt=""
                                style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                class=" mb-2 border border-3"
                                @click="source_foto = currentRecord.gambar; showModal2('lihat_foto');"
                                v-if="cekIsPDF(currentRecord.gambar) == 0">

                            <div class="d-flex" v-if="cekIsPDF(currentRecord.gambar) == 1">
                                <a :href="currentRecord.gambar" target="_blank"
                                    class="p-2 rounded border border-success text-black " style="cursor:pointer">
                                    <i class='bx bxs-file-pdf fs-1'></i>
                                </a>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="nama" class="form-label"> Alasan </label>
                            <textarea type="text" class="form-control" v-model="currentRecord.alasan" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="hideModal2('modal_edit')">Tutup</button>
                        <button type="submit" @click="currentRecord.status_diterima = 'TOLAK'"
                            class="btn bg-danger text-light">
                            Tolak </button>
                        <button type="submit" @click="currentRecord.status_diterima = 'SETUJU'"
                            class="btn bg-green-custom text-light">
                            Setuju </button>
                        <!-- <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button> -->
                    </div>
                </div>

            </div>
        </div>
    </form>

    <!-- <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit_ujian" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit_ujian">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_edit_ujian')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nim" class="form-label"> NIM </label>
                            <input type="text" class="form-control" disabled v-model="currentRecord.nim_mahasiswa" />
                        </div>
                        <div class="mb-3">
                            <label for="nama_lengkap" class="form-label"> Nama Lengkap</label>
                            <input type="text" class="form-control" disabled v-model="currentRecord.nama_lengkap" />
                        </div>
                        <div class="mb-3">
                            <label for="jumlah" class="form-label"> Status Ujian</label>
                            <select class="form-select" aria-label="Default select example" name="status_ujian"
                                id="status_ujian" v-model="currentRecord.status_ujian" required>
                                <option value="" selected disabled>- Pilih Status Ujian</option>
                                <option value="YA">Diperbolehkan Ujian</option>
                                <option value="TIDAK" selected>Tidak Diperbolehkan Ujian</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="jumlah" class="form-label"> Jumlah Cicilan</label>
                            <input type="text" class="form-control"
                                @input="changerupiahInputInsert($event.target.value, 'jumlah', 'update')"
                                v-model="currentRecord.jumlah" />
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_akhir" class="form-label"> Tanggal terakhir pembayaran</label>
                            <input type="date" class="form-control" v-model="currentRecord.tanggal_akhir" />
                        </div>
                        <div class="mb-3">
                            <label for="jumlah" class="form-label"> Status pembayaran</label>
                            <select class="form-select" aria-label="Default select example" name="status_pembayaran"
                                id="status_pembayaran" v-model="currentRecord.status_text" required>
                                <option value="" selected disabled>- Pilih Status Pembayaran</option>
                                <option value="PENDING">PENDING</option>
                                <option value="SUCCESS" selected>SUCCESS</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_edit_ujian')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>
            </div>
        </div>
    </form> -->


    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan
                        disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>



    <!-- <div class="modal fade" id="modal_update_generate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_update_generate">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Krs lagi</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_update_generate"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_update_generate')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin mengenerate lagi untuk mahasiswa yang belum dapat krs pada semester ini?
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_update_generate')">Tidak,
                        kembali</button>
                    <button type="button" class="btn bg-danger text-light" @click="cekGenerate('update')">
                        Ya,Generate kembali</button>
                </div>
            </div>
        </div>
    </div> -->
    <form @submit.prevent="insertTipeScore">
        <div class="modal fade" id="modal_insert_tipe_score" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_insert_tipe_score">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Tipe Score</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_insert_tipe_score"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_insert_tipe_score')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nama" class="form-label"> Nama </label>
                            <input type="text" class="form-control" v-model="newRecordScoreTipe.nama" />
                        </div>
                        <div class="mb-3">
                            <label for="number" class="form-label"> Score </label>
                            <input type="number" class="form-control" v-model="newRecordScoreTipe.score" />
                        </div>
                        <div class="mb-3">
                            <label for="nim" class="form-label"> Tipe Kegiatan </label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="newRecordScoreTipe.tipe_kegiatan" required>
                                <option value="" selected disabled>
                                    - Pilih Tipe Kegiatan
                                </option>
                                <option v-for=" list in tipeKegiatanListScoreTipe " :key="list.value"
                                    :value="list.value">
                                    {{ list.value }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_insert_tipe_score')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>

            </div>
        </div>
    </form>

    <form @submit.prevent="updateTipeScore">
        <div class="modal fade" id="modal_update_tipe_score" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_update_tipe_score">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Tipe Score</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_update_tipe_score"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_update_tipe_score')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nama" class="form-label"> Nama </label>
                            <input type="text" class="form-control" v-model="currentRecordScoreTipe.nama" />
                        </div>
                        <div class="mb-3">
                            <label for="number" class="form-label"> Score </label>
                            <input type="number" class="form-control" v-model="currentRecordScoreTipe.score" />
                        </div>
                        <div class="mb-3">
                            <label for="nim" class="form-label"> Tipe Kegiatan </label>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="currentRecordScoreTipe.tipe_kegiatan" required>
                                <option value="" selected disabled>
                                    - Pilih Tipe Kegiatan
                                </option>
                                <option v-for=" list in tipeKegiatanListScoreTipe " :key="list.value"
                                    :value="list.value">
                                    {{ list.value }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_update_tipe_score')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>

            </div>
        </div>
    </form>


    <form @submit.prevent="updatePengaturanScoreMahasiswa">
        <div class="modal fade" id="modal_pengaturan_score_mahasiswa" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_pengaturan_score_mahasiswa">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Pengaturan Score Mahasiswa</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_pengaturan_score_mahasiswa"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_pengaturan_score_mahasiswa')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="score_mahasiswa_tahun_pertama_wajib" class="form-label"> Score Mahasiswa Tahun
                                Pertama Wajib </label>
                            <input type="number" class="form-control"
                                v-model="currentRecordScorePengaturan.score_mahasiswa_tahun_pertama_wajib" />
                        </div>
                        <div class="mb-3">
                            <label for="score_mahasiswa_tahun_pertama_tidak_wajib" class="form-label"> Score Mahasiswa
                                Tahun Pertama Tidak Wajib </label>
                            <input type="number" class="form-control"
                                v-model="currentRecordScorePengaturan.score_mahasiswa_tahun_pertama_tidak_wajib" />
                        </div>
                        <div class="mb-3">
                            <label for="score_mahasiswa_tahun_biasa_wajib" class="form-label"> Score Mahasiswa Tahun
                                Biasa Wajib </label>
                            <input type="number" class="form-control"
                                v-model="currentRecordScorePengaturan.score_mahasiswa_tahun_biasa_wajib" />
                        </div>
                        <div class="mb-3">
                            <label for="score_mahasiswa_tahun_biasa_tidak_wajib" class="form-label"> Score Mahasiswa
                                Tahun Biasa Tidak Wajib </label>
                            <input type="number" class="form-control"
                                v-model="currentRecordScorePengaturan.score_mahasiswa_tahun_biasa_tidak_wajib" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_pengaturan_score_mahasiswa')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>
                    </div>
                </div>

            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_delete_tipe_score" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_delete_tipe_score">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_delete_tipe_score')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_delete_tipe_score')">Tidak,
                        tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteDataTipeScore">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_laporan_score_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_score_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan score Mahasiswa </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_score_mahasiswa')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Stambuk</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="stambukFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for=" list in stambukList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="tahun_ajarn"
                            id="tahun_ajarn" v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                </div>



                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_score_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light" @click="generateExcelScoreMahasiswa">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>



    <div class="modal fade bg-custom-gray" id="lihat_foto" data-bs-backdrop="static" role="dialog" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true" ref="lihat_foto">
        <div class="text-end position-absolute end-0 me-4 mt-2 " style="z-index: 9000!important;">
            <span class=" d-flex align-items-center" style="cursor: pointer;" v-on:click="hideModal2('lihat_foto')"><i
                    class='bx bx-x fs-1 text-danger fw-bold'></i>
            </span>
        </div>
        <div class="modal-dialog modal-xl modal-dialog-centered ">
            <div class="mb-3 w-100 text-center ">
                <img :src="source_foto" alt="" style="max-width: 100%; max-height: 80vh;">
            </div>
        </div>
    </div>
</template>

<style scoped>
.pagination {
    --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
    background-color: #0D9044 !important;
    border-color: #0D9044 !important;
    color: white !important;
}


.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, AlertBottom, base_url, menu_akses_cek, CloseLoading, ShowLoading, ErrorConnectionTimeOut, CharAndNumberOnly, NumberOnly, rupiah, rupiahInput, rupiahoutput, capitalizeWords, DateNow } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";
import XLSX from "xlsx/dist/xlsx.full.min.js";

import vueSelect from 'vue-select';


export default {
    data() {
        return {
            //data
            url: 'score_mahasiswa',
            menu: 'score_mahasiswa',
            api: "",

            //pages
            limit: 10,
            offset: 0,
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            currentPageActive: 1,
            pageActive: 1,


            //list
            prodiList: [],
            tahunAjaranList: [],
            semesterList: [],
            stambukList: [],
            paginglist: [],
            namaItemList: [],
            tanggalList: [],
            jalurList: [],
            limitList: [{
                value: 10
            }, {
                value: 20
            }, {
                value: 50
            }
            ],
            batas_page_awal: 0,
            batas_page_akhir: 10,


            //krs
            progresKrs: 0,
            isLoading: false,
            idKrsLast: 0,
            interval: null,
            updateGenerateKrsId: 0,

            //filter
            prodiFilter: "",
            tahunAjaranFilter: "",
            stambukFilter: "",
            semesterFilter: "",
            jalurFilter: "",
            namaItemFilter: "",
            statusDiterimaFilter: "",
            query: "",
            isLoad: 0,

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: { prodi: "", tahun_ajaran: "", semester: "" },
            currentRecord: {},

            //generate
            prodi: "",

            //scoreTipe
            numberPageScoreTipe: 1,
            totalPagesScoreTipe: 0,
            currentPageActiveScoreTipe: 1,
            pageActiveScoreTipe: 1,
            recordsScoreTipe: [],
            newRecordScoreTipe: { nama: "", tipe_kegiatan: "", score: "" },
            currentRecordScoreTipe: {},
            limitScoreTipe: 10,
            offsetScoreTipe: 0,
            batas_page_awalScoreTipe: 0,
            batas_page_akhirScoreTipe: 10,

            //
            prodiListScoreTipe: [],
            waktuKuliahListScoreTipe: [],
            dosenWaliListScoreTipe: [],
            semesterListScoreTipe: [],
            tahunAjaranListScoreTipe: [],
            hariJadwalListScoreTipe: [],
            mataKuliahListScoreTipe: [],
            tipeKegiatanListScoreTipe: [
                {
                    nama: "WAJIB",
                    value: "WAJIB"
                },
                {
                    nama: "TIDAK WAJIB",
                    value: "TIDAK WAJIB"
                },

            ],

            //
            prodiFilterScoreTipe: "",
            semesterFilterScoreTipe: "",
            tahunAjaranFilterScoreTipe: "",
            tipeKegiatanFilterScoreTipe: "",
            tipeFilterScoreTipe: "",
            MataKuliahFilterScoreTipe: "",
            queryScoreTipe: "",
            status_terima_ScoreTipe: "",

            paginglistScoreTipe: [],
            isLoadScoreTipe: 0,

            source_foto: "",

            currentRecordScorePengaturan: {},


        }
    },
    components: {
        SideNavbar, vueSelect
    },

    methods: {

        DateNow,
        CharAndNumberOnly,
        capitalizeWords,
        rupiah,
        rupiahInput,
        rupiahoutput,
        NumberOnly,
        openInNewTab(link) {
            window.open(link, '_blank', 'fullscreen=yes'); return false;
        },
        addNumberToObjects(objArray) {
            for (let i = 0; i < objArray.length; i++) {
                const newObj = { No: i + 1, ...objArray[i] };
                objArray[i] = newObj;
            }
            return objArray;
        },
        cekIsPDF(url) {
            if (url == undefined) {
                return 2;
            }
            if (url.substr(-3) == 'pdf') {
                return 1;

            }
            return 0;
        },
        changerupiahInputInsert(value, pilihan, status) {
            if (status == 'insert') {
                if (value.length == 5 && value.slice(-1) == ",") {
                    value = "Rp 0,-";
                    this.newRecord[pilihan] = rupiahInput(value);
                }
                this.newRecord[pilihan] = rupiahInput(value);

            } else {
                this.currentRecord[pilihan] = rupiahInput(value);

            }

        },

        async scoreTipe_index(status) {

            this.paginglistScoreTipe = [];
            ShowLoading();
            this.recordsScoreTipe = [];


            let formData = new FormData();

            formData.append("limit", this.limitScoreTipe);
            formData.append("offset", this.offsetScoreTipe);
            formData.append("tipe_kegiatan", this.tipeKegiatanFilterScoreTipe);
            formData.append("query", this.queryScoreTipe);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'tipe_score_index';
            } else {
                this.api = base_url + 'tipe_score_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        console.log(response.data);

                        if (response.data.data != undefined) {
                            this.recordsScoreTipe = response.data.data[1];
                            this.totalPagesScoreTipe = response.data.data[0][0].number_page;

                            let limit = this.limitScoreTipe;
                            this.pageActiveScoreTipe;

                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limitScoreTipe;
                                }

                                if (i <= this.batas_page_akhirScoreTipe && i > this.batas_page_awalScoreTipe) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);

                            }



                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhirScoreTipe] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActiveScoreTipe - 1].key) {

                                    page_list_array[this.batas_page_awalScoreTipe].show = false;
                                    page_list_array[this.batas_page_akhirScoreTipe].show = true;

                                    this.batas_page_awalScoreTipe++;
                                    this.batas_page_akhirScoreTipe++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActiveScoreTipe - 1].key) {
                                        this.batas_page_awalScoreTipe--;
                                        this.batas_page_akhirScoreTipe--;

                                        page_list_array[this.batas_page_awalScoreTipe].show = true;
                                        page_list_array[this.batas_page_akhirScoreTipe].show = false;
                                    }
                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActiveScoreTipe - 1].key) && page_list_array[this.batas_page_akhirScoreTipe] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActiveScoreTipe != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    console.log(page_list_array[i])
                                    console.log([i]);
                                    this.paginglistScoreTipe.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPagesScoreTipe,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }

                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglistScoreTipe.push(pemisahPages);
                                    this.paginglistScoreTipe.push(totalPages);
                                }

                            }


                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index(status) {

            this.paginglist = [];
            ShowLoading();
            this.records = [];


            let formData = new FormData();

            formData.append("limit", this.limit);
            formData.append("offset", this.offset);
            formData.append("stambuk", this.stambukFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("jalur", this.jalurFilter);
            // formData.append("nama_item", this.namaItemFilter);
            formData.append("status_diterima", this.statusDiterimaFilter);
            formData.append("query", this.query);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.stambukList = response.data.stambuk;
                        this.tahunAjaranList = response.data.tahun_ajaran;
                        this.prodiList = response.data.prodi;
                        this.jalurList = response.data.jalur;

                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];
                            this.totalPages = response.data.data[0][0].number_page;
                            let limit = this.limit;

                            this.pageActive;
                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limit;
                                }

                                if (i <= this.batas_page_akhir && i > this.batas_page_awal) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }


                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhir] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) {

                                    page_list_array[this.batas_page_awal].show = false;
                                    page_list_array[this.batas_page_akhir].show = true;

                                    this.batas_page_awal++;
                                    this.batas_page_akhir++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActive - 1].key) {
                                        this.batas_page_awal--;
                                        this.batas_page_akhir--;

                                        page_list_array[this.batas_page_awal].show = true;
                                        page_list_array[this.batas_page_akhir].show = false;

                                    }


                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) && page_list_array[this.batas_page_akhir] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActive != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglist.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPages,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }
                                // console.log(page_list_array[index_last_true]);
                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglist.push(pemisahPages);
                                    this.paginglist.push(totalPages);
                                }

                            }

                            // this.previousLastId = response.data.data[1][0].id;
                            // let last_index = Object.keys(response.data.data[1]).length - 1;
                            // this.NextLastId = response.data.data[1][last_index].id;
                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        insertTipeScore() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.newRecordScoreTipe).forEach(([key, value]) => {
                formData.append(key, value);
            });

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "tipe_score_insert";
            } else {
                this.api = base_url + "tipe_score_insert";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.recordsScoreTipe.unshift(response.data.data);

                        // Clear the form inputs
                        this.newRecordScoreTipe = { nama: "", score: "", tipe_kegiatan: "" };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_insert_tipe_score');
                    }
                    if (response.data.status == false) {

                        AlertPopup("error", "", response.data.message, 1500, false);

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        updateTipeScore() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.currentRecordScoreTipe).forEach(([key, value]) => {
                formData.append(key, value);
            });

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "tipe_score_update";
            } else {
                this.api = base_url + "tipe_score_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.recordsScoreTipe.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecordScoreTipe = {}
                        this.recordsScoreTipe.splice(index, 1, response.data.data);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_update_tipe_score');
                    }
                    if (response.data.status == false) {

                        AlertPopup("error", "", response.data.message, 1500, false);

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelScoreMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("stambuk", this.stambukFilter);

            api = base_url + 'generate_laporan_score_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Score Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Score Mahasiswa' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },


        stopInterval() {
            clearInterval(this.interval); // Clear the interval
            this.interval = null; // Reset the interval variable
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal2('modal_edit');

            // this.currentRecord.jumlah = this.rupiahInput(this.currentRecord.jumlah);
        },

        editRecordScoreTipe(record) {
            this.currentRecordScoreTipe = Object.assign({}, record);
        },
        editRecordUjian(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal2('modal_edit_ujian');

            this.currentRecord.jumlah = this.rupiahInput(this.currentRecord.jumlah);
        },
        cekGenerate() {

            ShowLoading();
            const formData = new FormData();

            if (this.prodi != "") {
                const array = this.prodi;
                this.prodi = array.join(',');
            } else {
                AlertPopup("error", "", "Prodi belum dipilih, silahkan pilih Prodi", 2000, false);
                return true;
            }


            formData.append('tahun_ajaran', this.tahunAjaranFilter);
            formData.append('prodi', this.prodi);
            formData.append('semester', this.semesterFilter);
            formData.append('stambuk', this.stambukFilter);
            formData.append('jalur', this.jalurFilter);
            formData.append('jumlah', rupiahoutput(this.newRecord.jumlah))
            formData.append('tanggal', this.newRecord.tanggal)
            formData.append('tanggal_akhir', this.newRecord.tanggal_akhir)
            formData.append('nama_item', this.newRecord.nama_item)
            formData.append('nama_item_baru', this.newRecord.nama_item_baru)
            formData.append('tipe', this.newRecord.tipe)

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_generate";
            } else {
                this.api = base_url + this.url + "_generate";
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        CloseLoading();
                        // this.index();
                        AlertPopup("success", "", response.data.message, 0, true);
                        this.hideModal2('modal_tambah')
                        // setTimeout(() => {
                        //     this.index();
                        // }, 1500);

                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                        if (this.prodi.length > 0 && this.prodi != '') {
                            const a = this.prodi.split(',');

                            let b = [];

                            for (let [key, value] of a.entries()) {
                                const index = this.prodiList.findIndex((record) => record.id == value);
                                console.log(key);
                                b.push(this.prodiList[index].id);
                            }

                            this.prodi = "";
                            this.prodi = b;
                        }

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        finalisasi() {
            ShowLoading();
            const formData = new FormData();



            if (this.prodi != "") {
                const array = this.prodi;
                this.prodi = array.join(',');
            }

            formData.append('tahun_ajaran', this.tahunAjaranFilter);
            formData.append('prodi', this.prodi);
            formData.append('semester', this.semesterFilter);
            formData.append('stambuk', this.stambukFilter);

            formData.append('nama_item', this.newRecord.nama_item)
            formData.append('tanggal', this.newRecord.tanggal)

            // Object.entries(this.newRecord).forEach(([key, value]) => {
            //     formData.append(key, value);
            // });

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_finalisasi";
            } else {
                this.api = base_url + this.url + "_finalisasi";
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        CloseLoading();
                        AlertPopup("success", "", response.data.message, 1500, false);
                        this.hideModal2('modal_finalisasi')
                        setTimeout(() => {
                            this.index();
                        }, 1500);
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                        if (this.prodi.length > 0 && this.prodi != '') {
                            const a = this.prodi.split(',');

                            let b = [];

                            for (let [key, value] of a.entries()) {
                                const index = this.prodiList.findIndex((record) => record.id == value);
                                console.log(key);
                                b.push(this.prodiList[index].id);
                            }

                            this.prodi = "";
                            this.prodi = b;
                        }
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        update() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_update';
            } else {
                this.api = base_url + this.url + '_update';
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Clear the form inputs
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.records.splice(index, 1, response.data.data);
                        this.currentRecord = {};
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_edit');


                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            console.log(this.temporaryId)
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        deleteDataTipeScore() {
            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "tipe_score_delete";
            } else {
                this.api = base_url + "tipe_score_delete";
            }

            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.recordsScoreTipe.findIndex((record) => record.id === this.temporaryId);
                        this.recordsScoreTipe.splice(index, 1);
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_delete_tipe_score');
                        CloseLoading();
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        getProgresKrs(last_id) {
            console.log('testing_cek_progress');
            this.idKrsLast = 0;
            const formData = new FormData();
            formData.append('krs', last_id);
            if (process.env.NODE_ENV === "development") {
                // this.api = base_url + this.url + "_insert";
                this.api = base_url + "get_krs_progres";
            } else {
                this.api = base_url + "get_krs_progres";
            }
            console.log(this.api);
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.progresKrs = response.data.progres;
                        this.isLoading = true;
                        console.log(response.data.progres)

                    } else {
                        if (response.data.data != undefined) {
                            const index = this.records.findIndex((record) => record.id === response.data.data[0].id);
                            if (index > -1) {
                                this.records.splice(index, 1, response.data.data[0]);
                            } else {
                                this.records.unshift(response.data.data[0]);
                            }

                            this.stopInterval();
                            localStorage.removeItem('id_progress_krs');
                            this.isLoading = false;
                            this.idKrsLast = 0;
                        } else {
                            this.stopInterval();
                        }

                    }
                })
                .catch((error) => {
                    this.stopInterval();
                    // ErrorConnectionTimeOut(error);
                    console.log(error);

                });
        },
        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)
        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        deleteRecord(id) {
            this.temporaryId = id;
            this.showModal2('modal_delete');
        },
        deleteRecordTipeScore(id) {
            this.temporaryId = id;
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        },
        indexPengaturanScoreMahasiswa() {
            ShowLoading();


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "score_mahasiswa_pengaturan_index";
            } else {
                this.api = base_url + "score_mahasiswa_pengaturan_index";
            }
            // Add a new record to the API
            axios
                .post(this.api, '', this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.currentRecordScorePengaturan = response.data.data;
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        updatePengaturanScoreMahasiswa() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.currentRecordScorePengaturan).forEach(([key, value]) => {
                formData.append(key, value);
            });

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "score_mahasiswa_pengaturan_update";
            } else {
                this.api = base_url + "score_mahasiswa_pengaturan_update";
            }
            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        AlertBottom(response.data.message);
                        CloseLoading();
                    }
                    if (response.data.status == false) {

                        AlertPopup("error", "", response.data.message, 1500, false);

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.menu, 'create');
        // console.log(menu_akses_cek(this.menu, 'create'));
        this.update_akses = menu_akses_cek(this.menu, 'update');
        this.delete_akses = menu_akses_cek(this.menu, 'delete');
        // this.newRecord.tanggal = DateNow();
        // this.newRecord.tanggal_akhir = DateNow();

        this.index();
        if (localStorage.getItem('id_progress_krs') != null) {
            this.interval = setInterval(() => {
                this.getProgresKrs(localStorage.getItem('id_progress_krs'));
            }, 1000);
        }


    }



}
</script>