<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar "
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="sticky-top bg-white">
                <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                    Jadwal Mengajar
                </div>
                <div class="row mt-2 mb-2 me-0  bg-white">
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                            id="tahuna_ajaran" v-model="tahunAjaranFilter"
                            @change="pageActive = 1; offset = 0; index();">
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for=" list in prodiList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="prodiFilter == ''">
                            <option value="" selected>- Pilih Semester</option>
                            <option v-for=" list in semesterList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center" v-if="tipe == 'pegawai' || tipe == 'admin'">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="stambukFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="semesterFilter == ''">
                            <option value="" selected>- Pilih Stambuk</option>
                            <option v-for=" list in stambukList " :key="list.id" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center" v-if="tipe == 'pegawai' || tipe == 'admin'">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="kelasFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="stambukFilter == ''">
                            <option value="" selected>- Pilih Kelas</option>
                            <option v-for=" list in kelasList " :key="list.id" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>

                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="hariFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="semesterFilter == ''">
                            <option value="" selected>- Pilih Hari</option>
                            <option v-for=" list in hariJadwalList " :key="list.nama" :value="list.nama">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>


                    <div class="d-flex align-items-start justify-content-start mt-2 gap-2">
                        <div class="">
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="limit" @change="pageActive = 1; offset = 0; index()" required>
                                <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                    @change=index()>
                                    {{ list.value }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center  align-items-center ">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination" ref="pagi">
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != 1) { pageActive--; offset = offset - limit; index('previous') };"
                                            aria-label="Previous" style="cursor:pointer;">
                                            <span aria-hidden="true">&laquo;</span>
                                        </span>
                                    </li>
                                    <li v-for=" list in paginglist " :key="list.nama" :value="list.value"
                                        class="page-item"
                                        :class="{ 'active': list.nama == pageActive, 'd-none': list.show == false }">
                                        <span class="page-link text-black "
                                            @click="if (list.disabled != true) pageActive = list.nama; offset = list.value; index()"
                                            style="cursor: pointer;">{{
                                                list.nama }}</span>
                                    </li>
                                    <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != totalPages) { pageActive++; offset = offset + limit; index('next') };"
                                            aria-label="Next" style="cursor:pointer;">
                                            <span aria-hidden="true">&raquo;</span>
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="flex-fill">
                            <input type="text" v-model="query" @change="pageActive = 1; offset = 0; index()"
                                class="form-control" placeholder="Cari dosen pengajar, mata kuliah ">
                        </div>

                    </div>



                    <!-- <div class="col-2 text-end ">
                        <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            data-bs-toggle="modal" data-bs-target="#modal_tambah" @click="showModal('modal_tambah')">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah Kelas
                        </button>
                    </div> -->


                </div>
            </div>

            <div class="d-flex flex-column gap-2  ">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <div class="border  rounded-3 me-2 font-custom-1 mb-5 "
                    style="overflow-x:scroll; z-index: 0;  overflow-y: scroll; max-height: 65vh!important; ">
                    <table class="table mb-0 bg-transparent table-borderless" style="">
                        <thead class="sticky-top " style="top: 0px;">
                            <tr>
                                <th scope="col" class="fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">Hari</th>
                                <th scope="col" class="font-custom-1 fs-7">Kelas</th>
                                <th scope="col" class="font-custom-1 fs-7">stambuk</th>
                                <th scope="col" class="font-custom-1 fs-7">Kurikulum</th>
                                <th scope="col" class="font-custom-1 fs-7">Mata kuliah</th>
                                <th scope="col" class="font-custom-1 fs-7">Jam</th>
                                <th scope="col" class="font-custom-1 fs-7">Ruangan</th>
                                <th scope="col" class="font-custom-1 fs-7">Tipe </th>
                                <th scope="col" class="font-custom-1 fs-7">Sks teori</th>
                                <th scope="col" class="font-custom-1 fs-7">Sks Praktek</th>
                                <th scope="col" class="font-custom-1 fs-7">Sks Lapangan</th>
                                <th scope="col" class="font-custom-1 fs-7">Dosen Utama </th>
                                <th scope="col" class="font-custom-1 fs-7">Dosen Utama 2 </th>
                                <th scope="col" class="font-custom-1 fs-7">Dosen Pengajar</th>
                                <th scope="col" class="font-custom-1 fs-7">Dosen Pendamping</th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran</th>
                                <th scope="col" class=" fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="( record, index ) in records " :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.hari }}</td>
                                <td>{{ record.kelas }}</td>
                                <td>{{ record.stambuk }}</td>
                                <td>{{ record.kurikulum }}</td>
                                <td>{{ record.mata_kuliah }}</td>
                                <td>{{ record.jam_mulai }} - <br>{{ record.jam_selesai }}</td>
                                <td>{{ record.ruangan }}</td>
                                <td>{{ record.tipe }}</td>
                                <td>{{ record.sks_teori }}</td>
                                <td>{{ record.sks_praktek }}</td>
                                <td>{{ record.sks_lapangan }}</td>
                                <td>{{ record.dosen_utama }}</td>
                                <td>{{ record.dosen_utama_2 }}</td>
                                <td>{{ record.dosen_pengajar }}</td>
                                <td>{{ record.dosen_pendamping }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <!-- <button class="btn btn-primary btn-sm fs-8" v-on:click="editRecord(record)"
                                            data-bs-toggle="modal" data-bs-target="#modal_berita_acara"><i
                                                class='bx bxs-file fs-8'></i></button> -->
                                        <button class="btn btn-warning btn-sm fs-8"
                                            v-on:click="BeritaAcaraIndex(record.id, record.mata_kuliah, record.hari)"
                                            @click="showModal2('modal_list_berita_acara')"><i
                                                class='bx bx-list-ul fs-8'></i></button>


                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="modal_list_berita_acara" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_list_berita_acara">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Berita Acara {{ mata_kuliah_dipilih }}
                    </h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_list_berita_acara"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_list_berita_acara')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex justify-content-end align-items-center gap-2 ">
                            <button class="btn bg-blue-custom fs-8 py-1 text-light font-custom-4 mb-1"
                                style="border-radius: 5px;" @click="generate_rekap_ba();">
                                <i class='bx bxs-file'></i>
                                Generate Rekap Berita Acara
                            </button>
                            <button class="btn bg-blue-custom fs-8 py-1 text-light font-custom-4 mb-1"
                                style="border-radius: 5px;" @click="generate_rekap_absensi_ba();">
                                <i class='bx bxs-file'></i>
                                Generate Rekap Absensi Berita Acara
                            </button>
                            <button class="btn bg-blue-custom fs-8 py-1 text-light font-custom-4 mb-1"
                                style="border-radius: 5px;" @click="generate_rekap_nilai_ba();">
                                <i class='bx bxs-file'></i>
                                Generate Rekap Nilai Berita Acara
                            </button>
                        </div>
                        <div class="d-flex justify-content-end align-items-center ">
                            <button v-if="create_akses == true"
                                class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 mb-1"
                                style="border-radius: 5px;"
                                @click="showModal2('modal_berita_acara_tambah'); beritaAcaraGet(0, 'without_id', this.id_jadwal_dipilih);">
                                <img src="@/assets/icon/user_plus.png" alt="" width="15">
                                Tambah Berita Acara
                            </button>
                        </div>
                    </div>

                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Tanggal</th>
                                    <th>pertemuan </th>
                                    <th>Jam mulai</th>
                                    <th>Jam selesai</th>
                                    <th>Status Kehadiran</th>
                                    <th>Disetujui</th>
                                    <th>Dibuat </th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in beritaAcaraRecords " :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.tanggal }}</td>
                                    <td>{{ setPertemuan(record.pertemuan) }}</td>
                                    <td>{{ record.waktu_mulai }}</td>
                                    <td>{{ record.waktu_selesai }}</td>
                                    <td>{{ record.status_dosen }}</td>
                                    <td>{{ record.persetujuan_tidak_hadir }}</td>
                                    <td>{{ DateFormat(record.tanggal_dibuat) }}</td>

                                    <td class="bg-transparent">
                                        <div class="d-flex gap-2">
                                            <button
                                                v-if="(record.status_ba == 'TIDAK' && tipe == 'dosen' && record.status_dosen == 'HADIR')"
                                                class="btn btn-light  border-primary btn-sm fs-8 d-flex align-items-center"
                                                @click="showModal2('modal_get_qr', record.id)"><i
                                                    class='bx bx-qr text-primary fs-6'></i></button>
                                            <button
                                                v-if="((record.status_ba == 'TIDAK' && tipe == 'dosen') || tipe == 'pegawai' || tipe == 'admin') && update_akses == true"
                                                class="btn btn-warning btn-sm fs-8"
                                                @click="beritaAcaraGet(record.id, 'with_id', this.id_jadwal_dipilih); showModal2('modal_berita_acara_edit');"><i
                                                    class='bx bxs-message-square-edit fs-8'></i></button>

                                            <button v-if="record.status_ba == 'FINAL'"
                                                class="btn btn-primary btn-sm fs-8"
                                                @click="beritaAcaraGet(record.id, 'with_id', this.id_jadwal_dipilih); showModal2('modal_berita_acara_edit');"><i
                                                    class='bx bxs-show fs-8'></i></button>

                                            <button
                                                v-if="((record.status_ba == 'TIDAK' && tipe == 'dosen') || tipe == 'pegawai' || tipe == 'admin') && delete_akses == true"
                                                class="btn btn-danger btn-sm fs-8"
                                                v-on:click="deleteRecord(record.id, record.status_dosen); showModal2('modal_berita_acara_delete');"><i
                                                    class='bx bxs-trash-alt fs-8'></i></button>

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_list_berita_acara')">Tutup</button>
                    <!-- <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->
                </div>
            </div>

        </div>
    </div>

    <form @submit.prevent="beritaAcaraInsert">
        <div class="modal fade" id="modal_berita_acara_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_berita_acara_tambah">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Berita Acara</h5>

                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_berita_acara_tambah')">
                        </button>
                    </div>
                    <div class="modal-body"
                        style="overflow-y:auto; height:100vh!important; overflow-x:hidden; width:100vw!important">
                        <div>
                            <div class="row">
                                <div class="col-6">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Nama Dosen Utama
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {{ currentRecordBeritaAcara.dosen_utama }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Mata kuliah / sks
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {{ currentRecordBeritaAcara.mata_kuliah }} / {{
                                                        currentRecordBeritaAcara.sks
                                                    }} sks
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Fakultas / program studi
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {{ currentRecordBeritaAcara.fakultas }} <br>/ {{
                                                        currentRecordBeritaAcara.prodi
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-6">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    kelas / waktu / semester
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {{ currentRecordBeritaAcara.kelas }} / {{
                                                        currentRecordBeritaAcara.jam_mulai
                                                    }}
                                                    s.d {{ currentRecordBeritaAcara.jam_selesai }} / {{
                                                        currentRecordBeritaAcara.semester }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Hari / waktu / ruang
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {{ currentRecordBeritaAcara.hari }} / {{
                                                        currentRecordBeritaAcara.waktu_kuliah
                                                    }} / {{ currentRecordBeritaAcara.ruangan }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Tahun akademik
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {{ currentRecordBeritaAcara.tahun_ajaran }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="bg-blue-custom text-light py-2 px-2 rounded mt-3">
                                Input berita acara
                            </div>

                            <div class="row mt-2">
                                <div class="d-flex">
                                    <input class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                        type="checkbox" role="switch" id="status_akhir"
                                        v-model="currentRecordBeritaAcara.status_dosen">
                                    <label for="kehadiran_dosen">Dosen tidak hadir </label>
                                </div>
                                <div class="mb-3 mt-3" v-if="currentRecordBeritaAcara.status_dosen == true">
                                    <label for="tanggal_mulai" class="form-label">
                                        Tanggal </label>
                                    <input type="date" class="form-control" required :min="DateNow()" :max="DateNow()"
                                        v-model="currentRecordBeritaAcara.tanggal" v-if="tipe == 'dosen'" />
                                    <input type="date" class="form-control" required
                                        v-model="currentRecordBeritaAcara.tanggal"
                                        v-if="tipe == 'pegawai' || tipe == 'admin'" />
                                </div>
                                <div class="mb-3 mt-3" v-if="currentRecordBeritaAcara.status_dosen == true">
                                    <label for="pokok_bahasan" class="form-label ">Alasan Tidak Hadir</label>
                                    <textarea class="form-control" rows="5"
                                        v-model="currentRecordBeritaAcara.alasan_tidak_hadir"
                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')" />
                                </div>
                                <div class="col-6" v-if="currentRecordBeritaAcara.status_dosen == false">
                                    <div class="mb-3 mt-3">
                                        <label for="tanggal_mulai" class="form-label">
                                            Tanggal </label>
                                        <input type="date" class="form-control" required
                                            v-model="currentRecordBeritaAcara.tanggal" :min="DateNow()" :max="DateNow()"
                                            v-if="tipe == 'dosen'" />
                                        <input type="date" class="form-control" required
                                            v-model="currentRecordBeritaAcara.tanggal"
                                            v-if="tipe == 'pegawai' || tipe == 'admin'" />
                                    </div>
                                    <div class="mb-3 mt-1">
                                        <label for="waktu_pelaksanaan" class="form-label"> Waktu Pelaksanaan</label>
                                        <div class="d-flex align-items-center gap-2">
                                            <div class="">
                                                <input type="time" class="form-control"
                                                    v-model="currentRecordBeritaAcara.waktu_mulai" required />
                                            </div>
                                            <div>
                                                s.d
                                            </div>
                                            <div>
                                                <input type="time" class="form-control "
                                                    v-model="currentRecordBeritaAcara.waktu_selesai" required />
                                            </div>
                                        </div>

                                    </div>
                                    <div class="mb-3 mt-1">
                                        <label for="tanggal_mulai" class="form-label"> Ruangan</label>
                                        <input type="text" class="form-control" required disabled
                                            v-model="currentRecordBeritaAcara.ruangan" />
                                    </div>

                                    <div class="mb-3 mt-1">
                                        <label for="tanggal_mulai" class="form-label"> Pertemuan</label>
                                        <select class="form-select" aria-label="Default select example" name="gelar"
                                            id="gelar" v-model="currentRecordBeritaAcara.pertemuan">
                                            <option value="" selected disabled>- Pilih Pertemuan</option>
                                            <option v-for=" list in PertemuanList " :key="list.nama" :value="list.nama">
                                                {{ list.nama }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="mb-3 mt-1">
                                        <input
                                            class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                            type="checkbox" role="switch" id="pengganti"
                                            v-model="currentRecordBeritaAcara.pengganti">
                                        <label for="pengganti" class="form-label"> Pengganti pertemuan</label>
                                    </div>
                                    <div class="mb-3 mt-1" v-if="currentRecordBeritaAcara.pengganti == true">
                                        <label for="tanggal_mulai" class="form-label"> Pertemuan</label>
                                        <select class="form-select" aria-label="Default select example" name="gelar"
                                            id="gelar" v-model="currentRecordBeritaAcara.pengganti_tanggal">
                                            <option value="" selected disabled>- Pilih Pertemuan</option>
                                            <option v-for=" list in penggantiList " :key="list.tanggal"
                                                :value="list.id">
                                                {{ list.tanggal }}
                                            </option>
                                        </select>
                                    </div>

                                </div>
                                <div class="col-6" v-if="currentRecordBeritaAcara.status_dosen == false">
                                    <div class="mb-3 mt-3">
                                        <label for="tanggal_mulai" class="form-label"> Jenis Pertemuan</label>
                                        <select class="form-select" aria-label="Default select example" name="gelar"
                                            id="gelar" v-model="currentRecordBeritaAcara.jenis_pertemuan" required>
                                            <option value="" selected disabled>- Pilih Jenis Pertemuan</option>
                                            <option v-for=" list in JenisPertemuanList " :key="list.nama"
                                                :value="list.nama">
                                                {{ list.nama }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mb-3 mt-3">
                                        <label for="pokok_bahasan" class="form-label ">Dosen Pengajar </label>
                                        <input type="text" class="form-control"
                                            v-model="currentRecordBeritaAcara.dosen_pengajar" disabled>
                                    </div>
                                    <div class="mb-3 mt-3">
                                        <label for="pokok_bahasan" class="form-label ">Pokok Bahasan </label>
                                        <input type="text" class="form-control"
                                            v-model="currentRecordBeritaAcara.pokok_bahasan" required />
                                    </div>
                                    <div class="mb-3 mt-3">
                                        <label for="pokok_bahasan" class="form-label ">Sub Pokok Bahasan</label>
                                        <textarea class="form-control" rows="5"
                                            v-model="currentRecordBeritaAcara.sub_pokok_bahasan" required />
                                    </div>
                                </div>

                            </div>
                            <!-- <div class="bg-blue-custom text-light py-2 px-2 rounded mt-3"
                                v-if="currentRecordBeritaAcara.status_dosen == false">
                                Presensi perkuliahan
                            </div> -->

                            <!-- <div class="border mt-1 rounded-3 me-2 font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;"
                                v-if="currentRecordBeritaAcara.status_dosen == false">
                                <table class="table mb-0 bg-transparent table-borderless" style="">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>NIM</th>
                                            <th>Nama Mahasiswa</th>
                                            <th>Hadir</th>
                                            <th>Sakit</th>
                                            <th>Izin</th>
                                            <th>Absen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="( record, index ) in  mahasiswaRecords " :key="record.id"
                                            :ref="record.id" class="border-bottom">
                                            <th scope="row" class="bg-transparent">{{ index + 1 }}</th>
                                            <td>{{ record.nim_mahasiswa }}</td>
                                            <td>{{ capitalizeWords(record.nama_lengkap) }}</td>
                                            <td>
                                                <input :id="'hadir_' + record.id"
                                                    class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                    type="radio" :name="'kehadiran_' + record.id" value="HADIR"
                                                    @change="updateAttendance(record.id, 'HADIR')" required>
                                                <label :for="'hadir_' + record.id"></label>
                                            </td>
                                            <td>
                                                <input :id="'sakit_' + record.id"
                                                    class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                    type="radio" :name="'kehadiran_' + record.id" value="SAKIT"
                                                    @change="updateAttendance(record.id, 'SAKIT')" required>
                                                <label :for="'sakit_' + record.id"></label>
                                            </td>
                                            <td>
                                                <input :id="'izin_' + record.id"
                                                    class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                    type="radio" :name="'kehadiran_' + record.id" value="IZIN"
                                                    @change="updateAttendance(record.id, 'IZIN')" required>
                                                <label :for="'izin_' + record.id"></label>
                                            </td>
                                            <td>
                                                <input :id="'absen_' + record.id"
                                                    class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                    type="radio" :name="'kehadiran_' + record.id" value="ABSEN"
                                                    @change="updateAttendance(record.id, 'ABSEN')" required>
                                                <label :for="'absen_' + record.id"></label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->

                        </div>

                        <!-- <div class="mb-3">
                            <label for="nama" class="form-label">Kuota Kelas</label>
                            <input type="number" class="form-control" v-model="currentRecord.kuota" required />
                        </div> -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_berita_acara_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-primary text-light">
                            Simpan </button>

                    </div>


                </div>

            </div>
        </div>
    </form>

    <form @submit.prevent="beritaAcaraUpdate">
        <div class="modal fade" id="modal_berita_acara_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_berita_acara_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
                <div class="modal-content ">
                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Berita Acara</h5>

                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal2('modal_berita_acara_edit')">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Nama Dosen Utama
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {{ currentRecordBeritaAcara.dosen_utama }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Mata kuliah / sks
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {{ currentRecordBeritaAcara.mata_kuliah }} / {{
                                                    currentRecordBeritaAcara.sks
                                                }} sks
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Fakultas / program studi
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {{ currentRecordBeritaAcara.fakultas }} <br>/ {{
                                                    currentRecordBeritaAcara.prodi
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-6">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                kelas / waktu / semester
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {{ currentRecordBeritaAcara.kelas }} / {{
                                                    currentRecordBeritaAcara.jam_mulai
                                                }}
                                                s.d {{ currentRecordBeritaAcara.jam_selesai }} / {{
                                                    currentRecordBeritaAcara.semester }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Hari / waktu / ruang
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {{ currentRecordBeritaAcara.hari }} / {{
                                                    currentRecordBeritaAcara.waktu_kuliah
                                                }} / {{ currentRecordBeritaAcara.ruangan }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Tahun akademik
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {{ currentRecordBeritaAcara.tahun_ajaran }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="bg-blue-custom text-light py-2 px-2 rounded mt-3">
                            Input berita acara
                        </div>

                        <div class="row mt-2">
                            <div class="d-flex">
                                <input class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                    type="checkbox" role="switch" id="status_akhir"
                                    v-model="currentRecordBeritaAcara.status_dosen"
                                    :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen') || currentRecordBeritaAcara.persetujuan_tidak_hadir != ''">
                                <label for="kehadiran_dosen">Dosen tidak hadir</label>
                            </div>
                            <div class=" mt-3" v-if="tipe == 'pegawai' || tipe == 'admin'">
                                <label for="tanggal_dibuat" class="form-label">
                                    Tanggal Dibuat</label>
                                <input type="date" class="form-control"
                                    v-model="currentRecordBeritaAcara.tanggal_dibuat" required />
                            </div>
                            <div class=" mt-3" v-if="currentRecordBeritaAcara.status_dosen == true">
                                <label for="tanggal_mulai" class="form-label">
                                    Tanggal </label>
                                <input type="date" class="form-control" v-model="currentRecordBeritaAcara.tanggal"
                                    :min="currentRecordBeritaAcara.tanggal" :max="currentRecordBeritaAcara.tanggal"
                                    required v-if="tipe == 'dosen'" />
                                <input type="date" class="form-control" v-model="currentRecordBeritaAcara.tanggal"
                                    required v-if="tipe == 'pegawai' || tipe == 'admin'" />
                            </div>
                            <div class=" mt-3" v-if="currentRecordBeritaAcara.status_dosen == true">
                                <label for="pokok_bahasan" class="form-label ">Alasan Tidak Hadir</label>
                                <textarea class="form-control" rows="5"
                                    v-model="currentRecordBeritaAcara.alasan_tidak_hadir"
                                    :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')" />
                            </div>
                            <div class="col-6" v-if="currentRecordBeritaAcara.status_dosen == false">
                                <div class="mb-3 mt-3" v-if="tipe == 'pegawai' || tipe == 'admin'">
                                    <label for="tanggal_dibuat" class="form-label">
                                        Tanggal Dibuat</label>
                                    <input type="date" class="form-control"
                                        v-model="currentRecordBeritaAcara.tanggal_dibuat" required />
                                </div>
                                <div class="mb-3 mt-3">
                                    <label for="tanggal_mulai" class="form-label">
                                        Tanggal </label>
                                    <input type="date" class="form-control" required
                                        v-model="currentRecordBeritaAcara.tanggal"
                                        :min="currentRecordBeritaAcara.tanggal" :max="currentRecordBeritaAcara.tanggal"
                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL')"
                                        v-if="tipe == 'dosen'" />
                                    <input type="date" class="form-control" required
                                        v-model="currentRecordBeritaAcara.tanggal"
                                        v-if="tipe == 'admin' || tipe == 'pegawai'" />
                                </div>
                                <div class="mb-3 mt-1">
                                    <label for="waktu_pelaksanaan" class="form-label"> Waktu Pelaksanaan</label>
                                    <div class="d-flex gap-2">
                                        <div class="">
                                            <input type="time" class="form-control"
                                                v-model="currentRecordBeritaAcara.waktu_mulai"
                                                :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                                required />
                                        </div>
                                        <div>
                                            s.d
                                        </div>
                                        <div>
                                            <input type="time" class="form-control "
                                                v-model="currentRecordBeritaAcara.waktu_selesai"
                                                :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                                required />
                                        </div>
                                    </div>

                                </div>
                                <div class="mb-3 mt-1">
                                    <label for="tanggal_mulai" class="form-label"> Ruangan</label>
                                    <input type="text" class="form-control" required disabled
                                        v-model="currentRecordBeritaAcara.ruangan" />
                                </div>
                                <div class="mb-3 mt-1">
                                    <label for="tanggal_mulai" class="form-label"> Pertemuan</label>
                                    <select class="form-select" aria-label="Default select example" name="gelar"
                                        id="gelar" v-model="currentRecordBeritaAcara.pertemuan"
                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                        required>
                                        <option value="" selected disabled>- Pilih Pertemuan</option>
                                        <option v-for=" list in PertemuanList " :key="list.nama" :value="list.nama">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3 mt-1">
                                    <input class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                        type="checkbox" role="switch" id="pengganti"
                                        v-model="currentRecordBeritaAcara.pengganti">
                                    <label for="pengganti" class="form-label"> Pengganti pertemuan</label>
                                </div>
                                <div class="mb-3 mt-1"
                                    v-if="currentRecordBeritaAcara.pengganti == true || currentRecordBeritaAcara.pengganti == 'true'">
                                    <label for="tanggal_mulai" class="form-label"> Pertemuan</label>
                                    <select class="form-select" aria-label="Default select example" name="gelar"
                                        id="gelar" v-model="currentRecordBeritaAcara.pengganti_tanggal">
                                        <option value="" selected disabled>- Pilih Pertemuan</option>
                                        <option v-for=" list in penggantiList " :key="list.tanggal" :value="list.id">
                                            {{ list.tanggal }}
                                        </option>
                                    </select>
                                </div>

                            </div>
                            <div class="col-6" v-if="currentRecordBeritaAcara.status_dosen == false">
                                <div class="mb-3 mt-3">
                                    <label for="tanggal_mulai" class="form-label"> Jenis Pertemuan</label>
                                    <select class="form-select" aria-label="Default select example" name="gelar"
                                        id="gelar" v-model="currentRecordBeritaAcara.jenis_pertemuan" required>
                                        <option value="" selected disabled>- Pilih Jenis Pertemuan</option>
                                        <option v-for=" list in JenisPertemuanList " :key="list.nama"
                                            :value="list.nama">
                                            {{ list.nama }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-3 mt-3">
                                    <label for="pokok_bahasan" class="form-label ">Dosen Pengajar </label>
                                    <input type="text" class="form-control"
                                        v-model="currentRecordBeritaAcara.dosen_pengajar" disabled>
                                </div>
                                <div class="mb-3 mt-3">
                                    <label for="pokok_bahasan" class="form-label ">Pokok Bahasan </label>
                                    <input type="text" class="form-control"
                                        v-model="currentRecordBeritaAcara.pokok_bahasan" required
                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')" />
                                </div>
                                <div class="mb-3 mt-3">
                                    <label for="pokok_bahasan" class="form-label ">Sub Pokok Bahasan</label>
                                    <textarea class="form-control" rows="5"
                                        v-model="currentRecordBeritaAcara.sub_pokok_bahasan"
                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')" />
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div
                                :class="{ 'col-12': mahasiswaRecordsSesi2 == null || currentRecordBeritaAcara.jenis_pertemuan == 'daring', 'col-6': mahasiswaRecordsSesi2 != null }">
                                <div class="bg-blue-custom text-light py-2 px-2 rounded mt-3"
                                    v-if="currentRecordBeritaAcara.status_dosen == false">
                                    Presensi perkuliahan Sesi 1
                                </div>

                                <div class="border mt-1 rounded-3 me-2 font-custom-1 mb-5"
                                    style="overflow-x:auto; z-index: 0;"
                                    v-if="currentRecordBeritaAcara.status_dosen == false">
                                    <table class="table mb-0 bg-transparent table-borderless" style="">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>NIM</th>
                                                <th>Nama Mahasiswa</th>
                                                <th>Hadir</th>
                                                <th>Sakit</th>
                                                <th>Izin</th>
                                                <th>Absen</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="( record, index ) in mahasiswaRecords " :key="record.id"
                                                :ref="record.id" class="border-bottom">
                                                <th scope="row" class="bg-transparent">{{ index + 1 }}</th>
                                                <td>{{ record.nim_mahasiswa }}</td>
                                                <td>{{ capitalizeWords(record.nama_lengkap) }}</td>
                                                <td>
                                                    <input :id="'hadir_' + record.id"
                                                        class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                        type="radio" :name="'kehadiran_' + record.id" value="HADIR"
                                                        :checked="record.status_kehadiran === 'HADIR'"
                                                        @change="updateAttendance(record.id, 'HADIR')"
                                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                                        required>
                                                    <label :for="'hadir_' + record.id"></label>
                                                </td>
                                                <td>
                                                    <input :id="'sakit_' + record.id"
                                                        class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                        type="radio" :name="'kehadiran_' + record.id" value="SAKIT"
                                                        :checked="record.status_kehadiran === 'SAKIT'"
                                                        @change="updateAttendance(record.id, 'SAKIT')"
                                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                                        required>
                                                    <label :for="'sakit_' + record.id"></label>
                                                </td>
                                                <td>
                                                    <input :id="'izin_' + record.id"
                                                        class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                        type="radio" :name="'kehadiran_' + record.id" value="IZIN"
                                                        :checked="record.status_kehadiran === 'IZIN'"
                                                        @change="updateAttendance(record.id, 'IZIN')"
                                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                                        required>
                                                    <label :for="'izin_' + record.id"></label>
                                                </td>
                                                <td>
                                                    <input :id="'absen_' + record.id"
                                                        class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                        type="radio" :name="'kehadiran_' + record.id" value="ABSEN"
                                                        :checked="record.status_kehadiran === 'ABSEN' || record.status_kehadiran === ''"
                                                        @change="updateAttendance(record.id, 'ABSEN')"
                                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                                        required>
                                                    <label :for="'absen_' + record.id"></label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-6" v-if="mahasiswaRecordsSesi2 != null && currentRecordBeritaAcara.jenis_pertemuan == 'luring'
                            ">
                                <div class="bg-blue-custom text-light py-2 px-2 rounded mt-3"
                                    v-if="currentRecordBeritaAcara.status_dosen == false">
                                    Presensi perkuliahan Sesi 2
                                </div>

                                <div class="border mt-1 rounded-3 me-2 font-custom-1 mb-5"
                                    style="overflow-x:auto; z-index: 0;"
                                    v-if="currentRecordBeritaAcara.status_dosen == false">
                                    <table class="table mb-0 bg-transparent table-borderless" style="">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>NIM</th>
                                                <th>Nama Mahasiswa</th>
                                                <th>Hadir</th>
                                                <th>Sakit</th>
                                                <th>Izin</th>
                                                <th>Absen</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(   record, index   ) in mahasiswaRecordsSesi2   "
                                                :key="record.id" :ref="record.id" class="border-bottom">
                                                <th scope="row" class="bg-transparent">{{ index + 1 }}</th>
                                                <td>{{ record.nim_mahasiswa }}</td>
                                                <td>{{ capitalizeWords(record.nama_lengkap) }}</td>
                                                <td>
                                                    <input :id="'hadir2_' + record.id"
                                                        class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                        type="radio" :name="'kehadiran2_' + record.id" value="HADIR"
                                                        :checked="record.status_kehadiran === 'HADIR'"
                                                        @change="updateAttendanceSesi2(record.id, 'HADIR')"
                                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                                        required>
                                                    <label :for="'hadir2_' + record.id"></label>
                                                </td>
                                                <td>
                                                    <input :id="'sakit2_' + record.id"
                                                        class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                        type="radio" :name="'kehadiran2_' + record.id" value="SAKIT"
                                                        :checked="record.status_kehadiran === 'SAKIT'"
                                                        @change="updateAttendanceSesi2(record.id, 'SAKIT')"
                                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                                        required>
                                                    <label :for="'sakit2_' + record.id"></label>
                                                </td>
                                                <td>
                                                    <input :id="'izin2_' + record.id"
                                                        class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                        type="radio" :name="'kehadiran2_' + record.id" value="IZIN"
                                                        :checked="record.status_kehadiran === 'IZIN'"
                                                        @change="updateAttendanceSesi2(record.id, 'IZIN')"
                                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                                        required>
                                                    <label :for="'izin2_' + record.id"></label>
                                                </td>
                                                <td>
                                                    <input :id="'absen2_' + record.id"
                                                        class="form-check-input bg-green-custom-checked bg-border-green-custom me-2"
                                                        type="radio" :name="'kehadiran2_' + record.id" value="ABSEN"
                                                        :checked="record.status_kehadiran === 'ABSEN' || record.status_kehadiran === ''"
                                                        @change="updateAttendanceSesi2(record.id, 'ABSEN')"
                                                        :disabled="(currentRecordBeritaAcara.status_ba == 'FINAL' && tipe == 'dosen')"
                                                        required>
                                                    <label :for="'absen2_' + record.id"></label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                        <!-- <div class="mb-3">
                            <label for="nama" class="form-label">Kuota Kelas</label>
                            <input type="number" class="form-control" v-model="currentRecord.kuota" required />
                        </div> -->

                    </div>
                    <div class="modal-footer"
                        v-if="(currentRecordBeritaAcara.status_ba != 'FINAL' && tipe == 'dosen') || tipe == 'pegawai' || tipe == 'admin'">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_berita_acara_edit')">Tutup</button>
                        <!-- Finalisasi akan pending dan menunggu persetujuan dari pegawai-->
                        <button type="submit"
                            @click="finalisasi = 'YA'; currentRecordBeritaAcara.persetujuan_tidak_hadir = 'PENDING'"
                            class="btn bg-green-custom text-light"
                            v-if="((tipe == 'dosen') || (update_akses == true && (tipe == 'pegawai' || tipe == 'admin')) || (tipe == 'admin')) && currentRecordBeritaAcara.status_ba == 'TIDAK'">
                            Finalisasi </button>
                        <button type="submit" @click="setujui = 'YA'; finalisasi = 'YA'"
                            class="btn bg-warning text-black"
                            v-if="currentRecordBeritaAcara.persetujuan_tidak_hadir == 'PENDING' && update_akses == true && (tipe == 'pegawai' || tipe == 'admin')">
                            Setujui </button>
                        <button type="submit" @click="setujui = 'TOLAK'; finalisasi = 'YA'"
                            class="btn bg-danger text-white"
                            v-if="(currentRecordBeritaAcara.persetujuan_tidak_hadir != 'TOLAK') && update_akses == true && (tipe == 'pegawai' || tipe == 'admin')">
                            Tolak </button>

                        <button type="submit" @click="finalisasi = 'TIDAK'" class="btn bg-primary text-light"
                            v-if="update_akses == true && (tipe == 'pegawai' || tipe == 'admin') && currentRecordBeritaAcara.status_ba == 'FINAL'">
                            Simpan dan Batalkan Finalisasi </button>
                        <button type="submit" @click="finalisasi = 'TIDAK'" class="btn bg-primary text-light"
                            v-if="(tipe == 'dosen' || tipe == 'admin' || tipe == 'pegawai') && currentRecordBeritaAcara.status_ba == 'TIDAK'">
                            Simpan </button>

                    </div>

                </div>
            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_berita_acara_delete" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_berita_acara_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Berita Acara</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_berita_acara_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_berita_acara_delete')">Tidak,
                        tetap simpan
                        disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="modal_get_qr" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_get_qr">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Berita Acara</h5>

                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_get_qr')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex justify-content-center gap-4 align-items-center">
                        <div class="">
                            <div>
                                <div class="fs-3 mb-2">
                                    QR-Code
                                </div>
                            </div>

                            <div class="mb-2">
                                <select class="form-select" aria-label="Default select example" name="jenis_qr"
                                    id="jenis_qr" v-model="jenisQr" @change="QrGet(idJadwalSementara)" required>
                                    <option value="" selected disabled>- Pilih jenis QR</option>
                                    <option value="1" selected v-if="jenisQrPertemuan == 'luring'">Absensi Mulai
                                    </option>
                                    <option value="2" selected>
                                        <span v-if="jenisQrPertemuan == 'luring' && jumlahSksQr == 2">Absensi
                                            Selesai</span>
                                        <span v-if="jenisQrPertemuan == 'luring' && jumlahSksQr == 4">Absensi
                                            Break</span>
                                        <span v-if="jenisQrPertemuan == 'daring'">Absensi</span>
                                    </option>
                                    <option value="3" selected v-if="jumlahSksQr == 4 && jenisQrPertemuan == 'luring'">
                                        Absensi sesi 2</option>
                                    <option value="4" selected v-if="jumlahSksQr == 4 && jenisQrPertemuan == 'luring'">
                                        Absen
                                        Selesai
                                    </option>
                                </select>
                            </div>
                            <div class="">
                                <select class="form-select" aria-label="Default select example" name="ukuran_qr"
                                    id="ukuran_qr" v-model="sizecode" required>
                                    <option value="" selected disabled>- Pilih ukuran</option>
                                    <option value="300" selected>300</option>
                                    <option value="400" selected>400</option>
                                    <option value="500" selected>500</option>
                                    <option value="600" selected>600</option>
                                    <option value="700" selected>700</option>
                                    <option value="800" selected>800</option>
                                </select>
                            </div>


                        </div>
                        <div>
                            <div class="text-center">
                                <div v-if="(jenisQr == 1 && recordQr.pesan_masuk_1 == '-') || (jenisQr == 3 && recordQr.pesan_masuk_2 == '-')"
                                    class="fs-5 mb-2 text-danger">
                                    Perwakilan mahasiswa 2 Orang
                                </div>
                                <div v-if="jenisQr == 2 || jenisQr == 4" class="fs-5 mb-2 text-danger">
                                    Semua mahasiswa wajib melakukan absensi
                                </div>

                                <div
                                    v-if="(jenisQr == 1 && recordQr.pesan_masuk_1 != '-') || (jenisQr == 3 && recordQr.pesan_masuk_2 != '-')">
                                    <i class='bx bx-check-circle bx-tada font-green-custom' style="font-size:400px"></i>
                                </div>
                                <QrcodeVue :value="qrcode" :size="sizecode" level="H"
                                    v-if="jenisQr == 1 && recordQr.pesan_masuk_1 == '-'" />
                                <QrcodeVue :value="qrcode" :size="sizecode" level="H" v-if="jenisQr == 2" />
                                <QrcodeVue :value="qrcode" :size="sizecode" level="H"
                                    v-if="jenisQr == 3 && recordQr.pesan_masuk_2 == '-'" />
                                <QrcodeVue :value="qrcode" :size="sizecode" level="H" v-if="jenisQr == 4" />


                            </div>
                        </div>
                    </div>

                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_get_qr')">Tutup</button>

                </div> -->

            </div>
        </div>
    </div>
</template>

<style scoped>
.pagination {
    --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
    background-color: #0D9044 !important;
    border-color: #0D9044 !important;
    color: white !important;
}


.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}

::-webkit-scrollbar {
    width: 0.6rem;
    border-radius: 0.5rem;
    background-color: rgba(63, 63, 63, 0.656);
    height: 0.8rem;

}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.473);
    border-radius: 0.5rem;
    width: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
    width: 0.3rem;
    background-color: rgba(255, 255, 255, 0.797);
}

.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, hariList, base_url, DateFormat, CloseLoading, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut, AlertBottom, CharAndNumberOnly, DateNow, HourNow, base_url_2 } from "@/assets/javascript/function";
import QrcodeVue from 'qrcode.vue'
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {
            //data
            url: 'jadwal_mengajar',
            url2: 'berita_acara',
            api: "",

            //pages
            limit: 5,
            offset: 0,
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            currentPageActive: 1,
            pageActive: 1,

            prodiList: [],
            waktuKuliahList: [],
            dosenWaliList: [],
            semesterList: [],
            stambukList: [],
            tahunAjaranList: [],
            hariJadwalList: [],
            kelasList: [],

            limitList: [{
                value: 5
            }, {
                value: 10
            }, {
                value: 25
            }
            ],

            prodiFilter: "",
            semesterFilter: "",
            tahunAjaranFilter: "",
            hariFilter: "",
            kelasFilter: "",
            stambukFilter: "",
            query: "",


            PertemuanList: [
                {
                    nama: 1,
                },
                {
                    nama: 2,
                },
                {
                    nama: 3,
                },
                {
                    nama: 4,
                },
                {
                    nama: 5,
                },
                {
                    nama: 6,
                },
                {
                    nama: 7,
                },
                {
                    nama: 8,
                },
                {
                    nama: 9,
                },
                {
                    nama: 10,
                },
                {
                    nama: 11,
                },
                {
                    nama: 12,
                },
                {
                    nama: 13,
                },
                {
                    nama: 14,
                },
                {
                    nama: 15,
                },
                {
                    nama: 16,
                },
            ],
            JenisPertemuanList: [
                {
                    nama: 'luring',
                },
                {
                    nama: 'daring',
                },
            ],

            //data
            records: [],
            newRecord: { nama: "", prodi: "", waktu_kuliah: "", dosen_wali: "", stambuk: "", keterangan: "", kelas: "" },
            currentRecordBeritaAcara: { pertemuan: "", jenis_pertemuan: "", pengganti: false, },
            beritaAcaraRecords: [],
            mahasiswaRecords: [],
            mahasiswaRecordsSesi2: [],
            attendanceRecords: [],
            attendanceRecordsSesi2: [],
            penggantiList: [],
            paginglist: [],
            isLoad: 0,


            //modal berita acara
            mata_kuliah_dipilih: "",
            id_jadwal_dipilih: "",
            id_dosen_dipilih: "",
            id_profiles_dipilih: "",
            batas_page_awal: 0,
            batas_page_akhir: 10,

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            temporaryStatusDosen: "",

            finalisasi: "",
            setujui: "",
            tipe: "",

            // QR
            qrcode: "",
            sizecode: 400,
            jenisQr: "",
            jumlahSksQr: 0,
            jenisQrPertemuan: "",
            interval: "",
            idJadwalSementara: "",
            recordQr: [],

            // strict attendance lecturer
            status_strict_attendance_lecturer: "TIDAK",
            current_day: "",



        }
    },
    components: {
        SideNavbar,

        QrcodeVue,

    },
    computed: {
        returnValueStatusDosen() {
            return this.currentRecordBeritaAcara.status_dosen ? 'TIDAK' : 'HADIR';
        }
    },

    methods: {
        capitalizeWords(str) {
            if (str != undefined) {
                return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            } else {
                return '';
            }

        },
        CharAndNumberOnly,
        DateFormat,
        DateNow,
        setPertemuan(pertemuan) {
            if (pertemuan == 0) {
                return "";
            } else {
                return pertemuan;
            }
        },
        QrGet(id) {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", id);
            if (this.jenisQr == 0) {
                formData.append("status_qr", "1");
            } else {
                formData.append("status_qr", this.jenisQr);
            }

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + '_qr_get_2';
            } else {
                this.api = base_url + this.url2 + '_qr_get_2';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.qrcode = response.data.data[1][0].qrcode;
                        this.jenisQrPertemuan = response.data.data[1][0].jenis_pertemuan;
                        this.jumlahSksQr = response.data.data[1][0].jumlah_sks;
                        this.recordQr = response.data.data[1][0];
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.hideModal2('modal_get_qr');
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
            this.showModal('modal_list_berita_acara');

        },
        generate_rekap_ba() {
            ShowLoading();
            const formData = new FormData();
            formData.append('p_id_jadwal', this.id_jadwal_dipilih);
            formData.append('p_id_dosen', this.id_dosen_dipilih);
            formData.append('p_id_profiles', this.id_profiles_dipilih);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url_2 + "generate_ba_ustb";
            } else {
                this.api = base_url_2 + "generate_ba_ustb";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        CloseLoading();
                        // location.href = response.data.data.file_report;
                        window.open(response.data.data.file_report, '_blank');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }


                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generate_rekap_absensi_ba() {
            ShowLoading();
            const formData = new FormData();
            formData.append('p_id_jadwal', this.id_jadwal_dipilih);
            formData.append('p_id_dosen', this.id_dosen_dipilih);
            formData.append('p_id_profiles', this.id_profiles_dipilih);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url_2 + "generate_form_absensi_ustb";
            } else {
                this.api = base_url_2 + "generate_form_absensi_ustb";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        CloseLoading();
                        // location.href = response.data.data.file_report;
                        window.open(response.data.data.file_report, '_blank');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }

                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generate_rekap_nilai_ba() {
            ShowLoading();
            const formData = new FormData();
            formData.append('p_id_jadwal', this.id_jadwal_dipilih);
            formData.append('p_id_dosen', this.id_dosen_dipilih);
            formData.append('p_id_profiles', this.id_profiles_dipilih);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url_2 + "generate_ba_nilai";
            } else {
                this.api = base_url_2 + "generate_ba_nilai";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        CloseLoading();
                        // location.href = response.data.data.file_report;
                        window.open(response.data.data.file_report, '_blank');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }

                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        async index(status) {

            this.paginglist = [];
            ShowLoading();
            this.records = [];


            let formData = new FormData();

            formData.append("limit", this.limit);
            formData.append("offset", this.offset);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("hari", this.hariFilter);
            formData.append("kelas", this.kelasFilter);
            formData.append("stambuk", this.stambukFilter);
            formData.append("query", this.query);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.prodiList = response.data.prodi;
                        this.semesterList = response.data.semester;
                        this.tahunAjaranList = response.data.tahun_ajaran;
                        this.kelasList = response.data.kelas;
                        this.stambukList = response.data.stambuk;

                        if (response.data.data != undefined) {
                            if (this.tipe == 'pegawai' || this.tipe == 'admin') {
                                this.status_strict_attendance_lecturer = response.data.data[6][0].status_strict_attendance_lecturer;
                            } else {
                                this.status_strict_attendance_lecturer = response.data.data[5][0].status_strict_attendance_lecturer;
                            }
                        } else {
                            this.status_strict_attendance_lecturer = "TIDAK";
                        }

                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];
                            this.totalPages = response.data.data[0][0].number_page;
                            // let object_2 = [];
                            let limit = this.limit;

                            this.pageActive;
                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limit;
                                }

                                if (i <= this.batas_page_akhir && i > this.batas_page_awal) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }


                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhir] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) {

                                    page_list_array[this.batas_page_awal].show = false;
                                    page_list_array[this.batas_page_akhir].show = true;

                                    this.batas_page_awal++;
                                    this.batas_page_akhir++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActive - 1].key) {
                                        this.batas_page_awal--;
                                        this.batas_page_akhir--;

                                        page_list_array[this.batas_page_awal].show = true;
                                        page_list_array[this.batas_page_akhir].show = false;

                                    }


                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) && page_list_array[this.batas_page_akhir] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActive != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglist.push(page_list_array[i]);
                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPages,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }
                                // console.log(page_list_array[index_last_true]);
                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglist.push(pemisahPages);
                                    this.paginglist.push(totalPages);
                                }

                            }

                            // this.previousLastId = response.data.data[1][0].id;

                            // let last_index = Object.keys(response.data.data[1]).length - 1;
                            // this.NextLastId = response.data.data[1][last_index].id;

                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal('modal_list_berita_acara');

            this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            this.setRecordwithId('dosen_wali', this.dosenWaliList, 'nama', 'id');
            this.setRecordwithId('stambuk', this.stambukList, 'nama', 'id');
            this.setRecordwithId('waktu_kuliah', this.waktuKuliahList, 'nama', 'id');

        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },
        deleteData() {
            ShowLoading();
            const formData = new FormData();
            //get kehadiran siswa
            this.attendanceRecords = delete this.mahasiswaRecords.nama_lengkap;
            this.attendanceRecords = delete this.mahasiswaRecords.nim_mahasiswa;
            this.attendanceRecords = this.mahasiswaRecords.map(employee => {
                const { ...record } = employee;
                delete record.nama_lengkap;
                delete record.nim_mahasiswa;
                return record;
            });

            // get kehadiran siswa 2 
            // this.attendanceRecordsSesi2 = delete this.mahasiswaRecordsSesi2.nama_lengkap;
            // this.attendanceRecordsSesi2 = delete this.mahasiswaRecordsSesi2.nim_mahasiswa;
            // this.attendanceRecordsSesi2 = this.mahasiswaRecordsSesi2.map(employee => {
            //     const { ...record } = employee;
            //     delete record.nama_lengkap;
            //     delete record.nim_mahasiswa;
            //     return record;
            // });


            formData.append("id", this.temporaryId);
            formData.append("status_dosen", this.temporaryStatusDosen);
            formData.append("id_jadwal", this.id_jadwal_dipilih);
            formData.append('kehadiran_siswa', JSON.stringify(this.attendanceRecords));
            // formData.append('kehadiran_siswa_2', JSON.stringify(this.attendanceRecordsSesi2));

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "berita_acara" + "_delete";
            } else {
                this.api = base_url + "berita_acara" + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.beritaAcaraRecords.findIndex((record) => record.id === this.temporaryId);
                        this.beritaAcaraRecords.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_berita_acara_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        BeritaAcaraIndex(id, mata_kuliah, hari) {
            this.beritaAcaraRecords = [];
            this.current_day = hari;
            ShowLoading();
            let formData = new FormData();

            formData.append("jadwal", id);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + '_index';
            } else {
                this.api = base_url + this.url2 + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.id_dosen_dipilih = response.data.id_dosen;
                        this.id_profiles_dipilih = response.data.id_profile;

                        if (response.data.data != undefined) {
                            this.beritaAcaraRecords = response.data.data[0];
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
            this.showModal('modal_list_berita_acara');

            this.mata_kuliah_dipilih = mata_kuliah;
            this.id_jadwal_dipilih = id;
        },
        beritaAcaraGet(id, status, id_jadwal) {
            this.mahasiswaRecords = [];
            ShowLoading();
            let formData = new FormData();

            formData.append("status", status);
            formData.append("id", id);
            formData.append("jadwal", id_jadwal);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + '_get_2';
            } else {
                this.api = base_url + this.url2 + '_get_2';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        if (response.data.data != undefined) {
                            this.currentRecordBeritaAcara = response.data.data[1][0];
                            this.mahasiswaRecords = response.data.data[2];
                            this.penggantiList = response.data.data[3];

                            if (response.data.data[4][0] != undefined) {
                                this.mahasiswaRecordsSesi2 = response.data.data[4];

                            } else {
                                this.mahasiswaRecordsSesi2 = null;
                            }

                            if (this.currentRecordBeritaAcara.status_dosen == 'HADIR' || this.currentRecordBeritaAcara.status_dosen == undefined) {
                                this.currentRecordBeritaAcara.status_dosen = false
                            } else {
                                this.currentRecordBeritaAcara.status_dosen = true
                            }

                            this.PertemuanList = [
                                {
                                    nama: 1,
                                },
                                {
                                    nama: 2,
                                },
                                {
                                    nama: 3,
                                },
                                {
                                    nama: 4,
                                },
                                {
                                    nama: 5,
                                },
                                {
                                    nama: 6,
                                },
                                {
                                    nama: 7,
                                },
                                {
                                    nama: 8,
                                },
                                {
                                    nama: 9,
                                },
                                {
                                    nama: 10,
                                },
                                {
                                    nama: 11,
                                },
                                {
                                    nama: 12,
                                },
                                {
                                    nama: 13,
                                },
                                {
                                    nama: 14,
                                },
                                {
                                    nama: 15,
                                },
                                {
                                    nama: 16,
                                },
                            ]

                            for (let i = 0; i < this.beritaAcaraRecords.length; i++) {
                                console.log(this.beritaAcaraRecords[i]);
                                const index = this.PertemuanList.findIndex((record) => record.nama === this.beritaAcaraRecords[i].pertemuan);

                                if (index > -1) {
                                    if (this.beritaAcaraRecords[i].status_dosen != 'TIDAK' && this.beritaAcaraRecords[i].persetujuan_tidak_hadir != 'TOLAK') {
                                        this.PertemuanList.splice(index, 1);
                                    }

                                }
                            }

                            if (status == 'without_id') {
                                this.currentRecordBeritaAcara.tanggal = DateNow();
                                this.currentRecordBeritaAcara.waktu_mulai = HourNow();
                                this.currentRecordBeritaAcara.waktu_selesai = HourNow();
                                this.currentRecordBeritaAcara.pertemuan = "";
                                this.currentRecordBeritaAcara.jenis_pertemuan = "";


                            } else {
                                this.PertemuanList.unshift({ nama: response.data.data[1][0].pertemuan });
                            }

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.hideModal2('modal_berita_acara_tambah');
                        this.hideModal2('modal_berita_acara_edit');
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
            this.showModal('modal_list_berita_acara');

        },

        updateAttendance(id, value) {
            const index = this.mahasiswaRecords.findIndex(
                (record) => record.id == id
            );
            this.mahasiswaRecords[index].status_kehadiran = value;
            if (this.mahasiswaRecordsSesi2 != null && (value == 'IZIN' || value == 'SAKIT')) {
                this.mahasiswaRecordsSesi2[index].status_kehadiran = value;
            }
        },

        updateAttendanceSesi2(id, value) {
            const index = this.mahasiswaRecordsSesi2.findIndex(
                (record) => record.id == id
            );

            this.mahasiswaRecordsSesi2[index].status_kehadiran = value;
        },
        beritaAcaraUpdate() {

            this.attendanceRecords = delete this.mahasiswaRecords.nama_lengkap;
            this.attendanceRecords = delete this.mahasiswaRecords.nim_mahasiswa;
            this.attendanceRecords = this.mahasiswaRecords.map(employee => {
                const { ...record } = employee;
                delete record.nama_lengkap;
                delete record.nim_mahasiswa;
                return record;

            });

            if (this.currentRecordBeritaAcara.jenis_pertemuan == 'daring') {
                this.mahasiswaRecordsSesi2 = null;
            }

            if (this.mahasiswaRecordsSesi2 != null) {
                this.attendanceRecordsSesi2 = delete this.mahasiswaRecordsSesi2.nama_lengkap;
                this.attendanceRecordsSesi2 = delete this.mahasiswaRecordsSesi2.nim_mahasiswa;
                this.attendanceRecordsSesi2 = this.mahasiswaRecordsSesi2.map(employee => {
                    const { ...record } = employee;
                    delete record.nama_lengkap;
                    delete record.nim_mahasiswa;
                    return record;

                });
            }



            ShowLoading();
            let formData = new FormData();
            this.currentRecordBeritaAcara.waktu_mulai;
            this.currentRecordBeritaAcara.waktu_selesai;

            if (this.currentRecordBeritaAcara.status_dosen == false) {
                this.currentRecordBeritaAcara.status_dosen = 'HADIR'
            } else {
                this.currentRecordBeritaAcara.status_dosen = 'TIDAK'
            }
            if (this.setujui == 'YA' || this.setujui == 'TOLAK') {
                formData.append('persetujuan_tidak_hadir', this.setujui);
                delete this.currentRecordBeritaAcara.persetujuan_tidak_hadir;
            }

            Object.entries(this.currentRecordBeritaAcara).forEach(([key, value]) => {
                formData.append(key, value);
            });
            formData.append('finalisasi', this.finalisasi);
            formData.append('kehadiran_siswa', JSON.stringify(this.attendanceRecords));


            if (this.mahasiswaRecordsSesi2 != null) {
                formData.append('kehadiran_siswa_2', JSON.stringify(this.attendanceRecordsSesi2));
            }

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + '_update_2';
            } else {
                this.api = base_url + this.url2 + '_update_2';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        if (response.data.data != undefined) {
                            const index = this.beritaAcaraRecords.findIndex(
                                (record) => record.id == response.data.data.id
                            );

                            this.currentRecordBeritaAcara = {}
                            this.beritaAcaraRecords.splice(index, 1, response.data.data);

                        }
                        this.hideModal2('modal_berita_acara_edit');
                        AlertBottom(response.data.message);
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                        if (this.currentRecordBeritaAcara.status_dosen == 'HADIR') {
                            this.currentRecordBeritaAcara.status_dosen = false
                        } else {
                            this.currentRecordBeritaAcara.status_dosen = true
                        }
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
            this.showModal('modal_list_berita_acara');

        },
        beritaAcaraInsert() {
            ShowLoading();
            // this.attendanceRecords = delete this.mahasiswaRecords.nama_lengkap;
            // this.attendanceRecords = delete this.mahasiswaRecords.nim_mahasiswa;
            // this.attendanceRecords = this.mahasiswaRecords.map(employee => {
            //     const { ...record } = employee;
            //     delete record.nama_lengkap;
            //     delete record.nim_mahasiswa;
            //     return record;

            // });
            //cek apakah berita acara dibuat sesuai dengan jadwal jika tipenya dosen dan dosen hadir
            //&& currentRecordBeritaAcara.status_dosen == ''

            if (this.tipe == 'dosen' && this.status_strict_attendance_lecturer == "AKTIF" && (this.currentRecordBeritaAcara.pengganti == undefined || this.currentRecordBeritaAcara.pengganti == false)) {
                const currentDate = new Date();
                const dayNumber = currentDate.getDay();
                const dayNames = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

                const currentDay = dayNames[dayNumber];
                if (currentDay != this.current_day) {
                    AlertPopup("error", "", "Mohon maaf anda tidak dapat menginput diluar jadwal mengajar", 1500, false);
                    return true;
                }
            }


            let formData = new FormData();

            this.currentRecordBeritaAcara.jadwal = this.id_jadwal_dipilih;

            if (this.currentRecordBeritaAcara.status_dosen == false) {
                this.currentRecordBeritaAcara.status_dosen = 'HADIR'
            } else {
                this.currentRecordBeritaAcara.status_dosen = 'TIDAK'
            }

            Object.entries(this.currentRecordBeritaAcara).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // formData.append('kehadiran_siswa', JSON.stringify(this.attendanceRecords));

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + '_qr' + '_insert';
            } else {
                this.api = base_url + this.url2 + '_qr' + '_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.beritaAcaraRecords.unshift(response.data.data);
                        }
                        this.hideModal2('modal_berita_acara_tambah');
                        AlertBottom(response.data.message);
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                        if (this.currentRecordBeritaAcara.status_dosen == 'HADIR') {
                            this.currentRecordBeritaAcara.status_dosen = false
                        } else {
                            this.currentRecordBeritaAcara.status_dosen = true
                        }
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);

                });
            this.showModal('modal_list_berita_acara');

        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },

        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        showModal2(modal_name, id) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';

            if (modal_name == 'modal_get_qr') {
                this.idJadwalSementara = id;
                this.QrGet(id);

                if (this.jenisQr == 1 || this.jenisQr == 3) {
                    this.interval = setInterval(() => {
                        this.QrGet(id);
                        // console.log('masih looping');
                    }, 30000);
                } else {
                    this.interval = setInterval(() => {
                        this.QrGet(id);
                        // console.log('masih looping');
                    }, 15000);
                }


            }
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')


            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)

            if (modal_name == 'modal_get_qr') {
                clearInterval(this.interval);
                this.interval = null;
            }

        },
        deleteRecord(id, status_dosen) {

            this.temporaryId = id;
            this.temporaryStatusDosen = status_dosen;
        },

        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');

        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();
        this.hariJadwalList = hariList;
        this.tipe = localStorage.getItem('tipe_user');

    }



}
</script>