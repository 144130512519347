<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar "
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="sticky-top bg-white">
                <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                    Berita Acara Dosen Tidak Hadir
                </div>
                <div class="row mt-2 mb-2 me-0  bg-white">
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="tahun_ajaran"
                            id="tahuna_ajaran" v-model="tahunAjaranFilter"
                            @change="pageActive = 1; offset = 0; index();">
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for=" list in tahunAjaranList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="pageActive = 1; offset = 0; index();" required>
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for=" list in prodiList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="prodiFilter == ''">
                            <option value="" selected>- Pilih Semester</option>
                            <option v-for=" list in semesterList " :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-center">
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="hariFilter" @change="pageActive = 1; offset = 0; index()" required
                            :disabled="semesterFilter == ''">
                            <option value="" selected>- Pilih Hari</option>
                            <option v-for=" list in hariJadwalList " :key="list.nama" :value="list.nama">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-4">
                        <input type="text" v-model="query" @change="pageActive = 1; offset = 0; index()"
                            class="form-control" placeholder="Cari dosen pengajar, mata kuliah, kelas ">
                    </div>

                    <div class="d-flex align-items-start justify-content-start mt-2 gap-2">
                        <div>
                            <select class="form-select" aria-label="Default select example" name="semester"
                                id="semester" v-model="limit" @change="pageActive = 1; offset = 0; index()" required>
                                <option v-for=" list in limitList " :key="list.value" :value="list.value"
                                    @change=index()>
                                    {{ list.value }}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center  align-items-center">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination" ref="pagi">
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != 1) { pageActive--; offset = offset - limit; index('previous') };"
                                            aria-label="Previous" style="cursor:pointer;">
                                            <span aria-hidden="true">&laquo;</span>
                                        </span>
                                    </li>
                                    <li v-for=" list in paginglist " :key="list.nama" :value="list.value"
                                        class="page-item"
                                        :class="{ 'active': list.nama == pageActive, 'd-none': list.show == false }">
                                        <span class="page-link text-black"
                                            @click="if (list.disabled != true) pageActive = list.nama; offset = list.value; index()"
                                            style="cursor: pointer;">{{
                                                list.nama }}</span>
                                    </li>
                                    <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                    <li class="page-item">
                                        <span class="page-link"
                                            @click="if (pageActive != totalPages) { pageActive++; offset = offset + limit; index('next') };"
                                            aria-label="Next" style="cursor:pointer;">
                                            <span aria-hidden="true">&raquo;</span>
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>



                    <!-- <div class="col-2 text-end ">
                        <button class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            data-bs-toggle="modal" data-bs-target="#modal_tambah" @click="showModal('modal_tambah')">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15">
                            Tambah Kelas
                        </button>
                    </div> -->


                </div>
            </div>

            <div class="d-flex flex-column gap-2  ">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <div class="border  rounded-3 me-2 font-custom-1 mb-5 "
                    style="overflow-x:scroll; z-index: 0;  overflow-y: scroll; height: 65vh!important; ">
                    <table class="table mb-0 bg-transparent table-borderless">
                        <thead class="sticky-top " style="top: 0px;">
                            <tr>
                                <th scope="col" class="fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7" width="10%">Tanggal</th>
                                <th scope="col" class="font-custom-1 fs-7" width="20%">Alasan tidak hadir
                                </th>
                                <th scope="col" class="font-custom-1 fs-7" width="5%">Hari</th>
                                <th scope="col" class="font-custom-1 fs-7" width="5%">Kelas</th>
                                <th scope="col" class="font-custom-1 fs-7" width="10%">Kurikulum</th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran</th>
                                <th scope="col" class="font-custom-1 fs-7" width="15%">Mata kuliah</th>
                                <th scope="col" class="font-custom-1 fs-7" width="5%">Jam</th>
                                <th scope="col" class="font-custom-1 fs-7" width="7%">Ruangan</th>
                                <th scope="col" class="font-custom-1 fs-7" width="10%">Tipe </th>
                                <!-- <th scope="col" class="font-custom-1 fs-7">Sks teori</th>
                                <th scope="col" class="font-custom-1 fs-7">Sks Praktek</th>
                                <th scope="col" class="font-custom-1 fs-7">Sks Lapangan</th> -->
                                <th scope="col" class="font-custom-1 fs-7" width="3%">Dosen Utama </th>
                                <th scope="col" class="font-custom-1 fs-7" width="3%">Dosen Utama 2 </th>
                                <th scope="col" class="font-custom-1 fs-7" width="3%">Dosen Pengajar</th>
                                <th scope="col" class="font-custom-1 fs-7" width="4%">Dosen Pendamping</th>

                                <th scope="col" class=" fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="( record, index ) in records " :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ DateFormat(record.tanggal) }}</td>
                                <td>{{ record.alasan_tidak_hadir }}</td>
                                <td>{{ record.hari }}</td>
                                <td>{{ record.kelas }}</td>
                                <td>{{ record.kurikulum }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.mata_kuliah }}</td>
                                <td>{{ record.jam_mulai }} - <br>{{ record.jam_selesai }}</td>
                                <td>{{ record.ruangan }}</td>
                                <td>{{ record.tipe }}</td>
                                <!-- <td>{{ record.sks_teori }}</td>
                                <td>{{ record.sks_praktek }}</td>
                                <td>{{ record.sks_lapangan }}</td> -->
                                <td>{{ record.dosen_utama }}</td>
                                <td>{{ record.dosen_utama_2 }}</td>
                                <td>{{ record.dosen_pengajar }}</td>
                                <td>{{ record.dosen_pendamping }}</td>

                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <!-- <button class="btn btn-primary btn-sm fs-8" v-on:click="editRecord(record)"
                                            data-bs-toggle="modal" data-bs-target="#modal_berita_acara"><i
                                                class='bx bxs-file fs-8'></i></button> -->
                                        <button class="btn btn-success btn-sm fs-8"
                                            @click="showModal2('modal_berita_acara_delete'); getIdBeritaAcaraUpdateSetujui(record.id, record.status_dosen, record.tanggal, record.alasan_tidak_hadir, record.id_jadwal)"><i
                                                class='bx bx-check'></i></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>



    <!-- <div class="modal fade" id="modal_list_berita_acara" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_list_berita_acara">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Berita Acara {{ mata_kuliah_dipilih }} </h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_list_berita_acara"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_list_berita_acara')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex justify-content-end align-items-center ">
                            <button class="btn bg-blue-custom fs-8 py-1 text-light font-custom-4 mb-1"
                                style="border-radius: 5px;" @click="generate_rekap_ba();">
                                <i class='bx bxs-file'></i>
                                Generate Rekap Berita Acara
                            </button>
                        </div>
                        <div class="d-flex justify-content-end align-items-center ">
                            <button v-if="create_akses == true"
                                class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 mb-1"
                                style="border-radius: 5px;"
                                @click="showModal2('modal_berita_acara_tambah'); beritaAcaraGet(0, 'without_id', this.id_jadwal_dipilih);">
                                <img src="@/assets/icon/user_plus.png" alt="" width="15">
                                Tambah Berita Acara
                            </button>
                        </div>
                    </div>

                    <div class="border mt-1 rounded-3  font-custom-1 mb-5" style="overflow-x:auto; z-index: 0;">
                        <table class="table mb-0 bg-transparent table-borderless" style="">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Tanggal</th>
                                    <th>pertemuan </th>
                                    <th>Jam mulai</th>
                                    <th>Jam selesai</th>
                                    <th>Status Kehadiran</th>
                                    <th>Disetujui</th>
                                    <th>Dibuat </th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( record, index ) in  beritaAcaraRecords " :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.tanggal }}</td>
                                    <td>{{ setPertemuan(record.pertemuan) }}</td>
                                    <td>{{ record.waktu_mulai }}</td>
                                    <td>{{ record.waktu_selesai }}</td>
                                    <td>{{ record.status_dosen }}</td>
                                    <td>{{ record.persetujuan_tidak_hadir }}</td>
                                    <td>{{ DateFormat(record.created_at) }}</td>

                                    <td class="bg-transparent">
                                        <div class="d-flex gap-2">
                                            <button
                                                v-if="((record.status_ba == 'TIDAK' && tipe == 'dosen') || tipe == 'pegawai') && update_akses == true"
                                                class="btn btn-warning btn-sm fs-8"
                                                @click="beritaAcaraGet(record.id, 'with_id', this.id_jadwal_dipilih); showModal2('modal_berita_acara_edit');"><i
                                                    class='bx bxs-message-square-edit fs-8'></i></button>
                                            <button v-if="record.status_ba == 'FINAL'" class="btn btn-primary btn-sm fs-8"
                                                @click="beritaAcaraGet(record.id, 'with_id', this.id_jadwal_dipilih); showModal2('modal_berita_acara_edit');"><i
                                                    class='bx bxs-show fs-8'></i></button>
                                            <button
                                                v-if="((record.status_ba == 'TIDAK' && tipe == 'dosen') || tipe == 'pegawai') && delete_akses == true"
                                                class="btn btn-danger btn-sm fs-8"
                                                v-on:click="deleteRecord(record.id, record.status_dosen); showModal2('modal_berita_acara_delete');"><i
                                                    class='bx bxs-trash-alt fs-8'></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_list_berita_acara')">Tutup</button>
                </div>
            </div>

        </div>
    </div> -->



    <div class="modal fade" id="modal_berita_acara_delete" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_berita_acara_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Menyetujui Berita Acara</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_berita_acara_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menyetujui berita acara ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_berita_acara_delete')">Tidak,
                        tetap simpan
                        disini</button>
                    <button type="button" class="btn bg-success text-light" @click="beritaAcaraUpdateSetujui">
                        Ya,Setujui</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.pagination {
    --bs-pagination-color: black !important;
}

.active>.page-link,
.page-link.active {
    background-color: #0D9044 !important;
    border-color: #0D9044 !important;
    color: white !important;
}


.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, hariList, base_url, DateFormat, CloseLoading, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut, AlertBottom, CharAndNumberOnly, DateNow, HourNow, base_url_2 } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {
            //data
            url: 'berita_acara_dosen_tidak_hadir',
            page: 'laporan',
            api: "",

            //pages
            limit: 5,
            offset: 0,
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,
            currentPageActive: 1,
            pageActive: 1,




            prodiList: [],
            waktuKuliahList: [],
            dosenWaliList: [],
            semesterList: [],
            stambukList: [],
            tahunAjaranList: [],
            hariJadwalList: [],
            limitList: [{
                value: 5
            }, {
                value: 10
            }, {
                value: 25
            }
            ],

            prodiFilter: "",
            semesterFilter: "",
            tahunAjaranFilter: "",
            hariFilter: "",
            query: "",


            PertemuanList: [
                {
                    nama: 1,
                },
                {
                    nama: 2,
                },
                {
                    nama: 3,
                },
                {
                    nama: 4,
                },
                {
                    nama: 5,
                },
                {
                    nama: 6,
                },
                {
                    nama: 7,
                },
                {
                    nama: 8,
                },
                {
                    nama: 9,
                },
                {
                    nama: 10,
                },
                {
                    nama: 11,
                },
                {
                    nama: 12,
                },
                {
                    nama: 13,
                },
                {
                    nama: 14,
                },
            ],



            //data
            records: [],
            newRecord: { nama: "", prodi: "", waktu_kuliah: "", dosen_wali: "", stambuk: "", keterangan: "", kelas: "" },
            currentRecordBeritaAcara: {},
            beritaAcaraRecords: [],
            mahasiswaRecords: [],
            attendanceRecords: [],
            penggantiList: [],
            paginglist: [],
            isLoad: 0,


            //modal berita acara
            mata_kuliah_dipilih: "",
            id_jadwal_dipilih: "",
            id_dosen_dipilih: "",
            id_profiles_dipilih: "",
            batas_page_awal: 0,
            batas_page_akhir: 10,

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            temporaryStatusDosen: "",
            temporaryIdUpdate: "",
            temporaryTanggal: "",
            temporaryAlasan: "",
            temporaryIdJadwal: "",

            finalisasi: "",
            setujui: "",
            tipe: "",



        }
    },
    components: {
        SideNavbar,
    },
    computed: {
        returnValueStatusDosen() {
            return this.currentRecordBeritaAcara.status_dosen ? 'TIDAK' : 'HADIR';
        }
    },

    methods: {
        CharAndNumberOnly,
        DateFormat,
        setPertemuan(pertemuan) {
            if (pertemuan == 0) {
                return "";
            } else {
                return pertemuan;
            }
        },

        generate_rekap_ba() {
            ShowLoading();
            const formData = new FormData();
            formData.append('p_id_jadwal', this.id_jadwal_dipilih);
            formData.append('p_id_dosen', this.id_dosen_dipilih);
            formData.append('p_id_profiles', this.id_profiles_dipilih);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url_2 + "generate_ba_ustb";
            } else {
                this.api = base_url_2 + "generate_ba_ustb";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        CloseLoading();
                        location.href = response.data.data.file_report;
                        // window.open(response.data.data.file_report, '_blank');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }


                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        async index(status) {

            this.paginglist = [];
            ShowLoading();
            this.records = [];


            let formData = new FormData();

            formData.append("limit", this.limit);
            formData.append("offset", this.offset);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("hari", this.hariFilter);
            formData.append("query", this.query);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.prodiList = response.data.prodi;
                        this.semesterList = response.data.semester;
                        this.tahunAjaranList = response.data.tahun_ajaran;

                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];
                            this.totalPages = response.data.data[0][0].number_page;
                            // let object_2 = [];
                            let limit = this.limit;

                            this.pageActive;
                            let page_list_array = [];
                            let page_list;
                            for (let i = 1; i <= response.data.data[0][0].number_page; i++) {
                                if (i == 1) {
                                    limit = 0;
                                } else {
                                    limit = limit + this.limit;
                                }

                                if (i <= this.batas_page_akhir && i > this.batas_page_awal) {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: true,
                                        disabled: false,
                                    };

                                } else {
                                    page_list = {
                                        nama: i,
                                        value: limit,
                                        key: 'page_number_' + i,
                                        show: false,
                                        disabled: false,
                                    };
                                }
                                page_list_array.push(page_list);
                            }


                            if (page_list_array && page_list_array.length > 0) {
                                let index_last_true;
                                let index_first_true;
                                for (let i = 0; i < page_list_array.length; i++) {
                                    if (page_list_array[i].show == true) {
                                        index_last_true = i;
                                    }
                                }
                                for (let i = 0; i < page_list_array.length; i++) {

                                    if (page_list_array[i].show == true) {
                                        index_first_true = i;
                                        break;
                                    }
                                }

                                if (page_list_array[this.batas_page_akhir] != undefined && page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) {

                                    page_list_array[this.batas_page_awal].show = false;
                                    page_list_array[this.batas_page_akhir].show = true;

                                    this.batas_page_awal++;
                                    this.batas_page_akhir++;

                                } else if (this.pageActive != 1) {

                                    if (page_list_array[index_first_true].key == page_list_array[this.pageActive - 1].key) {
                                        this.batas_page_awal--;
                                        this.batas_page_akhir--;

                                        page_list_array[this.batas_page_awal].show = true;
                                        page_list_array[this.batas_page_akhir].show = false;

                                    }


                                }

                                if (status == 'next') {

                                    if (((page_list_array[index_last_true].key == page_list_array[this.pageActive - 1].key) && page_list_array[this.batas_page_akhir] == undefined)) {
                                        //
                                    } else {
                                        //
                                    }

                                } else if (status == 'previous' && this.pageActive != 1) {
                                    // this.pageActive--; this.offset = page_list_array[this.pageActive - 1].value;
                                }

                                for (let i = 0; i < page_list_array.length; i++) {
                                    this.paginglist.push(page_list_array[i]);

                                }


                                let pemisahPages = {
                                    nama: '...',
                                    value: '...',
                                    key: 'page_number_...',
                                    show: true,
                                    disabled: true,
                                }

                                let totalPages = {
                                    nama: this.totalPages,
                                    value: '0',
                                    key: 'page_number_total_pages',
                                    show: true,
                                    disabled: true,
                                }
                                // console.log(page_list_array[index_last_true]);
                                if (page_list_array[index_last_true + 1] && (page_list_array[index_last_true + 1].show != true || page_list_array[index_last_true + 1].nama != totalPages.nama)) {
                                    this.paginglist.push(pemisahPages);
                                    this.paginglist.push(totalPages);
                                }

                            }

                            this.previousLastId = response.data.data[1][0].id;

                            let last_index = Object.keys(response.data.data[1]).length - 1;
                            this.NextLastId = response.data.data[1][last_index].id;

                        }


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {
            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);



            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.records = response.data.data[1];
                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage + 1;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {

            let last_index = this.arrayLastIdOld.length;
            last_index = last_index - 1;
            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.arrayPreviousIdOld[last_index]);
            formData.append("last_id", "");
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.records = response.data.data[1];

                        this.totalPages = response.data.data[0][0].number_page;
                        this.previousLastId = response.data.data[1][0].id;

                        let last_index = Object.keys(response.data.data[1]).length - 1;
                        this.NextLastId = response.data.data[1][last_index].id;

                        this.numberPage = this.numberPage - 1;


                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },




        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal('modal_list_berita_acara');

            this.setRecordwithId('prodi', this.prodiList, 'nama', 'id');
            this.setRecordwithId('dosen_wali', this.dosenWaliList, 'nama', 'id');
            this.setRecordwithId('stambuk', this.stambukList, 'nama', 'id');
            this.setRecordwithId('waktu_kuliah', this.waktuKuliahList, 'nama', 'id');

        },
        setRecordwithId(field, arraylist, fieldNameArray, valueWantAdd) {
            if (this.currentRecord[field] != null) {
                const index = arraylist.findIndex((record) => record[fieldNameArray] == this.currentRecord[field]);
                this.currentRecord[field] = arraylist[index][valueWantAdd];
            } else {
                this.currentRecord[field] = "";
            }
        },
        getIdBeritaAcaraUpdateSetujui(id, status_dosen, tanggal, alasan, id_jadwal) {
            this.temporaryIdUpdate = id;
            this.temporaryStatusDosen = status_dosen;
            this.temporaryTanggal = tanggal;
            this.temporaryAlasan = alasan;
            this.temporaryIdJadwal = id_jadwal;
        },
        beritaAcaraUpdateSetujui() {

            ShowLoading();
            let formData = new FormData();
            formData.append('id', this.temporaryIdUpdate);
            formData.append('status_dosen', this.temporaryStatusDosen);
            formData.append('tanggal', this.temporaryTanggal);
            formData.append('alasan_tidak_hadir', this.temporaryAlasan);
            formData.append('id_jadwal', this.temporaryIdJadwal);
            formData.append('persetujuan_tidak_hadir', 'DISETUJUI');

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "berita_acara" + "_update";
            } else {
                this.api = base_url + "berita_acara" + "_update";
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            const index = this.records.findIndex((record) => record.id === this.temporaryId);
                            this.records.splice(index, 1);
                        }
                        AlertBottom(response.data.message);
                        CloseLoading();
                        this.hideModal2('modal_berita_acara_delete')
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        deleteData() {
            ShowLoading();
            const formData = new FormData();


            formData.append("id", this.temporaryId);
            formData.append("status_dosen", this.temporaryStatusDosen);
            formData.append("id_jadwal", this.id_jadwal_dipilih);
            formData.append('kehadiran_siswa', JSON.stringify(this.attendanceRecords));

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "berita_acara" + "_delete";
            } else {
                this.api = base_url + "berita_acara" + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.beritaAcaraRecords.findIndex((record) => record.id === this.temporaryId);
                        this.beritaAcaraRecords.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('modal_berita_acara_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        BeritaAcaraIndex(id, mata_kuliah) {
            this.beritaAcaraRecords = [];
            ShowLoading();
            let formData = new FormData();

            formData.append("jadwal", id);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + '_index';
            } else {
                this.api = base_url + this.url2 + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.id_dosen_dipilih = response.data.id_dosen;
                        this.id_profiles_dipilih = response.data.id_profile;

                        if (response.data.data != undefined) {
                            this.beritaAcaraRecords = response.data.data[0];

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
            this.showModal('modal_list_berita_acara');

            this.mata_kuliah_dipilih = mata_kuliah;
            this.id_jadwal_dipilih = id;
        },
        beritaAcaraGet(id, status, id_jadwal) {
            this.mahasiswaRecords = [];
            ShowLoading();
            let formData = new FormData();

            formData.append("status", status);
            formData.append("id", id);
            formData.append("jadwal", id_jadwal);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + '_get';
            } else {
                this.api = base_url + this.url2 + '_get';
            }

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        if (response.data.data != undefined) {
                            this.currentRecordBeritaAcara = response.data.data[1][0];
                            this.mahasiswaRecords = response.data.data[2];
                            this.penggantiList = response.data.data[3];


                            if (this.currentRecordBeritaAcara.status_dosen == 'HADIR' || this.currentRecordBeritaAcara.status_dosen == undefined) {
                                this.currentRecordBeritaAcara.status_dosen = false
                            } else {
                                this.currentRecordBeritaAcara.status_dosen = true
                            }

                            if (status == 'without_id') {
                                this.currentRecordBeritaAcara.tanggal = DateNow();
                                this.currentRecordBeritaAcara.waktu_mulai = HourNow();
                                this.currentRecordBeritaAcara.waktu_selesai = HourNow();
                            }

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.hideModal2('modal_berita_acara_tambah');
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
            this.showModal('modal_list_berita_acara');

        },

        updateAttendance(id, value) {
            const index = this.mahasiswaRecords.findIndex(
                (record) => record.id == id
            );
            this.mahasiswaRecords[index].status_kehadiran = value;
        },

        beritaAcaraUpdate() {

            this.attendanceRecords = delete this.mahasiswaRecords.nama_lengkap;
            this.attendanceRecords = delete this.mahasiswaRecords.nim_mahasiswa;
            this.attendanceRecords = this.mahasiswaRecords.map(employee => {
                const { ...record } = employee;
                delete record.nama_lengkap;
                delete record.nim_mahasiswa;
                return record;

            });

            ShowLoading();
            let formData = new FormData();
            this.currentRecordBeritaAcara.waktu_mulai;
            this.currentRecordBeritaAcara.waktu_selesai;

            if (this.currentRecordBeritaAcara.status_dosen == false) {
                this.currentRecordBeritaAcara.status_dosen = 'HADIR'
            } else {
                this.currentRecordBeritaAcara.status_dosen = 'TIDAK'
            }
            if (this.setujui == 'YA') {
                formData.append('persetujuan_tidak_hadir', this.setujui);
                delete this.currentRecordBeritaAcara.persetujuan_tidak_hadir;
            }

            Object.entries(this.currentRecordBeritaAcara).forEach(([key, value]) => {
                formData.append(key, value);
            });
            formData.append('finalisasi', this.finalisasi);


            formData.append('kehadiran_siswa', JSON.stringify(this.attendanceRecords));
            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + '_update';
            } else {
                this.api = base_url + this.url2 + '_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            const index = this.beritaAcaraRecords.findIndex(
                                (record) => record.id == response.data.data.id
                            );

                            this.currentRecordBeritaAcara = {}
                            this.beritaAcaraRecords.splice(index, 1, response.data.data);

                        }
                        this.hideModal2('modal_berita_acara_edit');
                        AlertBottom(response.data.message);
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                        if (this.currentRecordBeritaAcara.status_dosen == 'HADIR') {
                            this.currentRecordBeritaAcara.status_dosen = false
                        } else {
                            this.currentRecordBeritaAcara.status_dosen = true
                        }
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
            this.showModal('modal_list_berita_acara');

        },
        beritaAcaraInsert() {
            this.attendanceRecords = delete this.mahasiswaRecords.nama_lengkap;
            this.attendanceRecords = delete this.mahasiswaRecords.nim_mahasiswa;
            this.attendanceRecords = this.mahasiswaRecords.map(employee => {
                const { ...record } = employee;
                delete record.nama_lengkap;
                delete record.nim_mahasiswa;
                return record;

            });

            ShowLoading();
            let formData = new FormData();

            this.currentRecordBeritaAcara.jadwal = this.id_jadwal_dipilih;


            if (this.currentRecordBeritaAcara.status_dosen == false) {
                this.currentRecordBeritaAcara.status_dosen = 'HADIR'
            } else {
                this.currentRecordBeritaAcara.status_dosen = 'TIDAK'
            }

            Object.entries(this.currentRecordBeritaAcara).forEach(([key, value]) => {
                formData.append(key, value);
            });
            formData.append('kehadiran_siswa', JSON.stringify(this.attendanceRecords));
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url2 + '_insert';
            } else {
                this.api = base_url + this.url2 + '_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.beritaAcaraRecords.unshift(response.data.data);
                        }
                        this.hideModal2('modal_berita_acara_tambah');
                        AlertBottom(response.data.message);
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                        if (this.currentRecordBeritaAcara.status_dosen == 'HADIR') {
                            this.currentRecordBeritaAcara.status_dosen = false
                        } else {
                            this.currentRecordBeritaAcara.status_dosen = true
                        }
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);

                });
            this.showModal('modal_list_berita_acara');

        },

        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },

        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')

            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        deleteRecord(id, status_dosen) {

            this.temporaryId = id;
            this.temporaryStatusDosen = status_dosen;
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.page, 'create');
        this.update_akses = menu_akses_cek(this.page, 'update');
        this.delete_akses = menu_akses_cek(this.page, 'delete');

        this.index();
        this.hariJadwalList = hariList;
        this.tipe = localStorage.getItem('tipe_user');

    }



}
</script>