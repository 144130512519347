<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar "
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }"
        style="width: 99%!important;">

        <div class=" mt-1 ps-2 ">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                Laporan
            </div>
            <div class="">
                <div class="fw-bold py-3 ps-2">
                    A. Laporan
                </div>
                <div class="row gap-2 ms-1 mt-2">
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="goPage('berita_acara_dosen_tidak_hadir')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan Dosen Tidak Hadir
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_generate_krs')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan SKS Dosen
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_detail_kehadiran_dosen')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan Detail Kehadiran Dosen
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_rekap_kehadiran_dosen')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan Rekap Kehadiran Dosen
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_kehadiran_mahasiswa')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan Kehadiran Mahasiswa
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_nilai_mahasiswa')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan Nilai Mahasiswa
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_nilai_mahasiswa_perkelas')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan Nilai Mahasiswa Perkelas
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_nilai_remedial_mahasiswa')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Nilai Remedial Mahasiswa
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_nilai_semester_pendek_mahasiswa')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Nilai Semester Pendek Mahasiswa
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_nilai_susulan_uts_uas_mahasiswa')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Nilai Susulan Mahasiswa
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_ipk_mahasiswa')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan Hasil Studi Mahasiswa
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_finalisasi_nilai')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan Finalisasi Nilai
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_mahasiswa_perkelas')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan mahasiswa perkelas
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_prestasi_mahasiswa')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center ">
                            Laporan prestasi mahasiswa
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_status_mahasiswa'); getLaporanStatusMahasiswa();">

                        <div class="icon-card">
                            <i class='bx bx-file fs-2'></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Status Mahasiswa
                        </div>
                    </div>

                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_mahasiswa_baru'); getLaporanMahasiswaBaru();">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2'></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Mahasiswa Baru
                        </div>
                    </div>

                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_mahasiswa_lulus_do'); getLaporanMahasiswaLulusDo();">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2'></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Mahasiswa Lulus & DO
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_mahasiswa_ukt'); getLaporanMahasiswaUKT();">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2'></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Mahasiswa UKT
                        </div>
                    </div>
                </div>
                <div class="fw-bold py-3 ps-2">
                    B. Laporan Neo Feeder
                </div>
                <div class="row gap-2 ms-1 mt-2 mb-5">
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_mahasiswa_neo_feeder')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Mahasiswa
                        </div>
                    </div>

                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_kelas_neo_feeder')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Kelas
                        </div>
                    </div>

                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_akm_neo_feeder')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan AKM
                        </div>
                    </div>

                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_krs_neo_feeder')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan KRS
                        </div>
                    </div>


                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_prestasi_mahasiswa_neo_feeder')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Prestasi Mahasiswa
                        </div>
                    </div>
                    <div class="card px-3 py-3 d-flex justify-content-center align-items-center laporan-card "
                        style="cursor: pointer; min-width: 200px!important; max-width: 200px!important;"
                        @click="showModal2('modal_laporan_nilai_mahasiswa_neo_feeder')">
                        <div class="icon-card">
                            <i class='bx bx-file fs-2 '></i>
                        </div>
                        <div class="mt-3 fs-6 text-center">
                            Laporan Nilai Mahasiswa
                        </div>
                    </div>


                </div>
            </div>
        </div>


    </div>

    <div class="modal fade" id="modal_generate_krs" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_generate_krs">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan SKS dosen</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_generate_krs')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tanggal_mulai" class="form-label">Tanggal Mulai</label>
                        <input type="date" class="form-control" v-model="tanggal_mulai" required />
                    </div>
                    <div class="mb-3">
                        <label for="tanggal_selesai" class="form-label">Tanggal Selesai</label>
                        <input type="date" class="form-control" v-model="tanggal_selesai" required />
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_generate_krs')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelSKSDosen(tanggal_mulai, tanggal_selesai, 'generate_laporan_krs_dosen', 'Laporan SKS Dosen', config)">
                        Generate </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_laporan_detail_kehadiran_dosen" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_detail_kehadiran_dosen">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Detail Kehadiran dosen
                    </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_detail_kehadiran_dosen')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tanggal_mulai" class="form-label">Tanggal Mulai</label>
                        <input type="date" class="form-control" v-model="tanggal_mulai" required />

                    </div>
                    <div class="mb-3">
                        <label for="tanggal_selesai" class="form-label">Tanggal Selesai</label>
                        <input type="date" class="form-control" v-model="tanggal_selesai" required />
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_detail_kehadiran_dosen')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcel(tanggal_mulai, tanggal_selesai, 'generate_laporan_detail_kehadiran_dosen', 'Laporan Detail Kehadiran Dosen ', config)">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_rekap_kehadiran_dosen" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_rekap_kehadiran_dosen">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Rekap Kehadiran Dosen</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_rekap_kehadiran_dosen')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tanggal_mulai" class="form-label">Tanggal Mulai</label>
                        <input type="date" class="form-control" v-model="tanggal_mulai" required />

                    </div>
                    <div class="mb-3">
                        <label for="tanggal_selesai" class="form-label">Tanggal Selesai</label>
                        <input type="date" class="form-control" v-model="tanggal_selesai" required />
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_rekap_kehadiran_dosen')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcel(tanggal_mulai, tanggal_selesai, 'generate_laporan_rekap_kehadiran_dosen', 'Laporan Rekap Kehadiran Dosen ', config)">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_kehadiran_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_kehadiran_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Kehadiran Mahasiswa</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_kehadiran_mahasiswa')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="prodi" class="form-label">Prodi</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required
                            :disabled="prodiFilter == '' || tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="kelas" class="form-label">Kelas</label>
                        <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                            v-model="kelasFilter" @change="index()" required :disabled="prodiFilter == ''">
                            <option value="" selected disabled>- Pilih kelas</option>
                            <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                {{ list.nama }} ({{ list.waktu_kuliah }})
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_kehadiran_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelKehadiranMahasiswa()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_nilai_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_nilai_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Nilai Mahasiswa</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_nilai_mahasiswa')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="prodi" class="form-label">Prodi</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required
                            :disabled="prodiFilter == '' || tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="mb-3">
                        <label for="kelas" class="form-label">Kelas</label>
                        <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                            v-model="kelasFilter" @change="index()" required :disabled="prodiFilter == ''">
                            <option value="" selected disabled>- Pilih kelas</option>
                            <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                {{ list.nama }} ({{ list.waktu_kuliah }})
                            </option>
                        </select>
                    </div> -->
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_nilai_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light" @click="generateExcelNilaiMahasiswa()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_nilai_mahasiswa_perkelas" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_nilai_mahasiswa_perkelas">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Nilai Mahasiswa Perkelas
                    </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_nilai_mahasiswa_perkelas')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="prodi" class="form-label">Prodi</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required
                            :disabled="prodiFilter == '' || tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="kelas" class="form-label">Kelas</label>
                        <select class="form-select" aria-label="Default select example" name="kelas" id="kelas"
                            v-model="kelasFilter" @change="index()" required :disabled="prodiFilter == ''">
                            <option value="" selected disabled>- Pilih kelas</option>
                            <option v-for="list in kelasList" :key="list.nama" :value="list.id">
                                {{ list.nama }} ({{ list.waktu_kuliah }})
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_nilai_mahasiswa_perkelas')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelNilaiMahasiswaPerkelas()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_nilai_remedial_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_nilai_remedial_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Nilai Remedial Mahasiswa
                    </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_nilai_remedial_mahasiswa')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>

                    <!-- <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required :disabled="tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div> -->

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_nilai_remedial_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelNilaiRemedialMahasiswa()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>
    <div class="modal fade" id="modal_laporan_nilai_semester_pendek_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true"
        ref="modal_laporan_nilai_semester_pendek_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Nilai Semester Pendek
                        Mahasiswa
                    </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_nilai_semester_pendek_mahasiswa')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>

                    <!-- <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required :disabled="tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div> -->

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_nilai_semester_pendek_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelNilaiSemesterPendekMahasiswa()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>
    <div class="modal fade" id="modal_laporan_nilai_susulan_uts_uas_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true"
        ref="modal_laporan_nilai_susulan_uts_uas_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Nilai Susulan UTS & UAS
                        Mahasiswa
                    </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_nilai_susulan_uts_uas_mahasiswa')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required :disabled="tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_nilai_susulan_uts_uas_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelNilaiSusulanUtsUasMahasiswa()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>
    <div class="modal fade" id="modal_laporan_ipk_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_ipk_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Hasil Studi Mahasiswa
                    </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_ipk_mahasiswa')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required :disabled="tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_ipk_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light" @click="generateIpkMahasiswa()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>
    <div class="modal fade" id="modal_laporan_finalisasi_nilai" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_finalisasi_nilai">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Finalisasi Nilai
                    </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_finalisasi_nilai')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="semester" class="form-label">Semester</label>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index()" required :disabled="tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_finalisasi_nilai')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateLaporanFinalsiasiNilai()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_mahasiswa_neo_feeder" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_mahasiswa_neo_feeder">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Mahasiswa Neo Feeder</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_mahasiswa_neo_feeder')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Stambuk</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama.substring(0, 4) }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_mahasiswa_neo_feeder')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelMahasiswaNeoFeeder()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>


    <div class="modal fade" id="modal_laporan_kelas_neo_feeder" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_kelas_neo_feeder">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Kelas Neo Feeder</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_kelas_neo_feeder')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_kelas_neo_feeder')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light" @click="generateExcelKelasNeoFeeder()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_akm_neo_feeder" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_akm_neo_feeder">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan AKM Neo Feeder</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_akm_neo_feeder')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_kelas_neo_feeder')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light" @click="generateExcelAKMNeoFeeder()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_krs_neo_feeder" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_krs_neo_feeder">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan KRS Neo Feeder</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_krs_neo_feeder')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_krs_neo_feeder')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light" @click="generateExcelNeoFeeder('krs')">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>
    <div class="modal fade" id="modal_laporan_prestasi_mahasiswa_neo_feeder" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true"
        ref="modal_laporan_prestasi_mahasiswa_neo_feeder">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Prestasi Mahasiswa Neo
                        Feeder
                    </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_prestasi_mahasiswa_neo_feeder')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Stambuk</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama.substring(0, 4) }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_prestasi_mahasiswa_neo_feeder')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelNeoFeeder('prestasi_mahasiswa')">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>
    <div class="modal fade" id="modal_laporan_nilai_mahasiswa_neo_feeder" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_nilai_mahasiswa_neo_feeder">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Nilai Mahasiswa Neo Feeder
                    </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_nilai_mahasiswa_neo_feeder')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_nilai_mahasiswa_neo_feeder')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateNilaiMahasiswaNeoFeeder()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_mahasiswa_perkelas" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_mahasiswa_perkelas">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Mahasiswa perkelas</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_mahasiswa_perkelas')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Tahun Ajaran</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_mahasiswa_perkelas')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelMahasiswaPerkelas()">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>
    <div class="modal fade" id="modal_laporan_prestasi_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_prestasi_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Laporan Prestasi Mahasiswa </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_prestasi_mahasiswa')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="tahun_ajaran" class="form-label">Stambuk</label>
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()" required>
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_prestasi_mahasiswa')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light"
                        @click="generateExcelPrestasiMahasiswa">
                        Generate </button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_status_mahasiswa" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_status_mahasiswa">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Laporan Status Mahasiswa </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_status_mahasiswa')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex gap-2 mb-3">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index(); getLaporanStatusMahasiswa();" required>
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index(); getLaporanStatusMahasiswa();" required
                            :disabled="tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>


                    <div class="border mt-1 rounded-3 mb-5 me-2 font-custom-1">
                        <table class="table mb-0 bg-transparent table-borderless " style="">
                            <thead>
                                <tr>
                                    <th scope="col" class="bg-transparent fs-7">No</th>
                                    <th scope="col" class="font-custom-1 fs-7">Prodi</th>
                                    <th scope="col" class="font-custom-1 fs-7">Aktif</th>
                                    <th scope="col" class="font-custom-1 fs-7">Cuti</th>
                                    <th scope="col" class="font-custom-1 fs-7">Kampus Merdeka</th>
                                    <th scope="col" class="font-custom-1 fs-7">Non aktif</th>
                                    <th scope="col" class="font-custom-1 fs-7">Total</th>
                                    <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(record, index) in recordsStatusMahasiswa" :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.prodi }}</td>
                                    <td>{{ record.aktif }}</td>
                                    <td>{{ record.cuti }}</td>
                                    <td>{{ record.kampus_merdeka }}</td>
                                    <td>{{ record.non_aktif }}</td>
                                    <td>{{ record.total }}</td>
                                    <td class="bg-transparent">
                                        <div class="d-flex gap-2">
                                            <button class="btn btn-success btn-sm fs-8"
                                                v-on:click="generateExcelLaporanStatusMahasiswa(record.id_prodi, record.id_tahun_ajaran, record.id_semester)"><i
                                                    class='bx bxs-file-export fs-8'></i></button>

                                        </div>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_status_mahasiswa')">Tutup</button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_mahasiswa_baru" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_mahasiswa_baru">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Laporan Mahasiswa Baru</h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_mahasiswa_baru')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex gap-2 mb-3">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index(); getLaporanMahasiswaBaru();" required>
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                        <!-- <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index(); getLaporanMahasiswaBaru();" required
                            :disabled="tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select> -->
                    </div>


                    <div class="border mt-1 rounded-3 mb-5 me-2 font-custom-1">
                        <table class="table mb-0 bg-transparent table-borderless " style="">
                            <thead>
                                <tr>
                                    <th scope="col" class="bg-transparent fs-7">No</th>
                                    <th scope="col" class="font-custom-1 fs-7">Program Studi</th>
                                    <th scope="col" class="font-custom-1 fs-7">Mahasiswa Baru</th>
                                    <th scope="col" class="font-custom-1 fs-7">Mahasiswa Pindahan/Lanjutan</th>
                                    <th scope="col" class="font-custom-1 fs-7">Total</th>
                                    <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(record, index) in recordsMahasiswaBaru" :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.prodi }}</td>
                                    <td>{{ record.mahasiswa_baru }}</td>
                                    <td>{{ record.mahasiswa_lanjutan }}</td>
                                    <td>{{ record.total }}</td>
                                    <td class="bg-transparent">

                                        <div class="d-flex gap-2">
                                            <button class="btn btn-success btn-sm fs-8"
                                                v-on:click="generateExcelLaporanMahasiswaBaru(record.id_prodi, tahunAjaranFilter, record.id_semester)"><i
                                                    class='bx bxs-file-export fs-8'></i></button>

                                        </div>



                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_mahasiswa_baru')">Tutup</button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_mahasiswa_lulus_do" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_mahasiswa_lulus_do">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Laporan Mahasiswa Lulus & DO </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_mahasiswa_lulus_do')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex gap-2 mb-3">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index(); getLaporanMahasiswaLulusDo();" required>
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index(); getLaporanMahasiswaLulusDo();" required
                            :disabled="tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>

                    </div>


                    <div class="border mt-1 rounded-3 mb-5 me-2 font-custom-1">
                        <table class="table mb-0 bg-transparent table-borderless " style="">
                            <thead>
                                <tr>
                                    <th scope="col" class="bg-transparent fs-7">No</th>
                                    <th scope="col" class="font-custom-1 fs-7">Program Studi</th>
                                    <th scope="col" class="font-custom-1 fs-7">Lulus</th>
                                    <th scope="col" class="font-custom-1 fs-7">Mutasi</th>
                                    <th scope="col" class="font-custom-1 fs-7">Dikeluarkan</th>
                                    <th scope="col" class="bg-transparent  fs-7">Mengundurkan Diri</th>
                                    <th scope="col" class="bg-transparent  fs-7">Wafat</th>
                                    <th scope="col" class="bg-transparent  fs-7">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(record, index) in recordsMahasiswaLulusDo" :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.prodi }}</td>
                                    <td>{{ record.lulus }}</td>
                                    <td>{{ record.mutasi }}</td>
                                    <td>{{ record.dikeluarkan }}</td>
                                    <td>{{ record.mengundurkan_diri }}</td>
                                    <td>{{ record.meninggal }}</td>
                                    <td>{{ record.total }}</td>
                                    <td class="bg-transparent">
                                        <div class="d-flex gap-2">
                                            <button class="btn btn-success btn-sm fs-8"
                                                v-on:click="generateExcelLaporanMahasiswaLulusDo(record.id_prodi, record.id_tahun_ajaran, record.id_semester)"><i
                                                    class='bx bxs-file-export fs-8'></i></button>

                                        </div>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_mahasiswa_lulus_do')">Tutup</button>
                </div>

            </div>

        </div>
    </div>

    <div class="modal fade" id="modal_laporan_mahasiswa_ukt" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_laporan_mahasiswa_ukt">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Laporan Mahasiswa UKT </h5>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_laporan_mahasiswa_ukt')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex gap-2 mb-3">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index(); getLaporanMahasiswaUKT();" required>
                            <option value="" selected disabled>- Pilih Stambuk</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                        <select class="form-select" aria-label="Default select example" name="semester" id="semester"
                            v-model="semesterFilter" @change="index(); getLaporanMahasiswaUKT();" required
                            :disabled="tahunAjaranFilter == ''">
                            <option value="" selected disabled>- Pilih Semester</option>
                            <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                        <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                            v-model="dosenFilter" @change="index(); getLaporanMahasiswaUKT();" required>
                            <option value="" selected disabled>- Pilih Dosen</option>
                            <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                        <select class="form-select" aria-label="Default select example" name="dosen" id="dosen"
                            v-model="dosenFilter" @change="index(); getLaporanMahasiswaUKT();" required>
                            <option value="" selected disabled>- Pilih Dosen</option>
                            <option v-for="list in dosenList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>

                    </div>


                    <div class="border mt-1 rounded-3 mb-5 me-2 font-custom-1">
                        <table class="table mb-0 bg-transparent table-borderless " style="">
                            <thead>
                                <tr>
                                    <th scope="col" class="bg-transparent fs-7">No</th>
                                    <th scope="col" class="font-custom-1 fs-7">Stambuk</th>
                                    <th scope="col" class="font-custom-1 fs-7">Semester</th>
                                    <th scope="col" class="font-custom-1 fs-7">Peminatan</th>
                                    <th scope="col" class="font-custom-1 fs-7">Kelas</th>
                                    <th scope="col" class="bg-transparent  fs-7">Sudah Pembayaran</th>
                                    <th scope="col" class="bg-transparent  fs-7">Belum Pembayaran</th>
                                    <th scope="col" class="bg-transparent  fs-7">Sudah Pengisian KRS</th>
                                    <th scope="col" class="bg-transparent  fs-7">Belum Pengisian KRS</th>
                                    <th scope="col" class="bg-transparent  fs-7">Jumlah KRS tidak disetujui</th>
                                    <th scope="col" class="bg-transparent  fs-7">Jumlah KRS sudah disetujui</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(record, index) in recordsMahasiswaUKT" :key="record.id" :ref="record.id"
                                    class="border-bottom ">
                                    <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                    <td>{{ record.stambuk }}</td>
                                    <td>{{ record.semester }}</td>
                                    <td>{{ record.peminatan }}</td>
                                    <td>{{ record.kelas }}</td>
                                    <td>{{ record.sudah_pembayaran }}</td>
                                    <td>{{ record.belum_pembayaran }}</td>
                                    <td>{{ record.sudah_pengisian_krs }}</td>
                                    <td>{{ record.belum_pengisian_krs }}</td>
                                    <td>{{ record.jumlah_krs_tidak_disetujui }}</td>
                                    <td>{{ record.jumlah_krs_sudah_disetujui }}</td>
                                    <td class="bg-transparent">
                                        <div class="d-flex gap-2">
                                            <!-- <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                                v-on:click="editRecord(record)" data-bs-toggle="modal"
                                                data-bs-target="#modal_edit"><i
                                                    class='bx bxs-message-square-edit fs-8'></i></button> -->

                                        </div>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal2('modal_laporan_mahasiswa_ukt')">Tutup</button>
                </div>

            </div>

        </div>
    </div>

    <button class="btn btn-success d-none" id="btn_laporan_invalid" @click="showModal2('laporan_invalid')"
        ref="btn_laporan_invalid" hidden>
        <i class="bx bxs-file-invalid"></i> Laporan Import Invalid
    </button>

    <div>
        <div class="modal fade" id="laporan_invalid" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel" aria-hidden="true" ref="laporan_invalid">
            <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header  bg-danger text-white">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Terdapat Nilai yang kosong atau tidak valid
                            <!-- <span class="text-capitalize"> Nilai</span> -->
                        </h5>
                        <button type="button" class="btn-close" @click="hideModal2('laporan_invalid')"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <table class="table fs-6">
                                <thead style="
                    position: sticky !important;
                    top: -20px !important;
                    background-color: white;
                  ">
                                    <tr>
                                        <th scope="col" style="width: 5%">No</th>
                                        <th scope="col">status</th>
                                        <th scope="col">Data</th>
                                    </tr>
                                </thead>
                                <tbody id="data_laporan">
                                    <tr v-for="(   laporanRecords, index   ) in laporanRecords   "
                                        :key="laporanRecords.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            <i v-if="laporanRecords.status === 'SUKSES'"
                                                class="bx bxs-check-square text-success"></i>
                                            <i v-if="laporanRecords.status === 'GAGAL'"
                                                class="bx bxs-x-square text-danger"></i>
                                        </td>
                                        <td class="text-danger">{{ laporanRecords.text }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="modal-footer ">
                                <!-- <button type="button" class="btn btn-secondary" id="btn_tutup_laporan_invalid"
                                    @click="hideModal2('laporan_invalid'); cekNilaiUASTUGAS('', false)">LANJUT
                                    SIMPAN</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

.card:hover .icon-card {
    color: #0D9044 !important;
}

.card:hover {
    background-color: rgb(245, 245, 245);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, CloseLoading, goPage, ShowLoading, menu_akses_cek, ErrorConnectionTimeOut, AlertBottom, CharAndNumberOnly, DateNow } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";
import XLSX from "xlsx/dist/xlsx.full.min.js";

export default {
    data() {
        return {
            //data
            url: 'laporan',
            api: "",

            //pages
            limit: "15",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,

            prodiFilter: "",
            tahunAjaranFilter: "",
            semesterFilter: "",
            kelasFilter: "",
            dosenFilter: "",

            prodiList: [],
            tahunAjaranList: [],
            semesterList: [],
            kelasList: [],
            dosenList: [],

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: { nama: "" },
            currentRecord: {},


            // status mahasiswa
            recordsStatusMahasiswa: [],
            currentRecordStatusMahasiswa: {},

            //mahasiswa baru 
            recordsMahasiswaBaru: [],
            currentRecordMahasiswaBaru: {},

            // mahasiswa lulus do 
            recordsMahasiswaLulusDo: [],
            currentRecordMahasiswaLulusDo: {},

            // mahasiswa pembayaran UKT 
            recordsMahasiswaUKT: [],
            currentRecordMahasiswaUKT: {},

            //nilai excel
            laporanRecords: [],
        }
    },
    components: {
        SideNavbar,
    },

    methods: {
        goPage,
        CharAndNumberOnly,
        generateExcelSKSDosen(
            tanggal_mulai,
            tanggal_selesai,
            url,
            nama_file,
            config
        ) {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tanggal_mulai", tanggal_mulai);
            formData.append("tanggal_selesai", tanggal_selesai);
            if (process.env.NODE_ENV === "development") {
                api = base_url + url;
            } else {
                api = base_url + url;
            }

            axios
                .post(api, formData, config)
                .then((response) => {

                    if (response.data.status == true) {

                        let data_sks = [];
                        let jumlah_sks = 0;
                        let jumlah_sks_kelebihan = 0;
                        response.data.data.forEach((item, index) => {
                            let item_prepare = {};
                            let lastIndex = 0;
                            if (response.data.data[index - 1] != undefined && response.data.data[index - 1].nip == item.nip) {
                                jumlah_sks = jumlah_sks + item.sks;
                                jumlah_sks_kelebihan = jumlah_sks_kelebihan + item.kelebihan_sks;
                                // console.log(item.sks);
                                // console.log(jumlah_sks);
                                // console.log('aaa')
                                lastIndex = response.data.data.findLastIndex(item2 => item2.nip == item.nip);
                            } else {
                                jumlah_sks = item.sks;
                                jumlah_sks_kelebihan = item.kelebihan_sks;
                                // console.log('bbbb')

                            }

                            if (lastIndex == index && index != 0) {
                                item_prepare = { ...item, total_sks: jumlah_sks.toString(), total_kelebihan_sks: jumlah_sks_kelebihan.toString() };
                            } else {
                                item_prepare = { ...item, total_sks: '', total_kelebihan_sks: '' };
                            }


                            data_sks.push(item_prepare);
                        });

                        // console.log(data_sks);

                        data_sks = this.addNumberToObjects(data_sks);
                        for (var i = 0; i < data_sks.length; i++) {
                            delete data_sks[i]["id"];
                            delete data_sks[i]["created_at"];
                            delete data_sks[i]["updated_at"];
                        }
                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(data_sks);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, nama_file);
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, nama_file + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        addNumberToObjects(objArray) {
            for (let i = 0; i < objArray.length; i++) {
                const newObj = { No: i + 1, ...objArray[i] };
                objArray[i] = newObj;
            }
            return objArray;
        },
        //data
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';

            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')

            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        getLaporanStatusMahasiswa() {
            ShowLoading();

            if (this.tahunAjaranFilter == '' || this.semesterFilter == '') {
                CloseLoading();
                return true;
            }

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);

            api = base_url + 'generate_laporan_status_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.recordsStatusMahasiswa = response.data.data;
                        CloseLoading();
                        // response.data.data = this.addNumberToObjects(response.data.data);
                        // for (var i = 0; i < response.data.data.length; i++) {
                        //     delete response.data.data[i]["id"];
                        //     delete response.data.data[i]["created_at"];
                        //     delete response.data.data[i]["updated_at"];
                        // }

                        // // Create a new workbook
                        // const wb = XLSX.utils.book_new();
                        // // Create a new worksheet
                        // const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // // Add the worksheet to the workbook
                        // XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // // Convert the workbook to an XLSX file and download it
                        // XLSX.writeFile(wb, 'Laporan Nilai Mahasiswa' + ".xlsx");

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        getLaporanMahasiswaBaru() {

            ShowLoading();

            let api = "";
            let formData = new FormData();

            if (this.tahunAjaranFilter == '') {
                CloseLoading();
                return true;
            }

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            // formData.append("semester", this.semesterFilter);

            api = base_url + 'generate_laporan_mahasiswa_baru';

            axios
                .post(api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.recordsMahasiswaBaru = response.data.data;
                        // response.data.data = this.addNumberToObjects(response.data.data);
                        // for (var i = 0; i < response.data.data.length; i++) {
                        //     delete response.data.data[i]["id"];
                        //     delete response.data.data[i]["created_at"];
                        //     delete response.data.data[i]["updated_at"];
                        // }

                        // // Create a new workbook
                        // const wb = XLSX.utils.book_new();
                        // // Create a new worksheet
                        // const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // // Add the worksheet to the workbook
                        // XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // // Convert the workbook to an XLSX file and download it
                        // XLSX.writeFile(wb, 'Laporan Nilai Mahasiswa' + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        getLaporanMahasiswaLulusDo() {
            ShowLoading();

            if (this.tahunAjaranFilter == '' || this.semesterFilter == '') {
                CloseLoading();
                return true;
            }

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);

            api = base_url + 'generate_laporan_mahasiswa_lulus_do';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.recordsMahasiswaLulusDo = response.data.data;
                        CloseLoading();
                        // response.data.data = this.addNumberToObjects(response.data.data);
                        // for (var i = 0; i < response.data.data.length; i++) {
                        //     delete response.data.data[i]["id"];
                        //     delete response.data.data[i]["created_at"];
                        //     delete response.data.data[i]["updated_at"];
                        // }

                        // // Create a new workbook
                        // const wb = XLSX.utils.book_new();
                        // // Create a new worksheet
                        // const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // // Add the worksheet to the workbook
                        // XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // // Convert the workbook to an XLSX file and download it
                        // XLSX.writeFile(wb, 'Laporan Nilai Mahasiswa' + ".xlsx");

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        getLaporanMahasiswaUKT() {
            ShowLoading();

            if (this.tahunAjaranFilter == '' || this.semesterFilter == '' || this.dosenFilter == '') {
                CloseLoading();
                return true;
            }

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);

            api = base_url + 'generate_laporan_mahasiswa_UKT';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.recordsMahasiswaUKT = response.data.data;
                        CloseLoading();
                        // response.data.data = this.addNumberToObjects(response.data.data);
                        // for (var i = 0; i < response.data.data.length; i++) {
                        //     delete response.data.data[i]["id"];
                        //     delete response.data.data[i]["created_at"];
                        //     delete response.data.data[i]["updated_at"];
                        // }

                        // // Create a new workbook
                        // const wb = XLSX.utils.book_new();
                        // // Create a new worksheet
                        // const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // // Add the worksheet to the workbook
                        // XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // // Convert the workbook to an XLSX file and download it
                        // XLSX.writeFile(wb, 'Laporan Nilai Mahasiswa' + ".xlsx");

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        generateExcelLaporanStatusMahasiswa(
            id_prodi,
            id_tahun_ajaran,
            id_semester
        ) {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("id_prodi", id_prodi);
            formData.append("tahun_ajaran", id_tahun_ajaran);
            formData.append("semester", id_semester);

            if (process.env.NODE_ENV === "development") {
                api = base_url + 'generate_laporan_status_mahasiswa_excel';
            } else {
                api = base_url + 'generate_laporan_status_mahasiswa_excel';
            }

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Status Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, "Laporan Status Mahasiswa.xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelLaporanMahasiswaBaru(
            id_prodi,
            id_tahun_ajaran,
            id_semester
        ) {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("id_prodi", id_prodi);
            formData.append("tahun_ajaran", id_tahun_ajaran);
            formData.append("semester", id_semester);



            if (process.env.NODE_ENV === "development") {
                api = base_url + 'generate_laporan_mahasiswa_baru_excel';
            } else {
                api = base_url + 'generate_laporan_mahasiswa_baru_excel';
            }

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Mahasiswa Lanjutan & Baru');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, "Laporan Mahasiswa Lanjutan & Baru.xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelLaporanMahasiswaLulusDo(
            id_prodi,
            id_tahun_ajaran,
            id_semester
        ) {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("id_prodi", id_prodi);
            formData.append("tahun_ajaran", id_tahun_ajaran);
            formData.append("semester", id_semester);

            if (process.env.NODE_ENV === "development") {
                api = base_url + 'generate_laporan_mahasiswa_lulus_do_excel';
            } else {
                api = base_url + 'generate_laporan_mahasiswa_lulus_do_excel';
            }

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Mahasiswa Lanjutan & Baru');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, "Laporan Mahasiswa Lanjutan & Baru.xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcel(
            tanggal_mulai,
            tanggal_selesai,
            url,
            nama_file,
            config
        ) {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tanggal_mulai", tanggal_mulai);
            formData.append("tanggal_selesai", tanggal_selesai);

            if (process.env.NODE_ENV === "development") {
                api = base_url + url;
            } else {
                api = base_url + url;
            }

            axios
                .post(api, formData, config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                            delete response.data.data[i]["minggu_sistem"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, nama_file);
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, nama_file + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelKehadiranMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("kelas", this.kelasFilter);

            api = base_url + 'generate_laporan_kehadiran_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Kehadiran Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Kehadiran Mahasiswa' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelNilaiMahasiswa() {
            this.laporanRecords = [];
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("semester", this.semesterFilter);
            // formData.append("kelas", this.kelasFilter);

            api = base_url + 'generate_laporan_nilai_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Nilai Mahasiswa' + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        if (response.data.data != undefined) {

                            Object.entries(response.data.data).forEach(([key, value]) => {
                                key;
                                const item_prepare = { status: 'GAGAL', text: '(' + value.mata_kuliah + ') dengan dosen pengajar (' + value.nama_dosen + ') dengan kelas (' + value.nama_kelas + ')' + '<br> Ket : ' + value.status_finalisasi }
                                this.laporanRecords.push(item_prepare);
                            });
                            this.$refs.btn_laporan_invalid.click();

                        }

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelNilaiMahasiswaPerkelas() {
            this.laporanRecords = [];
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("prodi", this.prodiFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("kelas", this.kelasFilter);

            api = base_url + 'generate_laporan_nilai_mahasiswa_perkelas';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Nilai Mahasiswa' + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        if (response.data.data != undefined) {

                            Object.entries(response.data.data).forEach(([key, value]) => {
                                key;
                                const item_prepare = { status: 'GAGAL', text: '(' + value.mata_kuliah + ') dengan dosen pengajar (' + value.nama_dosen + ') dengan kelas (' + value.nama_kelas + ')' + '<br> Ket : ' + value.status_finalisasi }
                                this.laporanRecords.push(item_prepare);
                            });
                            this.$refs.btn_laporan_invalid.click();

                        }

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelNilaiRemedialMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);

            api = base_url + 'generate_laporan_nilai_remedial_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Nilai Remedial Mahasiswa' + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelNilaiSemesterPendekMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);

            api = base_url + 'generate_laporan_nilai_semester_pendek_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }
                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Nilai Semester Pendek Mahasiswa' + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelNilaiSusulanUtsUasMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);

            api = base_url + 'generate_laporan_nilai_susulan_uts_uas_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Nilai Susulan UTS dan UAS Mahasiswa' + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        generateExcelMahasiswaNeoFeeder() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);

            api = base_url + 'generate_laporan_mahasiswa_neo_feeder';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Mahasiswa Neo Feeder');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Mahasiswa Neo Feeder' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },


        generateExcelKelasNeoFeeder() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);

            api = base_url + 'generate_laporan_kelas_neo_feeder';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Kelas Neo Feeder');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Kelas Neo Feeder' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelNeoFeeder(laporan) {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);

            api = base_url + 'generate_laporan_' + laporan + '_neo_feeder';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan ' + laporan);
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan ' + laporan + ' Neo Feeder' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateNilaiMahasiswaNeoFeeder() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);

            api = base_url + 'generate_laporan_nilai_mahasiswa_neo_feeder';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    // console.log(response.data);
                    if (response.data.status == true) {

                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Nilai Neo Feeder');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Nilai Neo Feeder' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateIpkMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);

            api = base_url + 'generate_laporan_ipk_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Rekap Hasil Studi');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Rekap Hasil Studi Mahasiswa ' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateLaporanFinalsiasiNilai() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);

            api = base_url + 'generate_laporan_finalisasi_nilai';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Finalisasi Nilai');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Finalisasi Nilai Mahasiswa ' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },


        generateExcelMahasiswaPerkelas() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);

            api = base_url + 'generate_laporan_mahasiswa_perkelas';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Mahasiswa perkelas');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Mahasiswa perkelas' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generateExcelPrestasiMahasiswa() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);

            api = base_url + 'generate_laporan_prestasi_mahasiswa';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan Prestasi Mahasiswa');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan Prestasi Mahasiswa' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        generateExcelAKMNeoFeeder() {
            ShowLoading();

            let api = "";
            let formData = new FormData();

            formData.append("tahun_ajaran", this.tahunAjaranFilter);

            api = base_url + 'generate_laporan_akm_neo_feeder';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        response.data.data = this.addNumberToObjects(response.data.data);
                        for (var i = 0; i < response.data.data.length; i++) {
                            delete response.data.data[i]["id"];
                            delete response.data.data[i]["created_at"];
                            delete response.data.data[i]["updated_at"];
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Laporan AKM Neo Feeder');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Laporan AKM Neo Feeder' + ".xlsx");
                        CloseLoading();

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index() {
            this.totalPages = 0;
            this.prodiList = [];
            this.tahunAjaranList = [];
            this.semesterList = [];
            this.kelasList = [];

            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("prodi", this.prodiFilter);
            formData.append("tahun_ajaran", this.tahunAjaranFilter);
            formData.append("semester", this.semesterFilter);
            formData.append("kelas", this.kelasFilter);


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (localStorage.getItem('tipe_user') == 'dosen') {
                        for (let i = 0; i < response.data.prodi_akses.length; i++) {
                            const index = response.data.prodi.findIndex((record) => record.id === response.data.prodi_akses[i].id);
                            this.prodiList.push(response.data.prodi[index]);
                        }
                    } else {
                        this.prodiList = response.data.prodi;
                    }
                    // this.jumlah_sks = response.data.jumlah_sks;
                    if (response.data.status == true) {

                        this.tahunAjaranList = response.data.tahun_ajaran;
                        this.kelasList = response.data.kelas;
                        this.semesterList = response.data.semester;
                        this.dosenList = response.data.dosen;

                        if (response.data.data != undefined) {
                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1][0].id;

                            let last_index = Object.keys(response.data.data[1]).length - 1;
                            this.NextLastId = response.data.data[1][last_index].id;
                        } else {
                            this.records = [];
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {

                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {
            console.log(param2);

            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;
                        this.arrayLastIdOld.push(param2);
                        this.arrayPreviousIdOld.push(param1);

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[0]).pop();

                        this.NextLastId = response.data.data[1][last_index].id; // id 33
                        this.previousLastId = response.data.data[1][0].id; // 7
                        this.numberPage = this.numberPage + 1;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {

            let last_index = this.arrayLastIdOld.length;
            last_index = last_index - 1;
            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.arrayPreviousIdOld[last_index]);
            formData.append("last_id", "");
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[1]).pop();
                        this.NextLastId = response.data.data[1][last_index].id;
                        this.previousLastId = response.data.data[1][0].id;

                        if (this.arrayLastIdOld.length != 1) {
                            this.arrayLastIdOld.pop();
                            this.arrayPreviousIdOld.pop();
                            this.numberPage = this.numberPage - 1;
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }


            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status == true) {


                        this.records.unshift(response.data.data);



                        // Clear the form inputs
                        this.newRecord = {

                        };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_tambah', 'close_modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal('modal_edit');
        },
        update() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}
                        // this.$set(this.records, index, response.data.data);
                        this.records.splice(index, 1, response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            console.log(this.temporaryId)
            formData.append("url", "ra_rekomendasi_delete");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },


        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            console.log(this.temporaryId)
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        this.tanggal_mulai = DateNow();
        this.tanggal_selesai = DateNow();

        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();

    }



}
</script>