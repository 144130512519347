<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                Pegawai
            </div>
            <div class="d-flex flex-column gap-2 mt-2">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->

                <div class="row me-0">

                    <div class="col-6 text-center">
                        <div class="d-flex justify-content-center  align-items-center">
                            <div>
                                <i class='bx bx-chevron-left fs-7' @click="index_before_page()"
                                    style="cursor: pointer;"></i>
                            </div>
                            <div class="fs-7">
                                {{ numberPage }}/{{ totalPages }}
                            </div>
                            <div>
                                <i class='bx bx-chevron-right fs-7' @click="index_next_page(previousLastId, NextLastId)"
                                    style="cursor: pointer;"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 text-end ">
                        <button v-if="create_akses == true"
                            class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            data-bs-toggle="modal" data-bs-target="#modal_tambah" @click="showModal('modal_tambah')">
                            <img src="@/assets/icon/user_plus.png" alt="" width="15">
                            Tambah Pegawai
                        </button>

                    </div>

                </div>


                <div class="border mt-1 rounded-3 me-2 font-custom-1">
                    <table class="table mb-0 bg-transparent table-borderless " style="">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-transparent fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">NITK</th>
                                <th scope="col" class="font-custom-1 fs-7">NIP</th>
                                <th scope="col" class="font-custom-1 fs-7">Nama</th>

                                <!-- <th scope="col" class="font-custom-1 fs-7">Username</th> -->

                                <!-- <th scope="col" class="font-custom-1 fs-7">KTP</th> -->
                                <!-- <th scope="col" class="font-custom-1 fs-7">Email</th>
                                <th scope="col" class="font-custom-1 fs-7">whatsapp</th> -->
                                <th scope="col" class="font-custom-1 fs-7">Status</th>
                                <!-- <th scope="col" class="font-custom-1 fs-7">Bidang</th>
                                <th scope="col" class="font-custom-1 fs-7">Jabatan Struktural</th>
                                <th scope="col" class="font-custom-1 fs-7">Jabatan Fungsional</th> -->
                                <th scope="col" class="bg-transparent  fs-7">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <!-- <td>{{ record.username }}</td> -->
                                <td>{{ record.nidn }}</td>
                                <td>{{ record.nip }}</td>
                                <td>{{ record.nama }}</td>
                                <!-- <td>{{ record.email }}</td>
                                <td>{{ record.whatsapp }}</td> -->
                                <td>{{ record.status_pegawai }}</td>
                                <!-- <td>{{ record.bidang }}</td>

                                <td>{{ record.jabatan_struktural }}</td>
                                <td>{{ record.jabatan_fungsional }}</td> -->
                                <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <!-- <button class="btn btn-primary btn-sm fs-8" data-bs-toggle="modal"
                                            data-bs-target="#modal_penelitian_pegawai"
                                            v-on:click="get_penelitian_pegawai(record.id)">
                                            <i class='bx bxs-file-find fs-8'></i>
                                        </button> -->
                                        <button v-if="update_akses == true" class="btn btn-warning btn-sm fs-8"
                                            v-on:click="editRecord(record)" data-bs-toggle="modal"
                                            data-bs-target="#modal_edit"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <button v-if="delete_akses == true" class="btn btn-danger btn-sm fs-8"
                                            data-bs-toggle="modal" data-bs-target="#modal_delete"
                                            v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>
    <form @submit.prevent="insert()">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_tambah">

            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Tambah Data Pegawai</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_tambah', 'close_modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nip" class="form-label"> NIP</label>
                            <input type="text" class="form-control" v-model="newRecord.nip" maxlength="16"
                                @keypress="NumberOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="nidn" class="form-label"> NITK</label>
                            <input type="text" class="form-control" v-model="newRecord.nidn" maxlength="30"
                                @keypress="NumberOnly" required />
                        </div>

                        <div class="mb-3">
                            <label for="kode" class="form-label"> Nama Lengkap</label>
                            <input type="text" class="form-control" v-model="newRecord.nama" @keypress="CharOnly"
                                required />
                        </div>

                        <!-- <div class="mb-3 ">
                            <label for="jabatan" class="form-label"> Jabatan Struktural</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="newRecord.jabatan_struktural" required>
                                <option value="" selected disabled>- Pilih Jabatan Struktural</option>
                                <option v-for="list in jabatanStrukturalList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="jabatan" class="form-label"> Jabatan Fungsional</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="newRecord.jabatan_fungsional" required>
                                <option value="" selected disabled>- Pilih Jabatan Fungsional</option>
                                <option v-for="list in jabatanFungsionalList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                        <!-- <div class="mb-3">
                            <label for="golongan" class="form-label"> Golongan</label>
                            <select class="form-select" aria-label="Default select example" name="golongan" id="golongan"
                                v-model="newRecord.golongan" required>
                                <option value="" selected disabled>- Pilih Golongan</option>
                                <option v-for="list in golonganList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->

                        <!-- <div class="mb-3">
                            <label for="bidang" class="form-label"> Bidang</label>
                            <select class="form-select" aria-label="Default select example" name="bidang" id="bidang"
                                v-model="newRecord.bidang" required>
                                <option value="" selected disabled>- Pilih Bidang</option>
                                <option v-for="list in bidangList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->

                        <div class="mb-3">
                            <label for="status_pegawai" class="form-label"> Status Pegawai</label>
                            <select class="form-select" aria-label="Default select example" name="status_pegawai"
                                id="status_pegawai" v-model="newRecord.status_pegawai" required>
                                <option value="" selected disabled>- Pilih Status Pegawai</option>
                                <option v-for="list in statusDoseList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="tanggal_lahir" class="form-label">Tanggal Lahir</label>
                            <input type="date" class="form-control" v-model="newRecord.tanggal_lahir" required />
                        </div>

                        <!-- <div class="mb-3">
                            <label for="kode" class="form-label"> Username</label>
                            <input type="text" class="form-control" v-model="newRecord.username" required />
                        </div> -->
                        <!-- <div class="mb-3">
                            <label for="kode" class="form-label"> Password</label>
                            <input type="text" class="form-control" v-model="newRecord.password" required />
                        </div> -->

                        <div class="mb-3">
                            <label for="email" class="form-label"> Email</label>
                            <input type="text" class="form-control" v-model="newRecord.email" required />
                        </div>
                        <div class="mb-3">
                            <label for="whatsapp" class="form-label"> Whatsapp</label>
                            <input type="text" class="form-control" v-model="newRecord.whatsapp" @keypress="NumberOnly"
                                required />
                        </div>

                        <div class="mb-3">
                            <label for="jenis_kelamin" class="form-label"> Jenis Kelamin</label>
                            <select class="form-select" aria-label="Default select example" name="jenis_kelamin"
                                id="jenis_kelamin" v-model="newRecord.jenis_kelamin" required>
                                <option value="" selected disabled>- Pilih Jenis Kelamin</option>
                                <option v-for="list in jenisKelaminList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label"> Agama</label>
                            <select class="form-select" aria-label="Default select example" name="agama" id="agama"
                                v-model="newRecord.agama" required>
                                <option value="" selected disabled>- Pilih Agama</option>
                                <option v-for="list in agamaList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Alamat</label>
                            <textarea class="form-control" v-model="newRecord.alamat" required />
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_tambah', 'close_modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data Pegawai</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30"
                                @click="hideModal('modal_edit', 'close_modal_edit')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nip" class="form-label"> NIP</label>
                            <input type="text" class="form-control" v-model="currentRecord.nip" maxlength="16"
                                @keypress="NumberOnly" required />
                        </div>
                        <div class="mb-3">
                            <label for="nidn" class="form-label"> NITK</label>
                            <input type="text" class="form-control" v-model="currentRecord.nidn" maxlength="30"
                                @keypress="NumberOnly" required />
                        </div>

                        <div class="mb-3">
                            <label for="kode" class="form-label"> Nama Lengkap</label>
                            <input type="text" class="form-control" v-model="currentRecord.nama" @keypress="CharOnly"
                                required />
                        </div>

                        <!-- <div class="mb-3 ">
                            <label for="jabatan" class="form-label"> Jabatan Struktural</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="currentRecord.jabatan_struktural" required>
                                <option value="" selected disabled>- Pilih Jabatan Struktural</option>
                                <option v-for="list in jabatanStrukturalList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="jabatan" class="form-label"> Jabatan Fungsional</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="currentRecord.jabatan_fungsional" required>
                                <option value="" selected disabled>- Pilih Jabatan Fungsional</option>
                                <option v-for="list in jabatanFungsionalList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->
                        <!-- <div class="mb-3">
                            <label for="golongan" class="form-label"> Golongan</label>
                            <select class="form-select" aria-label="Default select example" name="golongan" id="golongan"
                                v-model="currentRecord.golongan" required>
                                <option value="" selected disabled>- Pilih Golongan</option>
                                <option v-for="list in golonganList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->

                        <!-- <div class="mb-3">
                            <label for="bidang" class="form-label"> Bidang</label>
                            <select class="form-select" aria-label="Default select example" name="bidang" id="bidang"
                                v-model="currentRecord.bidang" required>
                                <option value="" selected disabled>- Pilih Bidang</option>
                                <option v-for="list in bidangList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div> -->

                        <div class="mb-3">
                            <label for="status_pegawai" class="form-label"> Status Pegawai</label>
                            <select class="form-select" aria-label="Default select example" name="status_pegawai"
                                id="status_pegawai" v-model="currentRecord.status_pegawai" required>
                                <option value="" selected disabled>- Pilih Status Pegawai</option>
                                <option v-for="list in statusDoseList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>

                        <!-- <div class="mb-3">
                            <label for="kode" class="form-label"> Username</label>
                            <input type="text" class="form-control" v-model="currentRecord.username" required />
                        </div> -->
                        <!-- <div class="mb-3">
                            <label for="kode" class="form-label"> Password</label>
                            <input type="text" class="form-control" v-model="currentRecord.password" />
                        </div> -->
                        <div class="mb-3">
                            <label for="tanggal_lahir" class="form-label">Tanggal Lahir</label>
                            <input type="date" class="form-control" v-model="currentRecord.tanggal_lahir" />
                        </div>

                        <div class="mb-3">
                            <label for="email" class="form-label"> Email</label>
                            <input type="text" class="form-control" v-model="currentRecord.email" required />
                        </div>
                        <div class="mb-3">
                            <label for="whatsapp" class="form-label"> Whatsapp</label>
                            <input type="text" class="form-control" v-model="currentRecord.whatsapp"
                                @keypress="NumberOnly" required />
                        </div>

                        <div class="mb-3">
                            <label for="jenis_kelamin" class="form-label"> Jenis Kelamin</label>
                            <select class="form-select" aria-label="Default select example" name="jenis_kelamin"
                                id="jenis_kelamin" v-model="currentRecord.jenis_kelamin" required>
                                <option value="" selected disabled>- Pilih Jenis Kelamin</option>
                                <option v-for="list in jenisKelaminList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="agama" class="form-label"> Agama</label>
                            <select class="form-select" aria-label="Default select example" name="agama" id="agama"
                                v-model="currentRecord.agama" required>
                                <option value="" selected disabled>- Pilih Agama</option>
                                <option v-for="list in agamaList" :key="list.nama" :value="list.nama">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="alamat" class="form-label">Alamat</label>
                            <textarea class="form-control" v-model="currentRecord.alamat" required />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>

                    </div>


                </div>

            </div>
        </div>
    </form>

    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_delete', 'close_modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_delete', 'close_modal_delete')">Tidak, tetap simpan disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_penelitian_pegawai" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_penelitian_pegawai">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

            <div class="modal-content ">

                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Penelitian Pegawai</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_penelitian_pegawai"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal('modal_penelitian_pegawai', 'close_modal_penelitian_pegawai')">
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-4">
                            <div class="d-flex align-items-center gap-5">
                                <div class="">
                                    <select class="form-select fs-8 font-custom-2 py-1 fw-bold"
                                        aria-label="Default select example" v-model="tipe"
                                        @change="get_penelitian_pegawai()">
                                        <option value="" selected disabled>Pilih Tipe:</option>
                                        <option value="PENELITIAN">PENELITIAN</option>
                                        <option value="PENGABDIAN">PENGABDIAN</option>
                                        <option value="PUBLIKASI">PUBLIKASI</option>
                                        <option value="HKI">HKI</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <table class="table  " style="">

                        <thead>
                            <tr>
                                <th scope="col" class="fs-7">No</th>
                                <th scope="col" class="fs-7">Pegawai</th>
                                <th scope="col" class="fs-7">Judul</th>
                                <th scope="col" class="fs-7">Semester</th>
                                <th scope="col" class="fs-7">Tahun Ajaran</th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Penulisan</th>
                                <th scope="col" class="font-custom-1 fs-7">Jenis Penelitian</th>
                                <th scope="col" class="font-custom-1 fs-7">Jumlah Dana </th>
                                <th scope="col" class="font-custom-1 fs-7">Sumber Dana </th>
                                <th scope="col" class="font-custom-1 fs-7" v-if="tipe == 'PUBLIKASI'">Link Publikasi
                                </th>
                                <th scope="col" class="font-custom-1 fs-7" v-if="tipe == 'HKI'">Sertifikat
                                </th>
                                <th scope="col" class="font-custom-1 fs-7"
                                    v-if="tipe == 'PENELITIAN' || tipe == 'PENGABDIAN'">Masa pengerjaan </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in penelitianPegawaiList" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.pegawai }}</td>
                                <td>{{ record.judul }}</td>
                                <td>{{ record.semester }}</td>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.tahun_penulisan }}</td>
                                <td>{{ record.jenis_penelitian }}</td>
                                <td>{{ rupiah(record.jumlah_dana) }}</td>
                                <td>{{ record.sumber_dana }}</td>
                                <td v-if="tipe == 'PUBLIKASI'">{{ record.link_publikasi }}</td>
                                <td v-if="tipe == 'HKI'"><img :src="record.sertifikat" alt=""
                                        style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;"
                                        @click="openInNewTab(record.sertifikat)">
                                </td>
                                <td v-if="tipe == 'PENELITIAN' || tipe == 'PENGABDIAN'">{{ record.masa_pengerjaan }}
                                </td>


                            </tr>
                        </tbody>
                    </table>

                </div>
                <!-- <div class="modal-footer"> -->
                <!-- <button type="button" class="btn btn-secondary"
                        @click="hideModal('modal_edit', 'close_modal_edit')">Tutup</button>
                    <button type="submit" class="btn bg-green-custom text-light">
                        Simpan </button> -->

                <!-- </div> -->


            </div>

        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, CloseLoading, menu_akses_cek, ShowLoading, ErrorConnectionTimeOut, AlertBottom, NumberOnly, CharOnly, sarjanaList, magisterList, jenisKelaminList, agamaList, rupiah } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";

export default {
    data() {
        return {
            //data
            url: 'pegawai',
            api: "",

            bidangList: "",
            jabatanStrukturalList: "",
            jabatanFungsionalList: "",
            statusDoseList: [
                {
                    nama: "Tetap",
                },
                {
                    nama: "Kontrak",
                },
                {
                    nama: "Magang",
                },
            ],

            //list
            sarjanaList: [],
            magisterList: [],
            jenisKelaminList: [],
            agamaList: [],
            penelitianPegawaiList: [],

            tipe: "PENELITIAN",

            //pages
            limit: "15",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,


            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: {
                nama: "", username: "", password: "", bidang: "", jabatan_struktural: "", jabatan_fungsional: "", nidn: "", nip: "", email: "", whatsapp: "", status_pegawai: "", tipe: "",
                gelar_1: "", bidang_1: "", nama_univ_gelar_1: "", tahun_perolehan_1: "", gelar_2: "", bidang_2: "", nama_univ_gelar_2: "", tahun_perolehan_2: ""
            },



            currentRecord: {},
        }
    },
    components: {
        SideNavbar,
    },

    methods: {
        NumberOnly,
        CharOnly,
        rupiah,
        //data
        // index() {
        //     ShowLoading();
        //     let formData = new FormData();
        //     formData.append("url", this.page + "_index");
        //     formData.append("tipe", this.tipe);

        //     if (process.env.NODE_ENV === "development") {
        //         this.api = base_url + this.url + '_index';
        //     } else {
        //         this.api = base_url + this.url + '_index';
        //     }
        //     axios
        //         .get(this.api, this.config)
        //         .then((response) => {
        //             if (response.data.status == true) {
        //                 this.records = response.data.data;

        //                 CloseLoading();
        //             }

        //             if (response.data.status == false) {
        //                 AlertPopup("error", "", response.data.message, 1500, false);
        //             }
        //         })
        //         .catch((error) => {
        //             ErrorConnectionTimeOut(error);
        //         });
        // },

        index() {
            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.bidangList = response.data.bidang;
                        this.jabatanStrukturalList = response.data.jabatan_struktural;
                        this.jabatanFungsionalList = response.data.jabatan_fungsional;

                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];

                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1].id;
                            this.previousLastIdOld = response.data.data[1].id;
                            let last_index = Object.keys(response.data.data[0]).pop();

                            this.NextLastId = response.data.data[1][last_index].id;
                            this.NextLastIdOld = response.data.data[1][last_index].id;
                            this.arrayLastIdOld.push(response.data.data[1][last_index].id);
                            // this.arrayPreviousIdOld.push(response.data.data[1][0].id);

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {

            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;
                        this.arrayLastIdOld.push(param2);
                        this.arrayPreviousIdOld.push(param1);

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[0]).pop();

                        this.NextLastId = response.data.data[1][last_index].id; // id 33
                        this.previousLastId = response.data.data[1][0].id; // 7
                        this.numberPage = this.numberPage + 1;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {

            let last_index = this.arrayLastIdOld.length;
            last_index = last_index - 1;
            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.arrayPreviousIdOld[last_index]);
            formData.append("last_id", "");
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[1]).pop();
                        this.NextLastId = response.data.data[1][last_index].id;
                        this.previousLastId = response.data.data[1][0].id;

                        if (this.arrayLastIdOld.length != 1) {
                            this.arrayLastIdOld.pop();
                            this.arrayPreviousIdOld.pop();
                            this.numberPage = this.numberPage - 1;
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_insert";
            } else {
                this.api = base_url + this.url + "_insert";
            }


            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status == true) {
                        this.records.unshift(response.data.data);
                        // Clear the form inputs
                        this.newRecord = {
                            nama: "", username: "", password: "", nidn: "", nip: "", email: "", whatsapp: "", tipe: ""
                        };
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_tambah', 'close_modal_tambah')
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    AlertPopup("error", "", 'terjadi kesalahan silahkan dicoba kembali', 1500, false);
                    ErrorConnectionTimeOut(error);
                });
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            // const bidangIndex = this.bidangList.findIndex((record) => record.nama == this.currentRecord.bidang);
            // this.currentRecord['bidang'] = this.bidangList[bidangIndex].id;

            // const jabatanStrukturalIndex = this.jabatanStrukturalList.findIndex((record) => record.nama == this.currentRecord.jabatan_struktural);
            // this.currentRecord['jabatan_struktural'] = this.jabatanStrukturalList[jabatanStrukturalIndex].id;

            // const jabatanFungsionalIndex = this.jabatanFungsionalList.findIndex((record) => record.nama == this.currentRecord.jabatan_fungsional);
            // this.currentRecord['jabatan_fungsional'] = this.jabatanFungsionalList[jabatanFungsionalIndex].id;

            this.showModal('modal_edit');
        },
        update() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.currentRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });
            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_update";
            } else {
                this.api = base_url + this.url + "_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.records.findIndex(
                            (record) => record.id == response.data.data.id
                        );
                        this.currentRecord = {}
                        // this.$set(this.records, index, response.data.data);
                        this.records.splice(index, 1, response.data.data);

                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_edit', 'close_modal_edit');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        deleteData() {

            ShowLoading();
            const formData = new FormData();
            formData.append("id", this.temporaryId);
            console.log(this.temporaryId)
            formData.append("url", "ra_rekomendasi_delete");
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + "_delete";
            } else {
                this.api = base_url + this.url + "_delete";
            }
            // Delete a record from the API
            axios
                .post(this.api, formData, this.config)

                .then((response) => {
                    console.log(response);
                    if (response.data.status == true) {
                        // Remove the record from the records list
                        const index = this.records.findIndex((record) => record.id === this.temporaryId);
                        this.records.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal('modal_delete', 'close_modal_delete');
                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        get_penelitian_pegawai(id_pegawai) {
            this.penelitianPegawaiList = []

            ShowLoading();
            let formData = new FormData();


            if (id_pegawai == "" || id_pegawai == undefined) {
                formData.append("id_pegawai", localStorage.getItem('pegawai'));
            } else {
                localStorage.setItem('pegawai', id_pegawai);
                formData.append("id_pegawai", id_pegawai);
            }
            console.log(localStorage.getItem('pegawai'));
            console.log(this.tipe);

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            formData.append("tipe", this.tipe);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'get_penelitian_pegawai';
            } else {
                this.api = base_url + 'get_penelitian_pegawai';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        if (response.data.data != undefined) {
                            this.penelitianPegawaiList = response.data.data[1];

                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },


        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)


        },
        deleteRecord(id) {

            this.temporaryId = id;
            console.log(this.temporaryId)
            this.showModal('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        },

        updateGelar(pilihan, bidang, tipe) {

            if (pilihan.slice(-1) == 1) {
                if (tipe == 'insert') {
                    const index = this.sarjanaList.findIndex((record) => record.nama == this.newRecord[bidang]);
                    this.newRecord[pilihan] = sarjanaList[index].kode;
                } else {
                    const index = this.sarjanaList.findIndex((record) => record.nama == this.currentRecord[bidang]);
                    this.currentRecord[pilihan] = sarjanaList[index].kode;
                }
            } else {
                if (tipe == 'insert') {
                    const index = this.magisterList.findIndex((record) => record.nama == this.newRecord[bidang]);
                    this.newRecord[pilihan] = magisterList[index].kode;
                } else {
                    const index = this.magisterList.findIndex((record) => record.nama == this.currentRecord[bidang]);
                    this.currentRecord[pilihan] = magisterList[index].kode;
                }
            }


        }
    },

    mounted() {
        this.sarjanaList = sarjanaList;
        this.magisterList = magisterList;
        this.jenisKelaminList = jenisKelaminList;
        this.agamaList = agamaList;

    },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }

        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');
        this.index();

    }



}
</script>