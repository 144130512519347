<template>
    <SideNavbar @isShowNavbar="receiveDataFromChild" />
    <div class="animation-duration-item-navbar"
        :class="{ 'padding-body-show-navbar': isShowNavbar, 'padding-body-hide-navbar': !isShowNavbar }">

        <div class=" mt-1 ps-2">
            <div class="bg-green-custom ps-2 py-2 text-light font-custom-3 fw-bold">
                KRS
            </div>
            <div class="d-flex flex-column gap-2 mt-2">
                <!-- <div class="fs-8 fw-bold font-custom-2 font-gray-custom">
                        >Desktop/Pengguna
                    </div> -->
                <div class="row me-0">
                    <div class="col-2 text-start">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="prodiFilter" @change="index()">
                            <option value="" selected disabled>- Pilih Prodi</option>
                            <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                {{ list.nama }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 text-start">
                        <select class="form-select" aria-label="Default select example" name="prodi" id="prodi"
                            v-model="tahunAjaranFilter" @change="index()">
                            <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                            <option v-for="list in tahunAjaranList" :key="list.nama" :value="list.id">
                                {{ list.tahun_ajaran }}
                            </option>
                        </select>
                    </div>
                    <div class="col-8 text-end ">
                        <button v-if="create_akses == true"
                            class="btn bg-green-custom fs-8 py-1 text-light font-custom-4 " style="border-radius: 5px;"
                            @click="showModal2('modal_tambah')">
                            <img src="@/assets/icon/data_plus.png" alt="" width="15" :disabled="isLoading == true">
                            Generate KRS
                        </button>
                    </div>
                </div>
                <div v-if="isLoading"
                    class="bg-warning text-light fw-bold justify-content-end d-flex align-items-center px-2 py-2 gap-2">
                    <div>
                        Generate KRS {{ progresKrs + ' data' }}
                    </div>
                    <div class="spinner-border text-light" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>

                <div class="border mt-1 rounded-3 me-2 font-custom-1">
                    <table class="table mb-0 bg-transparent table-borderless " style="">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-transparent fs-7">No</th>
                                <th scope="col" class="font-custom-1 fs-7">Tahun Ajaran </th>
                                <th scope="col" class="font-custom-1 fs-7">Semester </th>
                                <th scope="col" class="font-custom-1 fs-7">Prodi </th>
                                <th scope="col" class="font-custom-1 fs-7">Status </th>
                                <!-- <th scope="col" class="bg-transparent  fs-7">Aksi</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in records" :key="record.id" :ref="record.id"
                                class="border-bottom ">
                                <th scope="row" class="bg-transparent ">{{ index + 1 }}</th>
                                <td>{{ record.tahun_ajaran }}</td>
                                <td>{{ record.semester }}</td>
                                <td>{{ record.prodi }}</td>
                                <td>{{ record.status }}</td>
                                <!-- <td class="bg-transparent">
                                    <div class="d-flex gap-2">
                                        <button class="btn btn-warning btn-sm fs-8" v-on:click="editRecord(record)"
                                            data-bs-toggle="modal" data-bs-target="#modal_edit"><i
                                                class='bx bxs-message-square-edit fs-8'></i></button>
                                        <button class="btn btn-danger btn-sm fs-8" data-bs-toggle="modal"
                                            data-bs-target="#modal_delete" v-on:click="deleteRecord(record.id)"><i
                                                class='bx bxs-trash-alt fs-8'></i></button>
                                    </div>

                                </td> -->

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>

    </div>
    <form @submit.prevent="cekGenerate('insert')">
        <div class="modal fade" id="modal_tambah" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_tambah">

            <div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Generate KRS </h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_tambah"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_tambah')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3 ">
                            <label for="jabatan" class="form-label">Pilih Prodi</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="newRecord.prodi" required>
                                <option value="" selected disabled>- Pilih Prodi</option>
                                <option v-for="list in prodiList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3 ">
                            <label for="jabatan" class="form-label">Pilih Tahun Ajaran</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="newRecord.tahun_ajaran" @change="index()" required>
                                <option value="" selected disabled>- Pilih Tahun Ajaran</option>
                                <option v-for="list in tahunAjaranList" :key="list.tahun_ajaran" :value="list.id">
                                    {{ list.tahun_ajaran }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3 ">
                            <label for="jabatan" class="form-label">Pilih Semester</label>
                            <select class="form-select" aria-label="Default select example" name="jabatan" id="jabatan"
                                v-model="newRecord.semester" :disabled="this.newRecord.tahun_ajaran == ''" required>
                                <option value="" selected disabled>- Pilih Semester</option>
                                <option v-for="list in semesterList" :key="list.nama" :value="list.id">
                                    {{ list.nama }}
                                </option>
                            </select>
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            @click="hideModal2('modal_tambah')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan</button>
                    </div>

                </div>

            </div>

        </div>
    </form>
    <form @submit.prevent="update">
        <div class="modal fade" id="modal_edit" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_edit">
            <div class="modal-dialog modal-fullscreen modal-dialog-scrollable" role="document">

                <div class="modal-content ">

                    <div class="modal-header bg-green-custom text-light">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Edit Data</h5>
                        <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                            ref="close_modal_edit"></button>
                        <button type="button" class="btn bg-transparent text-light border-0">
                            <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_edit')">
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="nama" class="form-label">Nama Ruangan</label>
                            <input type="text" class="form-control" @keydown="CharAndNumberOnly"
                                v-model="currentRecord.nama" required />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="hideModal2('modal_edit')">Tutup</button>
                        <button type="submit" class="btn bg-green-custom text-light">
                            Simpan </button>

                    </div>


                </div>

            </div>
        </div>
    </form>


    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true" ref="modal_delete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Hapus Data</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_delete"></button>
                    <button type="button " class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30" @click="hideModal2('modal_delete')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin menghapus data ini? data tidak dapat dikembalikan
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_delete')">Tidak, tetap
                        simpan
                        disini</button>
                    <button type="button" class="btn bg-danger text-light" @click="deleteData">
                        Ya,Hapus</button>
                </div>
            </div>
        </div>
    </div>



    <div class="modal fade" id="modal_update_generate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" ref="modal_update_generate">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content ">
                <div class="modal-header bg-green-custom text-light">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Generate Krs lagi</h5>
                    <button hidden type="button" data-bs-dismiss="modal" aria-label="Close"
                        ref="close_modal_update_generate"></button>
                    <button type="button" class="btn bg-transparent text-light border-0">
                        <img src="@/assets/icon/close.png" alt="" width="30"
                            @click="hideModal2('modal_update_generate')">
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        Apakah Anda yakin ingin mengenerate lagi untuk mahasiswa yang belum dapat krs pada semester ini?
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal2('modal_update_generate')">Tidak,
                        kembali</button>
                    <button type="button" class="btn bg-danger text-light" @click="cekGenerate('update')">
                        Ya,Generate kembali</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.padding-body-show-navbar {
    padding-left: 190px !important;
}

.padding-body-hide-navbar {
    padding-left: 82px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0.1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.delete-animation {
    animation: fadeLeft 1s ease;
}

@keyframes fadeLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(-20px);
    }
}


@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.animation-hide-modal {
    animation: bounceOut 0.3s ease-in;
}

.animation-show-modal {
    animation: bounceIn 0.3s ease-out;
}


.table tbody tr:last-child {
    border: 0px white !important;
}
</style>

<script>
import { AlertPopup, base_url, menu_akses_cek, CloseLoading, ShowLoading, ErrorConnectionTimeOut, CharAndNumberOnly } from "@/assets/javascript/function";
import SideNavbar from "@/components/SideNavbar.vue";
import Cookies from "js-cookie";
import axios from "axios";


export default {
    data() {
        return {
            //data
            url: 'krs',
            api: "",

            //pages
            limit: "15",
            previousLastId: "",
            NextLastId: "",
            previousLastIdOld: "",
            NextLastIdOld: "",
            arrayLastIdOld: [],
            arrayPreviousIdOld: [],
            numberPage: 1,
            totalPages: 0,

            //list
            prodiList: [],
            tahunAjaranList: [],
            semesterList: [],

            //krs
            progresKrs: 0,
            isLoading: false,
            idKrsLast: 0,
            interval: null,
            updateGenerateKrsId: 0,

            //filter
            prodiFilter: "",
            tahunAjaranFilter: "",
            isLoad: 0,

            //akses
            create_akses: false,
            update_akses: false,
            delete_akses: false,

            //tampilan
            isShowNavbar: true,
            temporaryId: "",
            records: [],
            newRecord: { prodi: "", tahun_ajaran: "", semester: "" },
            currentRecord: {},


        }
    },
    components: {
        SideNavbar,
    },

    methods: {
        CharAndNumberOnly,
        //data
        // index() {
        //     ShowLoading();
        //     let formData = new FormData();
        //     formData.append("url", this.page + "_index");
        //     formData.append("tipe", this.tipe);

        //     if (process.env.NODE_ENV === "development") {
        //         this.api = base_url + this.url + '_index';
        //     } else {
        //         this.api = base_url + this.url + '_index';
        //     }
        //     axios
        //         .get(this.api, this.config)
        //         .then((response) => {
        //             if (response.data.status == true) {
        //                 this.records = response.data.data;

        //                 CloseLoading();
        //             }

        //             if (response.data.status == false) {
        //                 AlertPopup("error", "", response.data.message, 1500, false);
        //             }
        //         })
        //         .catch((error) => {
        //             ErrorConnectionTimeOut(error);
        //         });
        // },

        index() {
            this.prodiList = [];
            this.records = [];
            ShowLoading();
            let formData = new FormData();

            formData.append("previous_id", '');
            formData.append("last_id", '');
            formData.append("limit", this.limit);
            if (this.tahunAjaranFilter == '') {
                formData.append("tahun_ajaran", this.newRecord.tahun_ajaran);
            } else {
                formData.append("tahun_ajaran", this.tahunAjaranFilter);
            }

            formData.append("prodi", this.prodiFilter);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        if (localStorage.getItem('tipe_user') == 'dosen') {
                            for (let i = 0; i < response.data.prodi_akses.length; i++) {
                                const index = response.data.prodi.findIndex((record) => record.id === response.data.prodi_akses[i].id);
                                this.prodiList.push(response.data.prodi[index]);
                            }
                        } else {
                            this.prodiList = response.data.prodi;
                        }
                        this.tahunAjaranList = response.data.tahun_ajaran;
                        this.semesterList = response.data.semester;
                        console.log();
                        if (response.data.data != undefined) {
                            this.records = response.data.data[1];
                            // if (this.newRecord.tahun_ajaran != "") {
                            // } else {
                            //     this.isLoad = 1;
                            // }

                            this.totalPages = response.data.data[0][0].number_page;
                            this.previousLastId = response.data.data[1].id;
                            this.previousLastIdOld = response.data.data[1].id;
                            let last_index = Object.keys(response.data.data[0]).pop();

                            this.NextLastId = response.data.data[1][last_index].id;
                            this.NextLastIdOld = response.data.data[1][last_index].id;
                            this.arrayLastIdOld.push(response.data.data[1][last_index].id);
                        }
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        index_next_page(param1, param2) {

            ShowLoading();
            let formData = new FormData();
            formData.append("previous_id", "");
            formData.append("last_id", param2);
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;
                        this.arrayLastIdOld.push(param2);
                        this.arrayPreviousIdOld.push(param1);

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[0]).pop();

                        this.NextLastId = response.data.data[1][last_index].id; // id 33
                        this.previousLastId = response.data.data[1][0].id; // 7
                        this.numberPage = this.numberPage + 1;
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        index_before_page() {

            let last_index = this.arrayLastIdOld.length;
            last_index = last_index - 1;
            ShowLoading();
            let formData = new FormData();
            formData.append("url", "ra_admin_verifikasi_page");
            formData.append("previous_id", this.arrayPreviousIdOld[last_index]);
            formData.append("last_id", "");
            formData.append("limit", this.limit);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + this.url + '_index';
            } else {
                this.api = base_url + this.url + '_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.totalPages = response.data.data[0][0].number_page;

                        this.records = response.data.data[1];
                        let last_index = Object.keys(response.data.data[1]).pop();
                        this.NextLastId = response.data.data[1][last_index].id;
                        this.previousLastId = response.data.data[1][0].id;

                        if (this.arrayLastIdOld.length != 1) {
                            this.arrayLastIdOld.pop();
                            this.arrayPreviousIdOld.pop();
                            this.numberPage = this.numberPage - 1;
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        insert() {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            if (process.env.NODE_ENV === "development") {
                // this.api = base_url + this.url + "_insert";
                this.api = base_url + "cron_job_update";
            } else {
                this.api = base_url + "cron_job_update";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Clear the form inputs
                        // this.idKrsLast = response.data.last_id;
                        // this.isLoading = true;

                        // localStorage.setItem('id_progress_krs', response.data.last_id);
                        this.tahunAjaranFilter = this.newRecord.tahun_ajaran;
                        this.prodiFilter = this.newRecord.prodi;
                        this.index();

                        this.newRecord = { tahun_ajaran: '', prodi: '', semester: '' };
                        AlertPopup("success", "", response.data.message, 1500, false);
                        this.hideModal2('modal_tambah');

                        // CloseLoading();
                        // AlertBottom(response.data.message);


                        // this.interval = setInterval(() => {
                        //     this.getProgresKrs(response.data.last_id)
                        // }, 1000);

                    }
                    if (response.data.status == false) {

                        AlertPopup("error", "", response.data.message, 1500, false);
                        // if (response.data.statusGenerate == 1) {
                        //     CloseLoading();
                        //     this.updateGenerateKrsId = response.data.id;
                        //     this.showModal2('modal_update_generate');
                        // } else {
                        //     AlertPopup("error", "", response.data.message, 1500, false);
                        // }

                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        stopInterval() {
            clearInterval(this.interval); // Clear the interval
            this.interval = null; // Reset the interval variable
        },

        editRecord(record) {
            // Set the current record to edit mode
            this.currentRecord = Object.assign({}, record);
            this.showModal2('modal_edit');
        },
        cekGenerate(status) {
            ShowLoading();
            const formData = new FormData();

            Object.entries(this.newRecord).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            if (process.env.NODE_ENV === "development") {
                // this.api = base_url + this.url + "_insert";
                this.api = base_url + "cek_cron";
            } else {
                this.api = base_url + "cek_cron";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // Clear the form inputs
                        CloseLoading();

                        if (status == 'insert') {
                            this.insert();
                        } else {
                            this.update();
                        }

                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        update() {
            this.insert();
        },
        // update() {
        //     this.hideModal2('modal_update_generate');
        //     ShowLoading();
        //     const formData = new FormData();

        //     Object.entries(this.newRecord).forEach(([key, value]) => {
        //         formData.append(key, value);
        //     });
        //     formData.append('id', this.updateGenerateKrsId);
        //     const index = this.records.findIndex((record) => record.id === this.updateGenerateKrsId);
        //     if (index > -1) {
        //         this.records[index].status = 'PROSES';
        //     }


        //     if (process.env.NODE_ENV === "development") {
        //         // this.api = base_url + this.url + "_insert";
        //         this.api = base_url + "cron_job_update";
        //     } else {
        //         this.api = base_url + "cron_job_update";
        //     }
        //     // Add a new record to the API
        //     axios
        //         .post(this.api, formData, this.config)
        //         .then((response) => {
        //             if (response.data.status == true) {
        //                 // Clear the form inputs
        //                 this.idKrsLast = response.data.last_id;
        //                 this.isLoading = true;

        //                 localStorage.setItem('id_progress_krs', response.data.last_id);

        //                 this.newRecord = { tahun_ajaran: '', prodi: '', semester: '' };
        //                 CloseLoading();
        //                 AlertBottom(response.data.message);
        //                 this.hideModal2('modal_tambah');

        //                 this.interval = setInterval(() => {
        //                     this.getProgresKrs(response.data.last_id)
        //                 }, 1000);


        //             }
        //             if (response.data.status == false) {

        //                 if (response.data.statusGenerate == 1) {
        //                     CloseLoading();
        //                     this.updateGenerateKrsId = response.data.id;
        //                     this.showModal2('modal_update_generate');
        //                 } else {
        //                     AlertPopup("error", "", response.data.message, 1500, false);
        //                 }

        //             }
        //         })
        //         .catch((error) => {
        //             ErrorConnectionTimeOut(error);
        //         });
        // },
        // deleteData() {

        //     ShowLoading();
        //     const formData = new FormData();
        //     formData.append("id", this.temporaryId);
        //     console.log(this.temporaryId)
        //     formData.append("url", "ra_rekomendasi_delete");
        //     if (process.env.NODE_ENV === "development") {
        //         this.api = base_url + this.url + "_delete";
        //     } else {
        //         this.api = base_url + this.url + "_delete";
        //     }
        //     // Delete a record from the API
        //     axios
        //         .post(this.api, formData, this.config)

        //         .then((response) => {
        //             console.log(response);
        //             if (response.data.status == true) {
        //                 // Remove the record from the records list
        //                 const index = this.records.findIndex((record) => record.id === this.temporaryId);
        //                 this.records.splice(index, 1);
        //                 CloseLoading();
        //                 AlertBottom(response.data.message);
        //                 this.hideModal2('modal_delete');
        //             } else {
        //                 AlertPopup("error", "", response.data.message, 1500, false);
        //             }
        //         })
        //         .catch((error) => {
        //             ErrorConnectionTimeOut(error);
        //         });

        // },

        getProgresKrs(last_id) {
            console.log('testing_cek_progress');
            this.idKrsLast = 0;
            const formData = new FormData();
            formData.append('krs', last_id);
            if (process.env.NODE_ENV === "development") {
                // this.api = base_url + this.url + "_insert";
                this.api = base_url + "get_krs_progres";
            } else {
                this.api = base_url + "get_krs_progres";
            }
            console.log(this.api);
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log('hitung');
                    console.log(response);
                    if (response.data.status == true) {
                        this.progresKrs = response.data.progres;
                        this.isLoading = true;
                        console.log(response.data.progres)

                    } else {
                        if (response.data.data != undefined) {
                            const index = this.records.findIndex((record) => record.id === response.data.data[0].id);
                            if (index > -1) {
                                this.records.splice(index, 1, response.data.data[0]);
                            } else {
                                this.records.unshift(response.data.data[0]);
                            }

                            this.stopInterval();
                            localStorage.removeItem('id_progress_krs');
                            this.isLoading = false;
                            this.idKrsLast = 0;
                        } else {
                            this.stopInterval();
                        }

                    }
                })
                .catch((error) => {
                    this.stopInterval();
                    // ErrorConnectionTimeOut(error);
                    console.log(error);

                });
        },
        //antimation
        receiveDataFromChild(data) {
            this.isShowNavbar = data;
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')

        },
        hideModal(modal_name, close_button_modal) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[close_button_modal].click();
            }, 150)
        },
        showModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-hide-modal')
            this.$refs[modal_name].classList.add('animation-show-modal')
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('animation-show-modal')
            this.$refs[modal_name].classList.add('animation-hide-modal')
            setTimeout(() => {
                this.$refs[modal_name].classList.remove('show');
                this.$refs[modal_name].style.display = 'none';
            }, 150)
            // this.$refs[modal_name].classList.remove('animation-hide-modal')
            // this.$refs[modal_name].classList.add('animation-show-modal')
        },
        deleteRecord(id) {
            this.temporaryId = id;
            console.log(this.temporaryId)
            this.showModal2('modal_delete');
        },
        deleteRecordApi() {
            const index = this.records.findIndex((record) => record.id === this.temporaryId);
            // this.$refs[this.temporaryId].classList.add('bg-danger');
            setTimeout(() => {
                this.$refs['close_modal_delete'].click();
                this.records.splice(index, 1);
            }, 1000)
        }
    },

    // mounted() {

    // },
    created() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 0,
        }
        //akses
        this.create_akses = menu_akses_cek(this.url, 'create');
        this.update_akses = menu_akses_cek(this.url, 'update');
        this.delete_akses = menu_akses_cek(this.url, 'delete');

        this.index();
        if (localStorage.getItem('id_progress_krs') != null) {
            this.interval = setInterval(() => {
                this.getProgresKrs(localStorage.getItem('id_progress_krs'));
            }, 1000);
        }


    }



}
</script>